import { Button } from "@chakra-ui/react";
import React from "react";

const ButtonCloseModal = ({ onClick, title }) => {
  return (
    <>
      <Button
        px={5}
        py={2}
        w={16}
        borderRadius={"full"}
        boxShadow={"lg"}
        onClick={onClick}
        color={"white"}
        fontSize={"xs"}
        bgColor={"gray.500"}
        _hover={{
          bgColor: "gray.600",
          transition: "background-color 0.3s ease-in-out",
        }}
        _active={{
          border: "4px solid #bbb",
        }}
      >
        {title ? title : "Close"}
      </Button>
    </>
  );
};

export default ButtonCloseModal;
