import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={28}
    height={27}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 .167C6.667.167.667 6.167.667 13.5s6 13.333 13.333 13.333c7.334 0 13.334-6 13.334-13.333S21.334.167 14 .167Zm-2.666 20L4.667 13.5l1.88-1.88 4.787 4.773 10.12-10.12 1.88 1.894-12 12Z"
      fill="#1A7431"
    />
  </svg>
)

export default SvgComponent
