import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    containerStepTwo: {
      backgroundColor: '#fff',
      padding: '20px 0',
      marginTop: 20,
    },
    containerCenter: {
      padding: '20px 70px',
      display: 'flex',
      flexDirection: 'row',
      gap: 20,
    },
    containerTopTwo: {
      padding: '0px 68px 20px',
      borderBottom: '1px solid #C2C2C2',
      '& .title': {
        margin: 0,
        color: '#404852'
      },
      '& .content': {
        margin: '20px 0'
      }
    },
    containerListType: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: "100%",
      '& .paymentType': {
        backgroundColor: '#fff',
        width: '30%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 0',
        border: '1px solid #DBD8D8',
        borderRadius: 5,
        cursor: 'pointer'
      },
      '& .paymentActive': {
        backgroundColor: '#fff',
        // color: '#fff',
        width: '30%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 0',
        border: '1px solid #E24E06',
        borderRadius: 5,
        cursor: 'pointer'
      },
    },
    containerMethod: {
      padding: '0 70px',
      display: 'flex',
      flexDirection: 'row',
      gap: 20,
      '& .paymentType': {
        backgroundColor: '#fff',
        width: '30%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 0',
        border: '1px solid #DBD8D8',
        borderRadius: 5,
        cursor: 'pointer'
      },
      '& .paymentActive': {
        backgroundColor: '#fff',
        // color: '#fff',
        width: '30%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 0',
        border: '1px solid #E24E06',
        borderRadius: 5,
        cursor: 'pointer'
      },
    },
    containerListMethod: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 20,
      '& .block': {
        display: 'block',
        width: '100%'
      },
      '& .block-phone': {
        display: 'block',
        width: '100%',
        marginBottom: 0
      },
      '& .paymentMethod': {
        backgroundColor: '#fff',
        width: '30%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 0',
        border: '1px solid #DBD8D8',
        borderRadius: 5,
        cursor: 'pointer',
        flexDirection: 'row'
      }
    },
    containerButton: {
      padding: '0 70px',
      marginTop: 50,
      display: 'flex',
      flexDirection: 'row',
      gap: 20,
    },
    containerQR: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }
}));
