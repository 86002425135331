import React, { useState  } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import ResultAvatarComparison from "./ResultAvatarComparison"; 
import PropTypes from "prop-types";

const ResultUserComparison = ({
  user1,
  user2,
  stats,
  level,
  mode,
  cardMatching,
  isUnlocked,
  props,
}) => {
  const [open, setOpen] = useState("");

  const onClickOpen = (text) => {
    if (isUnlocked) {
      if (text === open) {
        setOpen("");
      } else {
        setOpen(text);
      }
    } else {
      if (props?.match?.params?.id) {
        window.location.assign("/pricing#matching");
        // window.location.assign(`/dashboard/friend-list/${props.match.params.type}/${props?.match?.params?.id}/checkout/matching`)
      } else {
        window.location.assign("/pricing#matching");
        // window.location.assign(`/dashboard/profile/${user?.username}/checkout/matching`)
      }
    }
  };

  return (
    <Flex
      flexDirection="column"
      // width={{ md: "100%" }}
      borderWidth={1}
      borderRadius={15}
      mx="auto"
      px={{ sm: 16, md: 16 }}
      py={5}
      gap={5}
      bgColor={"white"}
    >
      <Box mb={4}>
        <Text fontSize="2xl" fontWeight="bold" align="center">
          Result Comparasion{" "}
        </Text>
      </Box>

      {/* Avatar & Percentage */}
      <ResultAvatarComparison
        user1={user1}
        user2={user2}
        stats={stats}
        level={level}
        mode={mode}
      />

      {/* Cards Section */}
      {cardMatching.map((card, index) => (
        <Flex direction="column" key={index} gap={3}>
          <Text
            fontWeight="bold"
            color="orange"
            fontSize={{ sm: "sm", md: "xl" }}
            as={"h1"}
          >
            {card.title}
          </Text>
          {card.texts.map((text, textIndex) => (
            <Box key={textIndex}>
              {text.map((item, i) => (
                <Flex key={i} direction="column" gap={1}>
                  <Text
                    fontWeight="bold"
                    color="gray.600"
                    fontSize={{ sm: "xs", md: "md" }}
                  >
                    {item.title !== "null" && item.title}
                  </Text>
                  <Text
                    textOverflow="ellipsis"
                    fontSize={{ sm: "xs", md: "sm" }}
                    overflow="hidden"
                    noOfLines={item.text === open ? 0 : 3}
                    align="justify"
                    color="gray.600"
                  >
                    {item.text}
                  </Text>

                  <Text
                    px={3}
                    py={1}
                    borderRadius={25}
                    bgColor={
                      isUnlocked && item.text !== open
                        ? "orange"
                        : isUnlocked && item.text === open
                        ? "yellow"
                        : "gray"
                    }
                    _hover={{ backgroundColor: "orange.400" }}
                    color="gray.100"
                    fontSize="xs"
                    mt={0}
                    cursor="pointer"
                    w="max"
                    onClick={() => onClickOpen(item.text)}
                  >
                    {isUnlocked && item.text !== open
                      ? "Read more"
                      : isUnlocked && item.text === open
                      ? "Show less"
                      : "Deep dive"}
                  </Text>
                </Flex>
              ))}
            </Box>
          ))}
        </Flex>
      ))}
    </Flex>
  );
};

// PropTypes for type checking
ResultUserComparison.propTypes = {
  user1: PropTypes.object.isRequired,
  user2: PropTypes.object.isRequired,
  stats: PropTypes.number.isRequired,
  level: PropTypes.string.isRequired,
  mode: PropTypes.number.isRequired,
  cardMatching: PropTypes.array.isRequired,
  isUnlocked: PropTypes.bool.isRequired,
  open: PropTypes.string.isRequired,
  // onClickOpen: PropTypes.func.isRequired,
};

export default ResultUserComparison;
