import React from "react";

import { Box, Grid, Text } from "@chakra-ui/react";

const ProfilePart2 = ({ user }) => {
  return (
    <Box
      bg="white"
      borderRadius="2xl"
      boxShadow="md"
      padding={4}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Text color={"gray.700"} fontWeight={"bold"} mb={2}>
        Data Diri
      </Text>

      <Box>
        <Grid
          templateColumns={{ base: "1fr", md: "3fr 5fr" }}
          gap={2}
          p={2}
          borderRadius={"lg"}
          _hover={{
            backgroundColor: "gray.100",
          }}
        >
          <Box>
            <Text color={"gray.600"} fontWeight={"bold"} fontSize={"sm"}>
              Nama
            </Text>
          </Box>
          <Box w={"full"}>
            <Text fontSize={"sm"} color="gray.700">
              {user?.name}
            </Text>
          </Box>
        </Grid>

        <Grid
          templateColumns={{ base: "1fr", md: "3fr 5fr" }}
          gap={2}
          p={2}
          borderRadius={"lg"}
          _hover={{
            backgroundColor: "gray.100",
          }}
        >
          <Box>
            <Text color={"gray.600"} fontWeight={"bold"} fontSize={"sm"}>
              Username
            </Text>
          </Box>
          <Box w={"full"}>
            <Text fontSize={"sm"} color="gray.700">
              {user?.username}
            </Text>
          </Box>
        </Grid>

        <Grid
          templateColumns={{ base: "1fr", md: "3fr 5fr" }}
          gap={2}
          p={2}
          borderRadius={"lg"}
          _hover={{
            backgroundColor: "gray.100",
          }}
        >
          <Box>
            <Text color={"gray.600"} fontWeight={"bold"} fontSize={"sm"}>
              Email
            </Text>
          </Box>
          <Box w={"full"}>
            <Text fontSize={"sm"} color="gray.700">
              {user?.email}
            </Text>
          </Box>
        </Grid>

        <Grid
          templateColumns={{ base: "1fr", md: "3fr 5fr" }}
          gap={2}
          p={2}
          borderRadius={"lg"}
          _hover={{
            backgroundColor: "gray.100",
          }}
        >
          <Box>
            <Text color={"gray.600"} fontWeight={"bold"} fontSize={"sm"}>
              Telepon
            </Text>
          </Box>
          <Box w={"full"}>
            <Text fontSize={"sm"} color="gray.700">
              {user?.phone}
            </Text>
          </Box>
        </Grid>

        <Grid
          templateColumns={{ base: "1fr", md: "3fr 5fr" }}
          gap={2}
          p={2}
          borderRadius={"lg"}
          _hover={{
            backgroundColor: "gray.100",
          }}
        >
          <Box>
            <Text color={"gray.600"} fontWeight={"bold"} fontSize={"sm"}>
              Tgl Lahir
            </Text>
          </Box>
          <Box w={"full"}>
            <Text fontSize={"sm"} color="gray.700">
              {user?.birth_date}
            </Text>
          </Box>
        </Grid>

        <Grid
          templateColumns={{ base: "1fr", md: "3fr 5fr" }}
          gap={2}
          p={2}
          borderRadius={"lg"}
          _hover={{
            backgroundColor: "gray.100",
          }}
        >
          <Box>
            <Text color={"gray.600"} fontWeight={"bold"} fontSize={"sm"}>
              Waktu Lahir
            </Text>
          </Box>
          <Box w={"full"}>
            <Text fontSize={"sm"} color="gray.700">
              {user?.birth_time}
            </Text>
          </Box>
        </Grid>

        <Grid
          templateColumns={{ base: "1fr", md: "3fr 5fr" }}
          gap={2}
          p={2}
          borderRadius={"lg"}
          _hover={{
            backgroundColor: "gray.100",
          }}
        >
          <Box>
            <Text color={"gray.600"} fontWeight={"bold"} fontSize={"sm"}>
              Lokasi Lahir
            </Text>
          </Box>
          <Box w={"full"}>
            <Text fontSize={"sm"} color="gray.700">
              {user?.birth_location}
            </Text>

           
          </Box>
        </Grid>

        <Grid
          templateColumns={{ base: "1fr", md: "3fr 5fr" }}
          gap={2}
          p={2}
          borderRadius={"lg"}
          _hover={{
            backgroundColor: "gray.100",
          }}
        >
          <Box>
            <Text color={"gray.600"} fontWeight={"bold"} fontSize={"sm"}>
              Jenis Kelamin
            </Text>
          </Box>
          <Box w={"full"}>
            <Text fontSize={"sm"} color="gray.700">
              {user?.gender === 0 ? "Laki-laki" : "Perempuan"}
            </Text>
          </Box>
        </Grid>

        <Grid
          templateColumns={{ base: "1fr", md: "3fr 5fr" }}
          gap={2}
          p={2}
          borderRadius={"lg"}
          _hover={{
            backgroundColor: "gray.100",
          }}
        >
          <Box>
            <Text color={"gray.600"} fontWeight={"bold"} fontSize={"sm"}>
              Status Verifikasi
            </Text>
          </Box>
          <Box w={"full"}>
            <Text fontSize={"sm"} color="gray.700">
              {user?.verified ? "Terverifikasi" : "Belum Terverifikasi"}
            </Text>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProfilePart2;
