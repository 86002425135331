import React from "react";
import { Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import Navbar from "components/header";
import Footer from "components/footerUser";

const PublicLayout = ({ children }) => {
  const location = useLocation(); // Get the current location

  // List of paths where the footer should not be displayed
  const noFooterPaths = ["/login", "/forgot-password", "/signup"];

  // Check if the current path matches any of the paths in noFooterPaths
  const noNavbar = noFooterPaths.includes(location.pathname);

  return (
    <Box
      minHeight="100vh"
      display={{ md: "flex" }}
      flexDirection={{ md: "column" }}
    >
      {!noNavbar && <Navbar />}

      <Box flex="1" mt={{ base: noNavbar ? 0 : -16, md: noNavbar ? 0 : 10 }}>
        {children}
      </Box>

      <footer>
        <Footer />
      </footer>
    </Box>
  );
};

export default PublicLayout;
