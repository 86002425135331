export const columnsDataService = [
  {
    Header: "SERVICE",
    accessor: "service",
  },
  {
    Header: "FEATURES",
    accessor: "features",
  },
  {
    Header: "CATEGORY",
    accessor: "category",
  },
  {
    Header: "PERIODE",
    accessor: "periode",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "HARGA",
    accessor: "harga",
  },
  {
    Header: "ACTION",
  }
];
// export const columnsDataService = [
//   {
//     Header: 'Service',
//     accessor: 'service'
//   },
//   {
//     Header: 'Features',
//     accessor: 'features',
//     Cell: ({ row }) => (
//       <ul>
//         {row.original.features.map((feature, index) => (
//           <li key={index}>
//             {feature.name} - {feature.category} - {feature.periode} - {feature.status} - {feature.harga}
//           </li>
//         ))}
//       </ul>
//     )
//   },
//   {
//     Header: 'Category',
//     accessor: 'features.',
//     Cell: ({ row }) => (
//       <ul>
//         {row.original.features.map((feature, index) => (
//           <li key={index}>
//             {feature.name} - {feature.category} - {feature.periode} - {feature.status} - {feature.harga}
//           </li>
//         ))}
//       </ul>
//     )
//   }
// ];
