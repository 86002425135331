import React from "react";
import { Box } from "@chakra-ui/react";

const KontainerPublik = ({
  children,
  py = { base: 4, sm: 48, md: 36 },
  px = { base: 6, sm: 6, md: 24, lg: 64 },
  ...rest
}) => {
  return (
    <Box px={px} py={py} {...rest}>
      {children}
    </Box>
  );
};

export default KontainerPublik;
