import { Box, Button, Text, Image } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import NotFoundImg from "assets/img/newimg/404.webp";

const NotFound = () => {
  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box textAlign="center" mx="auto">
        <Image
          src={NotFoundImg}
          alt="Not Found"
          width={{ base: "200px", lg: "400px" }}
          mx="auto"
        />

        <Text
          as="h1"
          fontSize={{ base: "3xl", lg: "90px" }}
          fontWeight="bold"
          color="red.500"
        >
          404{" "}
          <Box
            as="span"
            fontSize={{ base: "3xl", lg: "50px" }}
            color="gray.700"
          >
            - Not Found
          </Box>
        </Text>

        <Text as="h2" mt={4} mb={6} px={{ base: 6, lg: 10 }} color="gray.600">
          Maaf, kami tidak dapat menemukan informasi yang anda cari.
        </Text>

        <Button
          bgColor="tomato"
          _hover={{ backgroundColor: "orange.500" }}
          color="white"
          py={2}
          px={4}
          borderRadius="full"
          fontSize="sm"
          boxShadow="lg"
          as={Link}
          to="/home"
        >
          Kembali ke Halaman Utama
        </Button>
      </Box>
    </Box>
  );
};

export default NotFound;
