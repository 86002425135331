import React from "react";

import { Flex, Box } from "@chakra-ui/react";
import UserPickSelect from "./UserPickSelect";

const ShowPickAvatar = ({
  user1,
  user2,
  onChangeUser1,
  onChangeUser2,
  onRemoveUser,
}) => {
  return (
    <Flex
      flexDirection={{
        base: "column",
        sm: "column",
        md: "row",
      }}
      gap={{ sm: "16", md: "2", lg: "32" }}
      mx="auto"
      justify="center"
      alignItems="center"
    >
      {/* Pick User 1 */}
      <UserPickSelect
        onChangeUser1={onChangeUser1}
        onChangeUser2={onChangeUser2}
        user={user1}
        noUrut={1}
        onClick={() => onRemoveUser(1)}
      />

      <Box display={{ base: "none", md: "block" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="tomato"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="gray"
          width="100px"
          height="100px"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
          />
        </svg>
      </Box>

      <Box display={{ base: "block", md: "none" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="tomato"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="gray"
          width="100px"
          height="100px"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
          />
        </svg>
      </Box>

      {/* Pick User 2 */}
      <UserPickSelect
        onChangeUser1={onChangeUser1}
        onChangeUser2={onChangeUser2}
        user={user2}
        noUrut={2}
        onClick={() => onRemoveUser(2)}
      />
    </Flex>
  );
};

export default ShowPickAvatar;
