// Chakra imports
import React, { useEffect, useState } from "react";

import { Box, useMediaQuery } from "@chakra-ui/react";

import { SidebarContext } from "contexts/SidebarContext";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";

import AddColleague from "views/admin/addColleague";
import AddFriend from "views/admin/addFriend";
import FriendDetail from "views/admin/friendDetail";
import Matching from "views/admin/matchingWeb";
import PersonaWeb from "views/admin/personaWeb";

import ProfilingWeb from "views/admin/profilingWeb";
import ProfilingCompleteWeb from "views/admin/profilingCompleteWeb";
import ProfilingStandardWeb from "views/admin/profilingStandardWeb";
import EditProfile from "views/admin/editProfile";
import Payment from "views/admin/payment";

import Header from "./Header";
import Footer from "components/footer/FooterAdmin.js";
import SidebarAdmin from "./SidebarAdmin";

export default function Dashboard(props) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const currentUser = localStorage.getItem("user");

    if (currentUser) {
      try {
        const parsedUser = JSON.parse(currentUser);
        setUser(parsedUser);
      } catch (error) {
        console.error("Error parsing user data:", error);
        setUser(null);
      }
    }
  }, []);

  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };

  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };

  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const isAuthenticated = () => {
    return !!localStorage.getItem("user");
  };

  const PrivateRoute = ({ children, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? children : <Redirect to="/forbidden" />
      }
    />
  );

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/dashboard") {
        return (
          <PrivateRoute
            key={key}
            path={prop.layout + prop.path}
            component={prop.component}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  document.documentElement.dir = "ltr";

  const [isOpen, setIsOpen] = useState(() => {
    const savedState = localStorage.getItem("isOpen");
    return savedState !== null ? JSON.parse(savedState) : true;
  });

  const [isBelowMd] = useMediaQuery("(max-width: 48em)"); // 48em is md breakpoint

  // Simpan nilai isOpen ke localStorage setiap kali berubah
  useEffect(() => {
    localStorage.setItem("isOpen", JSON.stringify(isOpen));
  }, [isOpen]);

  useEffect(() => {
    if (isBelowMd) {
      setIsOpen(false);
    }
  }, [isBelowMd, setIsOpen]);

  const toggleSidebar = () => setIsOpen(!isOpen);

  return (
    <Box>
      {/* Header */}
      <header>
        <Header
          toggleSidebar={toggleSidebar}
          isOpen={isOpen}
          logoText={"Horizon UI Dashboard PRO"}
          brandText={getActiveRoute(routes)}
          secondary={getActiveNavbar(routes)}
          message={getActiveNavbarText(routes)}
          user={user}
        />
      </header>

      {/* Layout */}
      <Box>
        <SidebarContext.Provider value={{ toggleSidebar }}>
          {/* Sidebar */}
          <SidebarAdmin
            isOpen={isOpen}
            toggleSidebar={toggleSidebar}
            routes={routes}
            setIsOpen={setIsOpen}
            {...props}
          />

          {/* Main Content */}
          <main aria-labelledby="admin-main-content">
            <Box
              bgColor="#f6f6f6"
              pt="85px"
              float="right"
              minHeight="100vh"
              height="100%"
              overflow="auto"
              position="relative"
              w={{
                base: "100%",
                sm: isOpen ? "100%" : "85%",
                md: isOpen ? "83%" : !isOpen ? "93%" : "95%",
                lg: isOpen ? "85%" : "95%",
              }}
              transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
              transitionProperty="width, opacity"
              transitionDuration=".2s, .2s, .35s"
              transitionTimingFunction="ease"
            >
              {getRoute() && (
                <Box
                  mx="auto"
                  p={{ base: "20px", md: "30px" }}
                  pe="20px"
                  minH="100vh"
                  pt="50px"
                >
                  {/* Routes */}
                  <Switch>
                    <Route
                      path={`/dashboard/profile/:username/profiling-complete-web`}
                      component={ProfilingCompleteWeb}
                    />
                    <Route
                      path={`/dashboard/profile/:username/profiling-standard-web`}
                      component={ProfilingStandardWeb}
                    />
                    <Route
                      path={`/dashboard/profile/:username/profiling-web`}
                      component={ProfilingWeb}
                    />
                    <Route
                      path={`/dashboard/profile/:username/persona-web`}
                      component={PersonaWeb}
                    />
                    <Route
                      path={`/dashboard/profile/:username/matching-web`}
                      component={Matching}
                    />
                    <Route
                      path={`/dashboard/profile/:username/checkout/:productId`}
                      component={Payment}
                    />
                    <Route
                      path={`/dashboard/profile/:username/edit`}
                      component={EditProfile}
                    />
                    <Route
                      path={`/dashboard/friend-list/add-friend`}
                      component={AddFriend}
                    />
                    <Route
                      path={`/dashboard/friend-list/add-colleague`}
                      component={AddColleague}
                    />
                    <Route
                      path={`/dashboard/friend-list/:type/:id/profiling-complete-web`}
                      component={ProfilingCompleteWeb}
                    />
                    <Route
                      path={`/dashboard/friend-list/:type/:id/profiling-standard-web`}
                      component={ProfilingStandardWeb}
                    />
                    <Route
                      path={`/dashboard/friend-list/:type/:id/matching-web`}
                      component={Matching}
                    />
                    <Route
                      path={`/dashboard/friend-list/:type/:id/profiling-web`}
                      component={ProfilingWeb}
                    />
                    <Route
                      path={`/dashboard/friend-list/:type/:id/persona-web`}
                      component={PersonaWeb}
                    />
                    <Route
                      path={`/dashboard/friend-list/:type/:userId/checkout/:productId`}
                      component={Payment}
                    />
                    <Route
                      path={`/dashboard/friend-list/:type/:id`}
                      component={FriendDetail}
                    />
                    {getRoutes(routes)}
                    <Redirect from="/dashboard" to="/dashboard/me" />
                  </Switch>
                </Box>
              )}

              {/* Footer */}
              <footer>
                <Footer />
              </footer>
            </Box>
          </main>
        </SidebarContext.Provider>
      </Box>
    </Box>
  );
}
