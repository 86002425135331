import React from "react";
import { Box, Text, Button, Flex, useDisclosure } from "@chakra-ui/react";

const ErrorModal = ({ error , title}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  React.useEffect(() => {
    if (error) {
      onOpen(); 
    }
  }, [error, onOpen]);

  return (
    <>
      {isOpen && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          backgroundColor="rgba(0, 0, 0, 0.5)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="1000"
        >
          <Box
            bgColor="white"
            p={6}
            borderRadius="xl"
            boxShadow="lg"
            width={{ base: "90%", md: "400px" }}
          >
            <Text color="red.500" fontWeight="bold">
              {title ? title : "Error !!!"}
            </Text>
            <Text color="gray.700" mt={2} fontSize="sm">
              {error}
            </Text>
            <Flex justifyContent="flex-end" mt={4}>
              <Button
                bgColor="gray.500"
                _hover={{
                  backgroundColor: "gray.600",
                }}
                px={4}
                py={2}
                borderRadius={"full"}
                fontSize="xs"
                color="white"
                onClick={onClose}
                boxShadow={"xl"}
              >
                Close
              </Button>
            </Flex>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ErrorModal;
