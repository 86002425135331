import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Checked from '../../assets/svg/checked';
  
  const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: '#8C9197',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: 'transparent',
      border: '5px solid #1A7431',
      padding: 2,
      borderRadius: 10,
      width: 5,
      height: 5
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#1A7431',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 20,
      height: 20,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }));
  

const QontoStepIcon = (props) => {
    const { active, completed, className } = props;
  
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Checked />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

export default QontoStepIcon

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};