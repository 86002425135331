import React from "react";

// Chakra imports
import { Flex, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import FinalLogo from "assets/img/newimg/finallogo.png";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  return (
    <Flex align="center" direction="column">
      <Link to="/dashboard/me">
        <Image
          src={FinalLogo}
          alt=""
          objectFit="cover"
        />
      </Link>

      <HSeparator mb="2" />
    </Flex>
  );
}

export default SidebarBrand;
