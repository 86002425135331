/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import useStyles from "./style";
import StepIcon from "components/StepIcon";
import Back from "assets/svg/back";
import PaymentMethod from "components/PaymentMethod";
import { Box, Button, Text } from "@chakra-ui/react";
import KotakPutih from "tomslock/component/KotakPutih";
import axiosInstance from "tomslock/utils/axiosInstance";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#8C9197",
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1A7431",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1A7431",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const steps = ["Confirm Order", "Payment"];

const Payment = (props) => {
  const [user, setUser] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [service, setService] = useState([]);
  const styles = useStyles();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (props?.match?.params?.userId && props?.match?.params?.type) {
      axiosInstance
        .get(
          `/user/${props?.match?.params?.type}/${props.match.params.userId}`,
          {
            headers: {
              Authorization: `Bearer ${token ? token : ""}`,
              "Content-Type": "x-www-from-urlencode",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": "true",
              "Access-Control-Allow-Headers":
                "Origin, X-Requested-With, Content-Type, Accept",
            },
          }
        )
        .then((res) => {
          setUser(res.data.user ?? res.data.colleagues);
        })
        .catch((err) => {
          if (
            err.response?.data?.message === "Unauthorized" ||
            err.response?.data === "Unauthorized"
          ) {
            // navigate('/');
          }
        });
    } else {
      let localUser = localStorage.getItem("user");
      if (localUser) {
        setUser(JSON.parse(localUser));
      } else {
        window.location.assign("/home");
      }
    }
    if (token) {
      axiosInstance
        .get(
          `/product/list/${props?.match?.params?.productId}`,
          {
            headers: {
              Authorization: `Bearer ${token ? token : ""}`,
              "Content-Type": "x-www-from-urlencode",
            },
          }
        )
        .then((res) => {
          setService(res.data.products);
        })
        .catch((err) => {
          if (
            err.response.data.message === "Unauthorized" ||
            err.response.data === "Unauthorized"
          ) {
            // navigate('/');
          }
        });
    } else {
      // navigate('/')
    }
  }, []);

  return (
    <>
      <Box mb={4}>
        <Text fontWeight="bold" fontSize={"3xl"}>
          Draft Invoice Pembayaran
        </Text>
        <Text>{user && user.name}</Text>
      </Box>
      <KotakPutih p={6}>
        <Box>
          <div
            onClick={
              activeStep === 0
                ? () => window.history.go(-1)
                : () => setActiveStep(activeStep - 1)
            }
            className={styles.backButton}
          >
            <Back />
          </div>
          <div className="step">
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<QontoConnector />}
            >
              {steps.map((label, index) => {
                const stepProps = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </Box>

        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
          </React.Fragment>
        ) : (
          <>
            {activeStep === 0 && (
              <div className={styles.containerStepTwo}>
                <div className={styles.containerTopTwo}>
                  <h4 className="title">Draft invoice</h4>
                  <p className="content">
                    Please check again the data you provided below and service
                    you want to order.{" "}
                  </p>
                </div>
                <div className={styles.containerCenter}>
                  <div className={styles.containerLeft}>
                    <div className={styles.containerOrder}>
                      <h6 className="title">Name</h6>
                      <p className="content">{user.name}</p>
                    </div>
                    <div className={styles.containerOrder}>
                      <h6 className="title">Birth Date</h6>
                      <p className="content">{user.birth_date}</p>
                    </div>
                    <div className={styles.containerOrder}>
                      <h6 className="title">Gender</h6>
                      <p className="content">
                        {user.gender === 0 ? "Male" : "Female"}
                      </p>
                    </div>
                    <div className={styles.containerOrder}>
                      <h6 className="title">Service Type</h6>
                      <p className="content">{service?.name}</p>
                    </div>
                  </div>
                  <div className={styles.containerLeft}>
                    <div className={styles.containerOrder}>
                      <h6 className="title">Total Price</h6>
                      <div>
                        <p className="content">
                          Rp{" "}
                          {new Intl.NumberFormat("id-ID").format(
                            service?.price
                          )}
                        </p>
                        <p className="note">Price exclude tax and fee</p>
                        <Button
                          borderWidth={1}
                          borderColor="#1A7431"
                          bgColor="#1A7431"
                          boxShadow="xl"
                          _hover={{
                            backgroundColor: "green.500",
                          }}
                          color="#fff"
                          fontSize="sm"
                          w="100%"
                          fontWeight="bold"
                          borderRadius="full"
                          px="5"
                          py="3"
                          onClick={() => setActiveStep(activeStep + 1)}
                        >
                          {"Lanjutkan pembayaran"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeStep === 1 && (
              <PaymentMethod
                productId={service?.id}
                user={user}
                isUser={props?.match?.params?.type !== "colleague"}
              />
            )}
          </>
        )}
      </KotakPutih>
    </>
  );
};

export default Payment;
