import React from "react";
import { Flex, Box, Text, Button, Image } from "@chakra-ui/react";

import MaleProfile from "assets/img/newimg/profile-default.jpg";
import FemaleProfile from "assets/img/newimg/profile-female.jpg";
import PublicButton from "./PublicButtonWithModal";
import { formatDate } from "tomslock/utils/formatingInput";


const UserPickSelect = ({
  user,
  onClick,
  noUrut,
  onChangeUser1,
  onChangeUser2,
}) => {
  return (
    <Flex width="100%" flexDirection="column">
      {user ? (
        <Box
          gap={1}
          flexDirection="column"
          bgColor="white"
          boxShadow="md"
          border={"1px solid #dddccc"}
          borderRadius="2xl"
          w="240px"
          py={4}
          px={4}
        >
          {user?.photo ? (
            <Box borderRadius="xl" w="100%" h="200px" overflow="hidden">
              <Box
                borderRadius="xl"
                w="100%"
                h={{ base: "100%", sm: "100%", md: "280px" }}
                overflow="hidden"
                backgroundImage={`url(${
                  user?.photo ||
                  (!user || Number(user.gender) === 0
                    ? MaleProfile
                    : FemaleProfile)
                })`}
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
              />
            </Box>
          ) : (
            <Flex align="center" justify="center" pt={3}>
              <Image
                borderRadius="full"
                boxSize="150px"
                src={Number(user.gender) === 0 ? MaleProfile : FemaleProfile}
                alt={Number(user.gender)}
                loading="lazy"
              />
            </Flex>
          )}

          <Flex flexDirection="column" textAlign="center" mt={4}>
            <Box mb={2}>
              <Text
                fontSize={{ sm: "xs", md: "sm" }}
                fontWeight="bold"
                color={user?.gender === 0 ? "blue.600" : "#ed1aae"}
              >
                {user?.name}
              </Text>
              <Text fontSize={{ sm: "xs", md: "sm" }}>
                {formatDate(user?.birth_date)}
              </Text>
              <Text fontSize={{ sm: "xs", md: "sm" }}>
                {user?.gender === 0 ? "Male" : "Female"}
              </Text>
            </Box>
            <Button
              onClick={onClick}
              px="4"
              py="2"
              bgColor="red.600"
              color="white"
              _hover={{ backgroundColor: "tomato" }}
              borderRadius="lg"
              boxShadow="xl"
            >
              <Text fontSize="xs">Remove {user.name}</Text>
            </Button>
          </Flex>
        </Box>
      ) : (
        <>
          <Flex alignItems="center" gap={3} flexDirection="column">
            <Box
              p={2}
              border="2px solid #ccc"
              // border="2px solid #df7238"
              borderRadius="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Image
                src={MaleProfile}
                w="160px"
                h="160px"
                borderRadius="full"
                loading="lazy"
                alt="MaleProfile"
              />
            </Box>
            <Box>
              <Text
                fontSize={{ sm: "xs", md: "sm" }}
                textAlign="center"
                fontWeight="bold"
              >
                User {noUrut}
              </Text>
              <Text fontSize={"xs"} textAlign="center">
                belum dipilih
              </Text>
            </Box>

            {user !== "guess" ? (
              ""
            ) : (
              <Box>
                <PublicButton
                  visibleSearchUser={noUrut === 1 ? "user1" : "user2"}
                  onChangeUser1={onChangeUser1}
                  onChangeUser2={onChangeUser2}
                  user={user}
                />
              </Box>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default UserPickSelect;
