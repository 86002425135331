import React, { useState } from "react";
import { Button, Flex, Text, Icon, Box } from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";

import LoggedSearchPickUserModal from "./LoggedSearchPickUserModal";

const SelectUserButton = ({ user, user1, user2, onSelectMe }) => (
  <Button
    bgColor="blue.400"
    color="white"
    fontSize={{ sm: "xs", md: "sm" }}
    fontWeight="bold"
    borderRadius="25"
    onClick={onSelectMe}
    px="4"
    flex={1}
    py="2"
    boxShadow="lg"
    disabled={user2 === user}
    hidden={user1}
    w={32}
  >
    <Text
      overflow="hidden"
      fontSize={{ sm: "xs", md: "sm" }}
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      maxWidth="100%"
    >
      Pilih {user?.name}
    </Text>
  </Button>
);

const FindColleagueButton = ({ onOpen, user2 }) => (
  <Button
    bgColor="green.400"
    color="white"
    fontSize={{ sm: "xs", md: "sm" }}
    fontWeight="bold"
    borderRadius="25"
    onClick={() => onOpen("colleague")}
    px="4"
    flex={1}
    py="2"
    boxShadow="lg"
    _hover={{ backgroundColor: "green.500" }}
    _active={{
      backgroundColor: "green.400",
      border: "4px solid #81fccf ",
    }}
    hidden={user2}
    w={32}
  >
    <Flex gap={2} alignItems="center" justifyContent="center">
      <Icon as={Search2Icon} />
      <Text fontSize={{ sm: "xs", md: "sm" }}>Colleague</Text>
    </Flex>
  </Button>
);

const FindUserLainButton = ({ onOpen, user2 }) => (
  <Button
    bgColor={"tomato"}
    px={4}
    py={2}
    borderRadius={"full"}
    color={"white"}
    fontSize="sm"
    boxShadow="lg"
    _hover={{ backgroundColor: "orange" }}
    _active={{
      backgroundColor: "tomato",
      border: "4px solid #fcbf81 ",
    }}
    display="flex"
    gap={1}
    fontWeight="bold"
    onClick={() => onOpen("user")}
    flex={1}
    hidden={user2}
    w={32}
  >
    <Flex gap={2} alignItems="center" justifyContent="center">
      <Icon as={Search2Icon} />
      <Text fontSize={{ sm: "xs", md: "sm" }}>User Lain</Text>
    </Flex>
  </Button>
);

const LoggedButtonPickUser = ({
  user,
  user2,
  user1,
  onSelectMe,
  onChangeSearch,
  searchData,
  onSelectUser,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [visibleSearchUser, setVisibleSearchUser] = useState(null);

  const handleOpen = (type) => {
    setVisibleSearchUser(type);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setVisibleSearchUser(null);
  };

  return (
    <>
      {user !== "guess" && (!user1 || !user2) && (
        <Box w="full" maxW={{ base:"100%", sm:"100%", md:"85%" }} mx="auto">
          <Box borderBottom="1px solid #ccc" pb={4} mx="auto" />

          <Box
            display="flex"
            gap={4}
            alignItems="center"
            mt={6}
            bg={user1 && user2 ? "transparent" : "gray.100"}
            p={3}
            borderRadius="full"
            maxW={{ base:"100%", sm:"100%", lg:"65%" }}
            mx="auto"
          >
            <SelectUserButton
              user={user}
              user2={user2}
              user1={user1}
              onSelectMe={onSelectMe}
            />
            <FindUserLainButton onOpen={handleOpen} user2={user2} />
            <FindColleagueButton onOpen={handleOpen} user2={user2} />
          </Box>

          <LoggedSearchPickUserModal
            onChangeSearch={onChangeSearch}
            searchData={searchData}
            onSelectUser={onSelectUser}
            isOpen={isOpen}
            onClose={handleClose}
            visibleSearchUser={visibleSearchUser}
          />
        </Box>
      )}
    </>
  );
};

export default LoggedButtonPickUser;
