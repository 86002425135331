import React from "react";
import { Box, Flex, Image, Text, VStack } from "@chakra-ui/react";

const BannerLoggedUser = ({ user, ...rest }) => {
  return (
    <Box mb={6} borderRadius="2xl" p={6} bgColor="white" boxShadow={"lg"} {...rest}>
      <Flex
        alignItems="center"
        gap={4}
        flexDirection={{ base: "column", md: "row" }} 
      >
        <Image
          borderRadius="full"
          border="2px solid gray"
          boxSize="100px"
          src={user?.photo}
          alt={user?.name}
          objectFit="cover"
          transition="transform 0.2s"
          _hover={{ transform: "scale(1.05)" }}
          loading="lazy"
        />
        <VStack align={{ base:"center", lg:"start" }}>
          <Text color="gray.700" fontWeight="bold" fontSize="xl">
            {user?.name}
          </Text>
          <Text color="gray.700" fontSize="sm">
            {user?.email}
          </Text>
          <Text color="gray.700" fontSize="sm">
            {user?.gender === 0 ? "Male" : "Female"}
          </Text>
        </VStack>
      </Flex>
    </Box>
  );
};

export default BannerLoggedUser;
