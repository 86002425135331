import { Flex, Avatar, Text } from "@chakra-ui/react";

import {
  LinearProgress,
} from "@mui/material";

const ResultAvatarComparison = ({ user1, user2, stats, level, mode }) => {
  return (
    <Flex
      flexDirection={{ md: "row" }}
      px={{ sm: 0, md: 10 }}
      py={5}
      gap={10}
      alignItems="center"
    >
      {/* User 1 */}
      <UserProfile user={user1} />

      {/* Stats and Progress */}
      <Flex direction="column" width="100%">
        <Text textAlign="center" fontSize="4xl" fontWeight="bold">
          {stats}%
        </Text>
        <LinearProgress
          sx={{ height: 20, borderRadius: 3, width: "100%" }}
          color={mode === 0 ? "warning" : mode === 1 ? "error" : "info"}
          variant="determinate"
          value={stats}
        />
        <Text textAlign="center" fontSize="sm" fontWeight="bold">
          {level}
        </Text>
      </Flex>

      {/* User 2 */}
      <UserProfile user={user2} />
    </Flex>
  );
};

const UserProfile = ({ user }) => (
  <Flex flexDirection="column" gap={5}>
    <Flex alignItems="center" gap={1} flexDirection="column">
      <Avatar
        src={user.photo}
        h={{ sm: "50px", md: "240px" }}
        w={{ sm: "50px", md: "240px" }}
        border="4px solid gray"
        boxShadow="lg"
        transition="all 0.3s ease"
        _hover={{
          transform: "scale(1.1)",
          boxShadow: "2xl",
        }}
        alt={user?.name || "user"}
        loading="lazy"
      />

      <Flex flexDirection="column" textAlign="center">
        <Text
          textOverflow="ellipsis"
          fontSize={{ sm: "xs", md: "sm" }}
          overflow="hidden"
          whiteSpace="nowrap"
          maxW={80}
          fontWeight="bold"
        >
          {user.name}
        </Text>
        <Text fontSize={{ sm: "xs", md: "xs" }}>
          {user.gender === 0 ? "Male" : "Female"}
        </Text>
      </Flex>
    </Flex>
  </Flex>
);

export default ResultAvatarComparison;
