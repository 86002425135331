import React, { useEffect, useState } from "react";
import { Button } from "@chakra-ui/react";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <Button
          position="fixed"
          zIndex={3}
          bottom="30px"
          right={{base:"4", sm: "4", md: "30px" }}
          colorScheme="teal"
          onClick={scrollToTop}
          boxShadow="xl"
          borderRadius="full"
          size="lg"
          bgColor="red"
          _hover={{ backgroundColor: "white", color: "red" }}
          _focus={{
            backgroundColor: "orange.100",
            borderColor: "orange",
            border: "2px solid orange",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke={isHovered ? "red" : "white"} 
            className="size-6"
            width="48"
            height="48"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m15 11.25-3-3m0 0-3 3m3-3v7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </Button>
      )}
    </>
  );
};

export default ScrollToTop;
