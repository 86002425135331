import React from "react";
import { Box, Button, Image, Text } from "@chakra-ui/react";

const TypePickMode = ({ imageSrc, buttonLabel, isActive, onClick, color }) => {
  return (
    <Box
      p={6}
      w={{ base: "full", sm: "full", lg:"300px" }}
      h="auto"
      mx="auto"
      borderRadius="2xl"
      justifyContent="center"
      alignItems="center"
      boxShadow="lg"
      borderWidth={1}
      bgColor="white"
    >
      <Image
        src={imageSrc}
        alt={buttonLabel}
        width="100%"
        borderRadius="xl"
        mb={4}
      />
      <Button
        onClick={onClick}
        color={isActive ? "white" : color}
        bgColor={isActive ? color : "white"}
        borderWidth={1}
        borderColor={color}
        w="full"
        px={4}
        py={2}
        borderRadius="lg"
        _hover={{
          backgroundColor: color,
          color: "#ffffff",
          boxShadow: "xl",
        }}
      >
        <Text fontWeight="bold" fontSize="sm" align="center">
          {buttonLabel}
        </Text>
      </Button>
    </Box>
  );
};

export default TypePickMode;
