import React, { useState } from "react";
import { Box, Button, Flex, Text, Input } from "@chakra-ui/react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const PublicButton = ({ visibleSearchUser, onChangeUser1, onChangeUser2 }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name: "",
    birth_date: "",
    gender: 0,
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (visibleSearchUser === "user1") {
      onChangeUser1(userInfo);
    } else {
      onChangeUser2(userInfo);
    }
    handleCloseModal(); // Close the modal after saving
  };

  return (
    <>
      <Button
        bgColor={visibleSearchUser === "user1" ? "#3572EF" : "#ed1aae"}
        _hover={{
          backgroundColor:
            visibleSearchUser === "user1" ? "#2d61cb" : "#c52695",
        }}
        _focus={{
          backgroundColor:
            visibleSearchUser === "user1" ? "#3572EF" : "#ed1aae",
        }}
        color={"#fff"}
        fontSize={{ sm: "xs", md: "sm" }}
        fontWeight="bold"
        borderRadius="full"
        onClick={handleOpenModal}
        boxShadow="lg"
        width="32"
        px="4"
        py="2"
      >
        <Flex gap={2} alignItems="center" justifyContent="center">
          <Text fontSize={{ sm: "xs", md: "sm" }}>
            Pilih {visibleSearchUser === "user1" ? "User 1" : "User 2"}
          </Text>
        </Flex>
      </Button>

      {isModalOpen && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          backgroundColor="rgba(0, 0, 0, 0.5)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="1000"
        >
          <Box
            backgroundColor="white"
            p="6"
            borderRadius="md"
            boxShadow="lg"
            width={{ base: "90%", md: "600px", lg: "600px" }}
            maxWidth="90vw"
          >
            <Text fontSize="lg" fontWeight="bold" mb="4" align="start">
              Pilih {visibleSearchUser === "user1" ? "User 1" : "User 2"}
            </Text>
            <Text mb="4" align="start">
              Isi dengan informasi pengguna pertama di sini.
            </Text>

            <Flex flexDirection="column" gap={2} width="100%">
              <Flex flexDirection="column" gap={2}>
                <Box>
                  <Text fontSize="sm" mb="2" align="start" color="gray.600">
                    Nama
                  </Text>
                  <Input
                    px={3}
                    py={2}
                    borderRadius={5}
                    border="1px solid"
                    width="100%"
                    name="name"
                    fontSize={{ sm: "xs", md: "sm" }}
                    placeholder="Input name"
                    value={userInfo.name}
                    onChange={handleInputChange}
                  />
                </Box>

                <Box>
                  <Text fontSize="sm" mb="2" align="start" color="gray.600">
                    Tanggal Lahir
                  </Text>
                  <Input
                    px={3}
                    py={2}
                    borderRadius="lg"
                    border="1px solid #dddcce"
                    width="100%"
                    type="date"
                    name="birth_date"
                    fontSize={{ sm: "xs", md: "sm" }}
                    value={userInfo.birth_date}
                    onChange={handleInputChange}
                  />
                </Box>

                <Box>
                  <Text fontSize="sm" mb="2" align="start" color="gray.600">
                    Gender
                  </Text>
                  <RadioGroup
                    name="gender"
                    value={userInfo.gender}
                    onChange={(event) => {
                      // Convert the value to a number before updating state
                      setUserInfo((prev) => ({
                        ...prev,
                        gender: Number(event.target.value), // Convert to number
                      }));
                    }}
                  >
                    <FormControlLabel
                      value={0} // Male
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value={1} // Female
                      control={<Radio />}
                      label="Female"
                    />
                  </RadioGroup>
                </Box>
              </Flex>
            </Flex>

            <Flex justifyContent="end" gap={2} mt={4}>
              <Button
                colorScheme="gray"
                onClick={handleCloseModal}
                bgColor="gray.600"
                color="white"
                px="4"
                py="2"
                borderRadius="xl"
                fontSize="sm"
                boxShadow="lg"
              >
                Tutup
              </Button>

              <Button
                bgColor="tomato"
                color="white"
                fontSize={{ sm: "xs", md: "sm" }}
                fontWeight="bold"
                borderColor="#3572EF"
                px="4"
                py="2"
                boxShadow="lg"
                borderRadius="xl"
                onClick={handleSave} // Call handleSave on button click
              >
                Save
              </Button>
            </Flex>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PublicButton;
