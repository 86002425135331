import { Box, Button, Text, Image } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ForbiddenImg from "assets/img/newimg/403.webp";

const Forbidden = () => {
  return (
    <Box
      h="100vh"
      px={16}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box textAlign="center">
        <Image
          src={ForbiddenImg}
          alt="Forbidden"
          width={{ base: "200px", lg: "400px" }}
          mx="auto"
        />
        <Text
          as="h1"
          fontSize={{ base: "3xl", lg: "90px" }}
          fontWeight={"bold"}
          color={"red.500"}
        >
          403{" "}
          <Box
            as={"span"}
            fontSize={{ base: "3xl", lg: "50px" }}
            color={"gray.700"}
          >
            - Forbidden
          </Box>
        </Text>
        <br />
        <Text as="h2" mt={-6} mb={6}>
          Maaf, Anda tidak memiliki Akses. Silahkan Login atau kembali ke
          halaman utama.
        </Text>

        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          gap={2}
          justifyContent="center"
          alignItems="center"
          mx="auto"
        >
          <Button
            bgColor="gray"
            _hover={{ backgroundColor: "gray.500" }}
            color={"white"}
            py={2}
            px={4}
            borderRadius={"full"}
            fontSize={"sm"}
            boxShadow={"lg"}
            as={Link}
            to={"/login"}
            w={{ base: 64, lg: "max" }}
          >
            Login
          </Button>
          <Button
            bgColor="tomato"
            _hover={{ backgroundColor: "orange.500" }}
            color={"white"}
            py={2}
            px={4}
            borderRadius={"full"}
            fontSize={"sm"}
            boxShadow={"lg"}
            as={Link}
            to={"/home"}
            w={{ base: 64, lg: "max" }}
          >
            Kembali ke Halaman Utama
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Forbidden;
