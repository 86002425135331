import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const ProfilePart1 = ({ user }) => {
  return (
    <Box
      bg="white"
      borderRadius="2xl"
      boxShadow="md"
      padding={4}
      display="flex"
      flexDirection="column" // Membuat konten di dalam Box bisa ditata dalam kolom
      height="100%" // Memastikan semua Box memiliki tinggi penuh
    >
      <Box align="center" justify="center" mb={6}>
        <Box mt={6}>
          <Image
            borderRadius="full"
            border={"2px solid gray"}
            boxSize="100px"
            src={user?.photo}
            alt={user?.name}
            loading="lazy"
            objectFit="cover"
            transition="transform 0.2s" // Add a transition for the scale effect
            _hover={{ transform: "scale(1.05)" }} // Apply scale on hover
          />
        </Box>

        <Box mb={6}>
          <Text fontWeight={"bold"} fontSize={"2xl"} color="gray.700">
            {user?.name}
          </Text>
          <Text fontSize={"sm"} color="gray.700">
            {user?.birth_location}
          </Text>
          <Text f fontSize={"sm"} color="gray.700">
            {user?.birth_date}
          </Text>
        </Box>

        {/* Medsos  */}
        <Flex align="center" justify="center" mb={6} gap={2}>
          <Button bgColor="blue.400" w={8} h={8} p={2} borderRadius={"full"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              stroke="#ffffff"
              className="size-6"
              width="24"
              height="24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z"
              />
            </svg>
          </Button>

          <Button bgColor="blue.400" w={8} h={8} p={2} borderRadius={"full"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              stroke="#ffffff"
              className="size-6"
              width="24"
              height="24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z"
              />
            </svg>
          </Button>

          <Button bgColor="blue.400" w={8} h={8} p={2} borderRadius={"full"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              stroke="#ffffff"
              className="size-6"
              width="24"
              height="24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
              />
            </svg>
          </Button>
        </Flex>
        {/* End Medsos  */}

        {/* Button  */}
        <Box mb={6}>
          {/* <Flex align="center" justify="center" mb={3} gap={2}>
            <Button
              bgColor="tomato"
              fontSize="xs"
              color="white"
              w={"max"}
              px={3}
              py={1.5}
              borderRadius={"lg"}
              boxShadow={"lg"}
              as={Link}
              to={`/dashboard/profile/${user?.username}/edit`}
              _hover={{
                bgColor: "orange.400",
                textDecoration: "none",
                transition: "background-color 0.3s",
                color: "white",
              }}
              _active={{
                border: "2px solid",
                borderColor: "orange.200",
              }}
            >
              <Text color={"white"}>Connect</Text>
            </Button>
            <Button
              bgColor="tomato"
              fontSize="xs"
              color="white"
              px={3}
              py={1.5}
              borderRadius={"lg"}
              boxShadow={"lg"}
              as={Link}
              to={`/dashboard/profile/${user?.username}/edit`}
              _hover={{
                bgColor: "orange.400",
                textDecoration: "none",
                transition: "background-color 0.3s",
                color: "white",
              }}
              _active={{
                border: "2px solid",
                borderColor: "orange.200",
              }}
            >
              <Text color={"white"}>Message</Text>
            </Button>
          </Flex> */}

          <Button
            bgColor="gray.400"
            fontSize="xs"
            color="white"
            w={"36"}
            px={3}
            py={1.5}
            borderRadius={"lg"}
            boxShadow={"lg"}
            as={Link}
            to={`/dashboard/profile/${user?.username}/edit`}
            _hover={{
              bgColor: "gray",
              textDecoration: "none",
              transition: "background-color 0.3s",
              color: "white",
            }}
            _active={{
              border: "2px solid",
              borderColor: "gray.200",
            }}
          >
            <Text color={"white"}>Edit Profile</Text>
          </Button>
        </Box>
        {/* End Button  */}
      </Box>
    </Box>
  );
};

export default ProfilePart1;
