/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import qs from "qs";

import Select from "assets/svg/select";
import Selected from "assets/svg/selected";

import { styled } from "@mui/material/styles";

import PlaceholderPhoto from "assets/img/placeholderPhoto.png";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Button from "components/button";

import useStyles from "./style";

import { Avatar, Box, Flex, Grid, Image, Text, Input } from "@chakra-ui/react";

import KotakPutih from "tomslock/component/KotakPutih";
import ErrorModal from "tomslock/component/ErrorModal";
import ButtonAction from "tomslock/component/ButtonAction";
import axiosInstance from "tomslock/utils/axiosInstance";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EditProfile = () => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});
  const [avatars, setAvatars] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState();
  const [file, setFile] = useState();
  const [error, setError] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const tmpToken = localStorage.getItem("token");
        setToken(tmpToken);
        const res = await axiosInstance.get("/user/current", {
          headers: { Authorization: `Bearer ${tmpToken}` },
        });
        setUser(res.data.user);
      } catch (err) {
        if (err.response?.data?.message === "Unauthorized") {
          // navigate('/');
        }
        console.error("Failed to fetch user data:", err);
      }
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchAvatars = async () => {
      try {
        const res = await axiosInstance.get("/user/registration_data", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAvatars(res.data.data.avatars);
      } catch (err) {
        if (err.response?.data?.message === "Unauthorized") {
          // navigate('/');
        }
        console.error("Failed to fetch avatars:", err);
      }
    };
    if (token) {
      fetchAvatars();
    }
  }, [token]);

  const handleSubmit = async () => {
    try {
      await axiosInstance.post("/user/edit", qs.stringify({ ...user }), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      });
      window.location.assign("/dashboard/profile");
    } catch (err) {
      setError(
        err.response?.data?.message ||
          err.response?.data ||
          "Failed to update profile"
      );
      console.error("Failed to submit profile:", err);
    }
  };

  const onChangeInput = (e) => {
    const { id, value } = e.target;
    if (id === "password") {
      setUser({ ...user, password1: value, password2: value });
    } else {
      setUser({ ...user, [id]: value });
    }
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSelectAvatar = (avatar) => {
    setUser({ ...user, avatar: avatar.name });
    setSelectedAvatar(avatar);
  };

  const handleSaveAvatar = async () => {
    try {
      await axiosInstance.post(
        "/user/photo",
        qs.stringify({ avatar: selectedAvatar.name }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      window.location.reload();
    } catch (err) {
      setError(
        err.response?.data?.message ||
          err.response?.data ||
          "Failed to update avatar"
      );
      console.error("Failed to save avatar:", err);
    }
  };

  const handleChangePhoto = async (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    const formData = new FormData();
    formData.append("photo", uploadedFile);

    try {
      await axiosInstance.post("/user/photo", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      window.location.reload();
    } catch (err) {
      setError(
        err.response?.data?.message ||
          err.response?.data ||
          "Failed to upload photo"
      );
      console.error("Failed to upload photo:", err);
    }
  };

  return (
    <>
      {error && <ErrorModal error={error} />}
      <KotakPutih p={10}>
        <Box mb={4} borderBottom={"1px solid"} borderColor={"gray.200"} pb={4}>
          <Text fontSize={"xl"} fontWeight={"bold"}>
            Edit Profile
          </Text>
          <Text>{user?.name}</Text>
        </Box>
        <Box>
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 4fr" }}
            gap={16}
            alignItems="center"
          >
            <Box>
              <FormControl variant="standard">
                <div>
                  {user.photo ? (
                    <Image
                      src={selectedAvatar ? selectedAvatar.url : user.photo}
                      alt="Profile"
                      width={157}
                      className={styles.placeholderPhoto}
                    />
                  ) : (
                    <Image
                      src={PlaceholderPhoto}
                      alt="placeholder"
                      width={157}
                      className={styles.placeholderPhoto}
                    />
                  )}
                  <p className={styles.infoFile}>Maximum file size of 1 MB</p>
                </div>
                <div>
                  <Input
                    fullWidth
                    id="file"
                    style={{ display: "none" }}
                    className={styles.inputFile}
                    type="file"
                    onChange={handleChangePhoto}
                  />
                  <label shrink htmlFor="file" className={styles.labelBrowse}>
                    Browse Gallery
                  </label>
                  <div
                    className={styles.buttonUpload}
                    onClick={handleClickOpen}
                  >
                    <span>Browse Avatar</span>
                  </div>
                </div>
              </FormControl>
            </Box>
            <Box w={{ md: "50%" }}>
              <Box mb={4}>
                <Text fontSize={"sm"} mb={2}>
                  Display Name
                </Text>
                <FormControl variant="standard" className={styles.formControl}>
                  <Input
                    fullWidth
                    id="name"
                    value={user.name}
                    onChange={onChangeInput}
                    type="text"
                    placeholder="Display Name"
                    p={2}
                    bgColor={"gray.50"}
                    borderRadius={"lg"}
                    border={"1px solid #ddd"}
                    fontSize={"sm"}
                  />
                </FormControl>
              </Box>

              <Box mb={4}>
                <Text fontSize={"sm"} mb={2}>
                  Occupation
                </Text>
                <FormControl variant="standard" className={styles.formControl}>
                  <Input
                    fullWidth
                    id="occupation"
                    onChange={onChangeInput}
                    value={user.occupation}
                    type="text"
                    placeholder="Occupation"
                    p={2}
                    bgColor={"gray.50"}
                    borderRadius={"lg"}
                    fontSize={"sm"}
                    border={"1px solid #ddd"}
                  />
                </FormControl>
              </Box>

              <Box mb={4}>
                <Text fontSize={"sm"} mb={2}>
                  Tanggal Lahir
                </Text>
                <FormControl variant="standard" className={styles.formControl}>
                  <Input
                    fullWidth
                    id="birth_date"
                    onChange={onChangeInput}
                    value={user.birth_date}
                    type="date"
                    placeholder="Tanggal Lahir"
                    p={2}
                    bgColor={"gray.50"}
                    borderRadius={"lg"}
                    fontSize={"sm"}
                    border={"1px solid #ddd"}
                  />
                  <p className={styles.infoRequired}>
                    Hanya bisa diubah sekali
                  </p>
                </FormControl>
              </Box>

              <Box mb={4}>
                <Text fontSize={"sm"} mb={2}>
                  Jam Lahir
                </Text>
                <FormControl variant="standard" className={styles.formControl}>
                  <Input
                    fullWidth
                    id="birth_time"
                    onChange={onChangeInput}
                    value={user.birth_time}
                    type="time"
                    placeholder="Jam Lahir"
                    p={2}
                    bgColor={"gray.50"}
                    borderRadius={"lg"}
                    fontSize={"sm"}
                    border={"1px solid #ddd"}
                  />
                  <p className={styles.infoRequired}>
                    Hanya bisa diubah sekali
                  </p>
                </FormControl>
              </Box>

              <Box mb={4}>
                <Text fontSize={"sm"} mb={2}>
                  Tempat Lahir
                </Text>
                <FormControl variant="standard" className={styles.formControl}>
                  <Input
                    fullWidth
                    id="birth_location"
                    onChange={onChangeInput}
                    value={user.birth_location}
                    type="text"
                    placeholder="Tempat Lahir"
                    p={2}
                    bgColor={"gray.50"}
                    fontSize={"sm"}
                    borderRadius={"lg"}
                    border={"1px solid #ddd"}
                  />
                </FormControl>
              </Box>

              <Box mb={4}>
                <Text fontSize={"sm"} mb={2}>
                  Gender
                </Text>
                <FormControl
                  variant="gender"
                  className={styles.formControlSmall}
                >
                  <RadioGroup
                    row
                    id="gender"
                    value={Number(user.gender)}
                    defaultValue={Number(user.gender)}
                    onChange={onChangeInput}
                  >
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          id="gender"
                          icon={<Select />}
                          checkedIcon={<Selected />}
                        />
                      }
                      label="Pria"
                    />
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          id="gender"
                          icon={<Select />}
                          checkedIcon={<Selected />}
                        />
                      }
                      label="Wanita"
                    />
                  </RadioGroup>
                  <p className={styles.infoRequired}>
                    Hanya bisa diubah sekali
                  </p>
                </FormControl>
              </Box>

              <Box display={"flex"} gap={2}>
                <ButtonAction
                  onClick={() => window.location.assign("/dashboard/profile")}
                >
                  Cancel
                </ButtonAction>

                <ButtonAction onClick={() => handleSubmit()} color="orange">
                  Save
                </ButtonAction>
              </Box>
            </Box>
          </Grid>
        </Box>

        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Select Avatar
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Flex
              flexDirection="row"
              wrap="wrap"
              maxWidth="100%"
              gap={8}
              alignItems="center"
              justifyContent="center"
            >
              {avatars.length > 0 && (
                <>
                  {avatars.map((avatar, index) => (
                    <Flex
                      borderWidth={user.avatar === avatar.name ? 5 : 0}
                      borderRadius={500}
                      borderColor="#E24E06"
                      cursor="pointer"
                      onClick={() => handleSelectAvatar(avatar)}
                    >
                      <Avatar
                        mx="auto"
                        src={avatar.url}
                        h="130px"
                        w="130px"
                        borderWidth={
                          user.avatar === avatar.name ? "4px solid" : 0
                        }
                        borderColor={"#E24E06"}
                      />
                    </Flex>
                  ))}
                </>
              )}
            </Flex>
          </DialogContent>
          <DialogActions>
            <Button
              variant="primary"
              label="Select"
              onClick={handleSaveAvatar}
            />
          </DialogActions>
        </BootstrapDialog>
      </KotakPutih>
    </>
  );
};

export default EditProfile;
