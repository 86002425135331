import React from "react";
import { Box } from "@chakra-ui/react";
import TypePickMode from "./TypePickMode";

import MatchingFriendship from "../../assets/img/MatchingFriendship.webp";
import MatchingPartnership from "../../assets/img/MatchingPartnership.webp";
import MatchingRomance from "../../assets/img/MatchingRomance.webp";

const TypePickContainer = ({ mode, setMode }) => {
  const modes = [
    {
      imageSrc: MatchingPartnership,
      buttonLabel: "Partnership",
      color: "orange",
    },
    {
      imageSrc: MatchingRomance,
      buttonLabel: "Romance",
      color: "#FF0080",
    },
    {
      imageSrc: MatchingFriendship,
      buttonLabel: "Friendship",
      color: "#0988e5",
    },
  ];
  return (
    <Box
      mb={1}
      display="flex"
      flexDirection={{ base: "column", md: "row" }}
      justifyContent="space-between"
      alignItems="center"
      height={{ base: "auto", md: "600px" }}
      width="100%"
      maxWidth={{ lg:"1200px" }}
      mx="auto"
      mt={{ base: 16, md: -10 }}
      gap={{ base: 4, md: 12 }} 
      p={4}
    >
      {modes.map((item, index) => (
        <TypePickMode
          key={index}
          imageSrc={item.imageSrc}
          buttonLabel={item.buttonLabel}
          isActive={mode === index}
          onClick={() => setMode(index)}
          color={item.color}
          flex={{ base: "1 1 100%", md: "1 1 30%" }} // ambil 100% lebar di layar kecil
          marginY={{ base: 2, md: 0 }}
        />
      ))}
    </Box>
  );
};

export default TypePickContainer;
