import React from 'react';
import useStyles from './style';

const Button = props => {
  const {onClick, label, variant, fullWidth, style: customStyle} = props;
  const styles = useStyles();
  let style;
  if (variant === 'primary') {
    style = styles.primary;
  } else if (variant === 'secondary') {
    style = styles.secondary;
  } else if (variant === 'outlined') {
    style = styles.outlined;
  } else if (variant === 'alternate') {
    style = styles.alternate;
  } else {
    style = styles.button
  };
  return (
    <button onClick={onClick} className={style} style={{width: fullWidth ? '100%' : 'unset', ...customStyle}}>
      {label}
    </button>
  );
}

export default Button;
