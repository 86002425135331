import React from "react";
import { Box, Flex, Circle, Text } from "@chakra-ui/react";

const HorizontalTimeline = ({ currentStep }) => {
  const steps = [
    { label: "Tahap 1", description: "Pilih Tipe Matching" },
    { label: "Tahap 2", description: "Pilih Pengguna" },
    { label: "Tahap 3", description: "Hasil Matching" },
  ];

  return (
    <Box mx="auto" maxWidth={{ sm: "300px", md: "950px" }} mb={{ md: "16" }}>
      <Flex align="center" justify="center" mt={8} mb={4} gap={2}>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            {" "}
            {/* Using React.Fragment with key */}
            <Flex align="center" w={48}>
              <Flex direction="column" align="center">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  border={`4px solid `}
                  borderColor={`${
                    index <= currentStep ? "green.300" : "gray.300"
                  }`}
                  borderRadius="full"
                  p="3px"
                  boxShadow="md"
                >
                  <Circle
                    size="45px"
                    bg={index <= currentStep ? "green.400" : "gray.400"}
                    border={`2px solid ${
                      index <= currentStep ? "green.400" : "gray.400"
                    }`}
                    color="white"
                  >
                    <Text fontSize="xl" fontWeight="bold">
                      {index + 1}
                    </Text>
                  </Circle>
                </Box>

                <Text
                  align="center"
                  fontSize="sm"
                  fontWeight="bold"
                  mt={2}
                  color={index <= currentStep ? "green.300" : "gray.400"}
                >
                  {step.label}
                </Text>
                <Text fontSize="xs" color="gray.500" align="center">
                  {step.description}
                </Text>
              </Flex>
            </Flex>
            {index < steps.length - 1 && (
              <Box
                h={1}
                borderRadius={"full"}
                mt={-16}
                bgColor={index + 1 <= currentStep ? "green.300" : "gray.400"}
                w="full"
              />
            )}
          </React.Fragment> // Close the fragment here
        ))}
      </Flex>
    </Box>
  );
};

export default HorizontalTimeline;
