// components/Footer.js
import React from "react";
import { Box, Text, Stack, Link, Icon } from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";

const Footer = () => {
  return (
    <Box
      as="footer"
      width="100%"
      height={{ base: "full", md: "full" }}
      bg="gray.800"
      color="white"
      py={10}
      textAlign="center"
      mt="auto"
      mb={"-10px"}
    >
      <Stack
        direction={{ base: "column", md: "row" }}
        justify="space-between"
        align="center"
        maxW="1200px"
        mx="auto"
        px={4}
      >
        <Box mb={{ base: 4, md: 0 }}>
          <Text fontWeight="bold" mb={2}>
            N A V I G A T I O N
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={{ base: 0, md: 4 }}
            align="center"
          >
            <Link
              href="/about-us"
              color="white"
              px={3}
              fontSize={"sm"}
              py={{ base: 0, md: 1.5 }}
              borderRadius={"md"}
              _hover={{ backgroundColor: "tomato" }}
              _active={{
                backgroundColor: "transparent",
                borderColor: "tomato",
                border: "2px solid",
              }}
            >
              About Us
            </Link>
            <Text display={{ base: "none", md: "block" }}>|</Text>
            <Link
              href="/community-guideline"
              color="white"
              px={3}
              fontSize={"sm"}
              py={1.5}
              borderRadius={"md"}
              _hover={{ backgroundColor: "tomato" }}
              _active={{
                backgroundColor: "transparent",
                borderColor: "tomato",
                border: "2px solid",
              }}
            >
              Community Guideline
            </Link>
            <Text display={{ base: "none", md: "block" }}>|</Text>
            <Link
              href="/faq"
              color="white"
              px={3}
              fontSize={"sm"}
              py={{ base: 0, md: 1.5 }}
              borderRadius={"md"}
              _hover={{ backgroundColor: "tomato" }}
              _active={{
                backgroundColor: "transparent",
                borderColor: "tomato",
                border: "2px solid",
              }}
            >
              FAQ's
            </Link>
          </Stack>
        </Box>
        <Box mb={{ base: 4, md: 0 }}>
          <Text fontWeight="bold" mb={2}>
            L E G A L
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={{ base: 0, md: 4 }}
            align="center"
          >
            <Link
              href="/disclaimer"
              color="white"
              px={3}
              fontSize={"sm"}
              py={1.5}
              borderRadius={"md"}
              _hover={{ backgroundColor: "tomato" }}
              _active={{
                backgroundColor: "transparent",
                borderColor: "tomato",
                border: "2px solid",
              }}
            >
              Disclaimer
            </Link>
            <Text display={{ base: "none", md: "block" }}>|</Text>
            <Link
              href="/terms-of-service"
              color="white"
              px={3}
              py={1.5}
              fontSize={"sm"}
              borderRadius={"md"}
              _hover={{ backgroundColor: "tomato" }}
              _active={{
                backgroundColor: "transparent",
                borderColor: "tomato",
                border: "2px solid",
              }}
            >
              Terms of Service
            </Link>
            <Text display={{ base: "none", md: "block" }}>|</Text>
            <Link
              href="/privacy-policy"
              color="white"
              px={3}
              fontSize={"sm"}
              py={1.5}
              borderRadius={"md"}
              _hover={{ backgroundColor: "tomato" }}
              _active={{
                backgroundColor: "transparent",
                borderColor: "tomato",
                border: "2px solid",
              }}
            >
              Privacy Policy
            </Link>
          </Stack>
        </Box>
        <Box mb={{ base: 4, md: 0 }}>
          <Text fontWeight="bold" mb={2}>
            C O N T A C T
          </Text>
          <Stack direction="row" align="center">
            <Icon as={EmailIcon} color="teal.200" />
            <Link
              href="mailto:contact@personatalenta.id"
              color="white"
              px={3}
              py={1.5}
              fontSize={"sm"}
              borderRadius={"md"}
              _hover={{ backgroundColor: "tomato" }}
              _active={{
                backgroundColor: "transparent",
                borderColor: "tomato",
                border: "2px solid",
              }}
            >
              contact@personatalenta.id
            </Link>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default Footer;
