import React from "react";
import { Box, Image } from "@chakra-ui/react";
import Links from "./Links";
import ImgLogo from "assets/img/logo.png";
import { Link } from "react-router-dom";

function SidebarMiniContent(props) {
  const { routes, isOpen } = props;

  return (
    <Box justify="center" mx="auto">
      <Box
        mx="auto"
        as={Link}
        justify="center"
        align="center"
        my="6"
        to={"/dashboard/"}
      >
        <Image
          src={ImgLogo}
          alt="logo"
          boxSize="50px"
          border="2px solid #ff9c64"
          objectFit="contain"
          borderRadius="full"
          boxShadow={"lg"}
          mt={{ base: 4 }}
        />
      </Box>
      <Box my={6} borderBottom={"1px solid #ccc"}></Box>
      <Links routes={routes} isOpen={isOpen} />
    </Box>
  );
}

export default SidebarMiniContent;
