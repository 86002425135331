// Chakra imports
import React, { useEffect, useState } from "react";

import { Box, Grid } from "@chakra-ui/react";

// Custom components

import ServiceTable from "views/admin/profile/components/ServiceTable";
import { columnsDataService } from "views/admin/profile/variables/columnsData";
import axiosInstance from "tomslock/utils/axiosInstance";

export default function FriendDetail(props) {
  const { match } = props;
  const [user, setUser] = useState();
  const [service, setService] = useState();
  const [loadingItems, setLoadingItems] = useState({});

  useEffect(() => {
    let localUser = localStorage.getItem("user");
    let token = localStorage.getItem("token");
    if (localUser && token) {
      axiosInstance
        .get(`/user/${props.match.params.type}/${props.match.params.id}`, {
          headers: {
            Authorization: `Bearer ${token ? token : ""}`,
            "Content-Type": "x-www-from-urlencode",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        })
        .then((res) => {
          setUser(res.data.user ?? res.data.colleagues);
        })
        .catch((err) => {
          if (
            err.response?.data?.message === "Unauthorized" ||
            err.response?.data === "Unauthorized"
          ) {
            // navigate('/');
          }
        });
    } else {
      window.location.assign("/home");
    }
  }, [props]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axiosInstance
      .get(
        `/user/service/table-list-friend/${
          props.match.params.id
        }?is_has_user_id=${props.match.params.type === "friend"}`,
        {
          headers: {
            Authorization: `Bearer ${token ? token : ""}`,
            "Content-Type": "x-www-from-urlencode",
          },
        }
      )
      .then((res) => {
        if (res?.data?.products) {
          setService(res.data.products);
        }
      })
      .catch((err) => {
        if (
          err.response.data.message === "Unauthorized" ||
          err.response.data === "Unauthorized"
        ) {
          // navigate('/');
        }
      });
  }, [props]);

  const onClickDetail = (props) => {
    if (
      props.category === "File PDF" &&
      props.name === "Standard Profiling Book"
    ) {
      window.location.assign(
        `/dashboard/friend-list/${match.params.type}/${user?.id}/profiling-standard-web`
      );
    } else if (
      props.category === "File PDF" &&
      props.name === "Complete Profiling Book"
    ) {
      window.location.assign(
        `/dashboard/friend-list/${match.params.type}/${user?.id}/profiling-complete-web`
      );
    }
  };

  const onClickDownload = async (props) => {
    const token = localStorage.getItem("token");
    setLoadingItems((prev) => ({ ...prev, [props.index]: true }));

    let baseUrl;
    if (
      props.category === "File PDF" &&
      props.name === "Standard Profiling Book"
    ) {
      baseUrl = `/profiling/pdf/link?id=${user.id}&type=${
        match.params.type === "friend" ? 0 : 1
      }&version=standard`;
    } else if (
      props.category === "File PDF" &&
      props.name === "Complete Profiling Book"
    ) {
      baseUrl = `/profiling/pdf/link?id=${user.id}&type=${
        match.params.type === "friend" ? 0 : 1
      }&version=complete`;
    }

    try {
      const res = await axiosInstance.get(baseUrl, {
        headers: {
          Authorization: `Bearer ${token ? token : ""}`,
          "Content-Type": "x-www-from-urlencode",
        },
        responseType: "blob",
      });

      if (res?.data) {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(res.data);
        a.download = `${
          props.name === "Career Profiling Book"
            ? "Karir Terbaik Untuk"
            : props.name
        } ${user.name}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    } catch (err) {
      if (
        err.response?.data?.message === "Unauthorized" ||
        err.response?.data === "Unauthorized"
      ) {
        // navigate('/');
      }
    } finally {
      setLoadingItems((prev) => ({ ...prev, [props.index]: false }));
    }
  };

  const onClickCategory = (props) => {
    if (props.category === "Web View" && props.service === "webview") {
      window.location.assign(
        `/dashboard/friend-list/${match.params.type}/${user?.id}/profiling-web`
      );
    } else if (props.category === "Web View" && props.service === "Persona") {
      window.location.assign(
        `/dashboard/friend-list/${match.params.type}/${user?.id}/persona-web`
      );
    } else if (props.category === "Web View" && props.service === "matching") {
      window.location.assign(
        `/dashboard/friend-list/${match.params.type}/${user?.id}/matching-web`
      );
    }
  };

  // const onClickBuy = (props) => {
  //   if (props.service === "profiling") {
  //     window.location.assign(
  //       `/dashboard/friend-list/${match.params.type}/${user?.id}/product/profiling-book`
  //     );
  //   } else if (props.service === "webview") {
  //     window.location.assign(
  //       `/dashboard/friend-list/${match.params.type}/${user?.id}/product/profiling-web`
  //     );
  //   } else if (
  //     props.service === "Persona" &&
  //     props.name === "General Persona"
  //   ) {
  //     window.location.assign(
  //       `/dashboard/friend-list/${match.params.type}/${user?.id}/product/persona`
  //     );
  //   } else if (
  //     props.service === "Persona" &&
  //     props.name === "Daily Vibe Persona"
  //   ) {
  //     window.location.assign(
  //       `/dashboard/friend-list/${match.params.type}/${user?.id}/product/daily-vibe`
  //     );
  //   } else if (props.service === "matching") {
  //     window.location.assign(
  //       `/dashboard/friend-list/${match.params.type}/${user?.id}/product/matching`
  //     );
  //   }
  // };

  const onClickPrice = (props) => {
    if (props.service === "profiling" || props.service === "webview") {
      window.location.assign(
        `/pricing#profiling?type=${match.params.type}&id=${user?.id}`
      );
    } else if (
      props.service === "Persona" ||
      props.name === "General Persona" ||
      props.name === "Daily Vibe"
    ) {
      window.location.assign(
        `/pricing#persona?type=${match.params.type}&id=${user?.id}`
      );
      //   window.location.assign(`/dashboard/profile/${user?.username}/product/persona`)
    } else if (props.service === "matching") {
      window.location.assign(
        `/pricing#matching?type=${match.params.type}&id=${user?.id}`
      );
      //   window.location.assign(`/dashboard/profile/${user?.username}/product/matching`)
    }
  };

  return (
    <Box mt={{ sm: 5, md: 10 }}>
      <Grid>
        <ServiceTable
          columnsData={columnsDataService}
          tableData={service}
          onClickCategory={onClickCategory}
          onClickBuy={onClickPrice}
          onClickPrice={onClickPrice}
          onClickDetail={onClickDetail}
          onClickDownload={onClickDownload}
          loading={loadingItems}
          user={user}
          friend={true}
        />
      </Grid>
    </Box>
  );
}
