import { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  CircularProgress,
  Flex,
  Text,
  Box,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import ButtonMiddle from "tomslock/component/ButtonMiddle";

const ModalDownload = ({ row, index, loading, onClickAction }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDownloadClick = () => {
    setIsModalOpen(true);
    onClickAction({
      type: "Download",
      product: row.original,
      index: index,
    });
  };

  // Close the modal when loading is complete
  if (!loading[index] && isModalOpen) {
    setIsModalOpen(false);
  }

  return (
    <>
      <ButtonMiddle onClick={handleDownloadClick} color="green">
        {loading[index] ? (
          <CircularProgress thickness={4} size={15} color="gray.200" />
        ) : (
          "Download"
        )}
      </ButtonMiddle>

      {/* Conditional rendering of the overlay */}
      {isModalOpen && loading[index] && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          backgroundColor="rgba(0, 0, 0, 0.5)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="1000"
        >
          <Box
            bgColor="white"
            p={6}
            borderRadius="xl"
            boxShadow="lg"
            width={{ base: "90%", md: "400px" }}
            mx={"auto"}
          >
            <Text fontWeight="bold" mb={4}>
              Downloading...
            </Text>

            <CircularProgress isIndeterminate color="green.300" />

            <Text fontSize="sm" align="start" mt={4}>
              Unduhan Anda sedang berlangsung. Mohon tunggu sejenak, dan jangan
              tutup halaman ini!
            </Text>

            <Flex justifyContent="flex-end" mt={4}>
              <Button
                bgColor="gray.500"
                _hover={{
                  backgroundColor: "gray.600",
                }}
                px={4}
                py={2}
                borderRadius="full"
                fontSize="xs"
                color="white"
                boxShadow="xl"
                disabled
              >
                OK
              </Button>
            </Flex>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ModalDownload;
