import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  primary: {
    color: '#FFFFFF',
    backgroundColor: '#E24E06',
    border: 'none',
    fontSize: 16,
    fontWeight: 700,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 28px',
    gap: 10,
    borderRadius: 8,
    cursor: 'pointer',
    fontFamily: 'Manrope',
    '&:hover': {
        backgroundColor: '#B53E05',
    }
  },
  secondary: {
    color: '#F5F6F8',
    backgroundColor: '#404852',
    fontSize: 16,
    fontWeight: 700,
    display: 'flex',
    border: 'none',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 28px',
    gap: 10,
    borderRadius: 8,
    fontFamily: 'Manrope',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#262B31',
    }
  },
  outlined: {
    color: '#F5F6F8',
    backgroundColor: 'transparent',
    borderColor: '#F5F6F8',
    fontSize: 16,
    fontWeight: 700,
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 18px',
    gap: 10,
    borderRadius: 8,
    cursor: 'pointer',
    fontFamily: 'Manrope',
    '&:hover': {
        backgroundColor: '#E24E06',
        color: '#fff',
        borderColor: '#8C9197',
        borderWidth: 1
    }
  },
  alternate: {
    color: '#E24E06',
    backgroundColor: 'transparent',
    borderColor: '#E24E06',
    fontSize: 16,
    fontWeight: 700,
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 28px',
    gap: 10,
    borderRadius: 8,
    cursor: 'pointer',
    fontFamily: 'Manrope',
    '&:hover': {
        backgroundColor: '#E24E06',
        color: '#fff',
        borderColor: '#F5F6F8',
        borderWidth: 1
    }
  },
  button: {
    fontSize: 16,
    fontWeight: 700,
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 28px',
    gap: 10,
    borderRadius: 8,
    cursor: 'pointer',
    fontFamily: 'Manrope',
  }
}));
