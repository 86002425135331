// CardUser.js
import { Box, Button, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import React from "react";
import ButtonMiddle from "tomslock/component/ButtonMiddle";
import { formatDate } from "tomslock/utils/formatingInput";

const CardUser = ({ colleague, friends, type, activeMenu }) => {
  const isColleague = activeMenu === 0 && type === "colleague";
  const isFriend = activeMenu === 1 && type === "friend";

  const dataSource = isFriend ? friends : isColleague ? colleague : [];

  if (!isFriend && !isColleague) return null;

  return (
    <Grid
      templateColumns={{
        base: "repeat(1, 1fr)",
        sm: "repeat(1, 1fr)",
        md: "repeat(2, 1fr)",
        lg: "repeat(4, 1fr)",
      }}
      mb={6}
      gap={6}
    >
      {dataSource.map((data, index) => {
        const userData = isFriend ? data?.friend : data;
        return (
          <GridItem
            key={index}
            w="100%"
            h="auto"
            bgColor={"white"}
            boxShadow={"lg"}
            px={4}
            py={8}
            borderRadius={"2xl"}
            display="flex" // Add this line
            alignItems="center" // Add this line
            justifyContent="center" // Add this line
            mx={"auto"}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              mx="auto"
            >
              <Image
                boxSize={{ base: "180px", md: "200px" }}
                borderRadius="full"
                src={userData?.photo}
                alt={userData?.name}
                border={"2px solid #ddd"}
                mb={2}
                objectFit={"cover"}
                loading="lazy"
              />

              <Box mb={2}>
                <Text fontWeight="bold" fontSize="lg" mb={1}>
                  {userData?.name}
                </Text>
                <Text fontSize="sm">
                  {userData?.gender === 0 ? "Laki-laki" : "Perempuan"}
                </Text>
                <Text fontSize="sm">{formatDate(userData?.birth_date)}</Text>
              </Box>

              <ButtonMiddle
                onClick={() =>
                  window.location.assign(
                    `/dashboard/friend-list/${type}/${userData?.id}`
                  )
                }
                fontSize="xs"
                color="blue"
              >
                View
              </ButtonMiddle>
            </Box>
          </GridItem>
        );
      })}
    </Grid>
  );
};

export default CardUser;
