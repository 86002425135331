import React, { useEffect, useMemo, useState } from "react";
import {
  Flex,
  Box,
  GridItem,
  Grid,
  Center,
  VStack,
  Text,
  Button,
} from "@chakra-ui/react";

import KotakPutih from "tomslock/component/KotakPutih";
import DownloadButton from "./DownloadButton";
import BannerLoggedUser from "tomslock/component/BannerLoggedUser";
import ButtonAction from "tomslock/component/ButtonAction";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function ServiceTable(props) {
  const {
    tableData,
    onClickCategory,
    onClickBuy,
    onClickPrice,
    onClickDownload,
    loading,
    friend,
    user,
  } = props;
  const [activeMenu, setActiveMenu] = useState("profiling");
  const [serviceData, setServiceData] = useState([]);
  const data = useMemo(() => tableData, [tableData]);

  useEffect(() => {
    if (data) {
      setServiceData(data.profiling);
    }
  }, [data]);

  const onChangeMenu = (menu) => {
    if (menu === "profiling") {
      setActiveMenu("profiling");
      setServiceData(data.profiling);
    } else if (menu === "matching") {
      setActiveMenu("matching");
      setServiceData(data.matching);
    } else if (menu === "persona") {
      setActiveMenu("persona");
      setServiceData(data.persona);
    }
  };

  const handleDownload = ({ category, name, index }) => {
    onClickDownload({ category, name, index });
  };

  return (
    <>
      {user && (
        <>
          <BannerLoggedUser user={user} />

          <Box mb={4}>
            <ButtonAction
              as={Link}
              to={"/dashboard/friend-list"}
              w={48}
              color="teal"
            >
              Kembali ke FriendList
            </ButtonAction>
          </Box>
        </>
      )}

      <Box mb={4}>
        <Text
          align="center"
          justify="center"
          fontSize={"2xl"}
          fontWeight="bold"
        >
          Pricing Scheme
        </Text>
      </Box>

      <Box
        bgColor={{ base: "white", md: "gray.300" }}
        borderRadius={{ base: "2xl", md: "full" }}
        mb={6}
        p={4}
        w={{ base: "100%", sm: "100%", md: "100%", lg: "max" }}
        mx={"auto"}
        boxShadow={{ base: "lg", md: "none" }}
      >
        <Flex
          align="center"
          justify="center"
          gap={6}
          direction={{ base: "column", md: "row" }}
        >
          <Button
            cursor="pointer"
            onClick={() => onChangeMenu("profiling")}
            borderRadius={"full"}
            px={6}
            py={3}
            align="center"
            justify="center"
            borderWidth={activeMenu === "profiling" ? "0px" : "1px"}
            borderColor="orange.300"
            boxShadow={activeMenu === "profiling" ? "xl" : "none"}
            backgroundColor={activeMenu === "profiling" ? "orange.500" : "#fff"}
            color={activeMenu === "profiling" ? "white" : "orange"}
            _hover={{
              backgroundColor: "orange.500",
              color: "white",
            }}
          >
            <Text fontSize={"sm"} fontWeight="bold">
              Profiling Service
            </Text>
          </Button>

          {!friend && (
            <Button
              cursor="pointer"
              onClick={() => onChangeMenu("matching")}
              borderRadius={"full"}
              px={6}
              py={3}
              align="center"
              justify="center"
              borderWidth={activeMenu === "matching" ? "0px" : "1px"}
              borderColor="pink.500"
              boxShadow={activeMenu === "matching" ? "xl" : "none"}
              backgroundColor={activeMenu === "matching" ? "pink.500" : "#fff"}
              _hover={{
                backgroundColor: "pink.500",
                color: "white",
              }}
              color={activeMenu === "matching" ? "#FFF" : "pink.500"}
            >
              <Text fontSize={"sm"} fontWeight="bold">
                Matching Service
              </Text>
            </Button>
          )}
          <Button
            cursor="pointer"
            onClick={() => onChangeMenu("persona")}
            borderRadius={"full"}
            px={6}
            py={3}
            align="center"
            justify="center"
            borderWidth={activeMenu === "persona" ? "0px" : "1px"}
            borderColor="blue.500"
            boxShadow={activeMenu === "persona" ? "xl" : "none"}
            backgroundColor={activeMenu === "persona" ? "blue.500" : "#fff"}
            _hover={{
              backgroundColor: "blue.500",
              color: "white",
            }}
            color={activeMenu === "persona" ? "#FFF" : "blue.500"}
          >
            <Text fontSize={"sm"} fontWeight="bold">
              Persona Service
            </Text>
          </Button>
        </Flex>
      </Box>

      {/* End Button Tab Panel  */}

      <Grid templateColumns="repeat(6, 1fr)" gap={6}>
        {serviceData.map((item, index) => {
          const isPurchase = serviceData.find(
            (element) =>
              element.name === item.name && element.isPurchase === true
          );
          return (
            <GridItem
              w="100%"
              colSpan={{ base: 6, sm: 6, md: 3, lg: 2 }}
              key={index}
            >
              <KotakPutih py={10}>
                <Center>
                  <VStack>
                    <Center
                      bgColor={"orange.100"}
                      w={8}
                      h={8}
                      borderRadius={"full"}
                    >
                      <Text
                        fontSize={"sm"}
                        fontWeight={"bold"}
                        color={"orange.600"}
                      >
                        {index + 1}
                      </Text>
                    </Center>
                    <Text fontSize={{ sm: "xs", md: "sm" }} color={"gray.500"}>
                      {item.name}{" "}
                      {item.duration &&
                        `${item.duration} ${item.duration_type}`}
                    </Text>

                    {item.isPurchase ? (
                      <Text
                        flex={1}
                        fontSize={"xs"}
                        minWidth={{ sm: "65px", md: "100px" }}
                        color="green"
                        align={"center"}
                        bgColor={"green.50"}
                        px={6}
                        py={1.5}
                        borderRadius={"full"}
                      >
                        Layanan telah dibeli
                      </Text>
                    ) : (
                      <Text
                        cursor="pointer"
                        flex={1}
                        fontSize={"2xl"}
                        minWidth={{ sm: "65px", md: "100px" }}
                        fontWeight={"bold"}
                        color={"gray.600"}
                        onClick={() =>
                          onClickPrice({
                            category: item.category,
                            service: activeMenu,
                          })
                        }
                      >
                        Rp {new Intl.NumberFormat("id-ID").format(item.price)}
                      </Text>
                    )}

                    <Box>
                      {item.isPurchase || isPurchase ? (
                        <Flex flexDirection="row" width="100%" gap={1}>
                          <Flex flex={1}>
                            {item.type === "profiling" ? (
                              <>
                                {/* kene  */}
                                <DownloadButton
                                  key={index}
                                  item={item}
                                  activeMenu={activeMenu}
                                  index={index}
                                  handleDownload={handleDownload}
                                  loading={loading[index]}
                                />
                              </>
                            ) : (
                              <Button
                                width="48"
                                bgColor="green"
                                _hover={{
                                  backgroundColor: "green.400",
                                }}
                                color="white"
                                fontSize={{ sm: "xs", md: "sm" }}
                                fontWeight="500"
                                borderRadius="full"
                                w={24}
                                onClick={() =>
                                  onClickCategory({
                                    category: item.category,
                                    service: item.type,
                                    name: item.features,
                                  })
                                }
                                px="4"
                                py="2"
                                title="Open Web View"
                              >
                                Open
                              </Button>
                            )}
                          </Flex>
                        </Flex>
                      ) : (
                        <Box>
                          <Button
                            width="36"
                            bgColor="orange"
                            w={24}
                            _hover={{
                              backgroundColor: "orange.400",
                            }}
                            _active={{
                              border: "4px solid",
                              borderColor: "orange.200",
                            }}
                            color="white"
                            fontSize={"xs"}
                            fontWeight="500"
                            borderRadius="full"
                            boxShadow={"lg"}
                            onClick={() =>
                              onClickBuy({
                                category: item.category,
                                service: activeMenu,
                                name: item.name,
                              })
                            }
                            px="4"
                            py="2"
                          >
                            Beli
                          </Button>
                        </Box>
                      )}
                    </Box>

                    <Box align="center" justify="center">
                      <Text color={"gray.700"} fontSize={"xs"}>
                        Keterangan
                      </Text>
                      <Text color={"gray.500"} fontSize={"xs"}>
                        {item.desc}
                      </Text>
                    </Box>
                  </VStack>
                </Center>
              </KotakPutih>
            </GridItem>
          );
        })}
      </Grid>
    </>
  );
}
