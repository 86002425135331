import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Image,
  Button,
  VStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import logo from "assets/img/logo.png";
import axios from "axios";

const Header = ({ active }) => {
  const history = useHistory();
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";

  const [user, setUser] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onToggle = () => {
    isOpen ? onClose() : onOpen();
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    let currentUser = localStorage.getItem("user");

    if (token) {
      axios
        .get(`https://api.personatalenta.id/user/current`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setUser(res.data.user);
          localStorage.setItem("user", JSON.stringify(res.data.user));
        })
        .catch((err) => {
          if (err.response?.data?.message === "Unauthorized") {
            history.push("/");
          }
        });
    }

    if (currentUser) {
      setUser(JSON.parse(currentUser));
    }
  }, [history]);

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  const navLinks = ["home", "profiling", "matching", "pricing", "faq"];

  return (
    <Box
      bgGradient={{
        base: "linear(to-b, #000000, #111111, #222222)",
        lg: "linear(to-b, #000000, #111111, #222222)",
        // lg: "linear(to-r, #FDD809, #E24E06, #ED1B33)",
      }}
      px={{ base: 4, md: 8 }}
      py={4}
      position="fixed"
      top={0}
      width={{ base: "full", md: "95%", lg: "full" }}
      zIndex={5}
      boxShadow="lg"
    >
      <Flex justify="space-between" align="center">
        <Link to="/home">
          <Flex align="center">
            <Image
              src={logo}
              alt="logo"
              boxSize={{ base: "30px", md: "40px" }}
            />
            <Text
              ml={2}
              fontWeight="bold"
              fontSize={{ base: "lg", md: "2xl" }}
              color="white"
              fontFamily="Audiowide"
            >
              PERSONA
            </Text>
          </Flex>
        </Link>

        {isMobile ? (
          <>
            <IconButton
              icon={<HamburgerIcon />}
              variant="outline"
              color="white"
              onClick={onToggle}
              aria-label="Open menu"
              fontSize="2xl"
            />
            <Drawer
              placement="right"
              isOpen={isOpen}
              onClose={onClose}
              zIndex={2}
            >
              <DrawerOverlay />
              <DrawerContent
                bg="white"
                p={4}
                boxShadow="lg"
                borderRadius="md"
                zIndex={2}
              >
                <VStack spacing={4} mt={16} align="start" pl={4}>
                  {navLinks.map((link) => (
                    <Link key={link} to={`/${link}`} onClick={onClose}>
                      <Text fontSize="lg" fontWeight="bold" color="gray.700">
                        {link.toUpperCase()}
                      </Text>
                    </Link>
                  ))}
                  {user ? (
                    <>
                      <Button
                        onClick={() => history.push("/dashboard")}
                        fontWeight={"bold"}
                        color="gray.700"
                      >
                        DASHBOARD
                      </Button>
                      <Button
                        onClick={handleLogout}
                        fontWeight={"bold"}
                        color="gray.700"
                      >
                        LOGOUT
                      </Button>
                    </>
                  ) : (
                    <Link to={isLoginPage ? "/signup" : "/login"}>
                      <Button
                        colorScheme="yellow"
                        fontSize="lg"
                        fontWeight="bold"
                        color="gray.700"
                      >
                        {isLoginPage ? "SIGN UP" : "LOGIN"}
                      </Button>
                    </Link>
                  )}
                </VStack>
              </DrawerContent>
            </Drawer>
          </>
        ) : (
          <Flex align="center" gap={{ base: 6, lg: 10 }}>
            {navLinks.map((link) => (
              <Link key={link} to={`/${link}`}>
                <Text
                  fontWeight="bold"
                  fontSize="sm"
                  color={
                    location.pathname === `/${link}` ? "yellow.300" : "white"
                  }
                  _hover={{ color: "yellow.300" }}
                >
                  {link.toUpperCase()}
                </Text>
              </Link>
            ))}

            {user ? (
              <Menu>
                <MenuButton
                  as={Button}
                  colorScheme="orange"
                  bgColor="tomato"
                  _hover={{ backgroundColor: "orange.500" }}
                  p={2}
                  borderRadius="xl"
                  border="2px solid #ccc"
                >
                  <Text color={"white"} fontSize="sm" fontWeight="bold">
                    DASHBOARD
                  </Text>
                </MenuButton>
                <MenuList bgColor="tomato" p={2} borderRadius="2xl" w={36}>
                  <MenuItem
                    px={2}
                    py={1}
                    borderRadius="md"
                    onClick={() => history.push("/dashboard")}
                    _hover={{ backgroundColor: "white", color: "tomato" }}
                    pb={2}
                    fontSize="sm"
                    color={"white"}
                  >
                    Hai, <Text fontWeight="bold">{user.name}</Text>
                  </MenuItem>
                  <MenuItem borderBottom="1px solid #eee" my={1}></MenuItem>
                  <MenuItem
                    px={2}
                    py={1}
                    borderRadius="md"
                    onClick={() => history.push("/dashboard")}
                    _hover={{ backgroundColor: "white", color: "tomato" }}
                    pb={2}
                    fontSize="sm"
                    color={"white"}
                  >
                    Dashboard
                  </MenuItem>
                  <MenuItem
                    onClick={handleLogout}
                    _hover={{ backgroundColor: "white", color: "tomato" }}
                    pb={2}
                    px={2}
                    py={1}
                    borderRadius="md"
                    color={"white"}
                    fontSize="sm"
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            ) : (
              <Link to={isLoginPage ? "/signup" : "/login"}>
                <Button
                  fontWeight="bold"
                  color="white"
                  fontSize="sm"
                  as={Button}
                  colorScheme="orange"
                  bgColor="tomato"
                  _hover={{ backgroundColor: "orange.500" }}
                  p={2}
                  borderRadius="lg"
                  border="2px solid #ffdcbc"
                  mt={-1}
                  width={24}
                >
                  {isLoginPage ? "SIGN UP" : "LOGIN"}
                </Button>
              </Link>
            )}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default Header;
