// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";

export default function Banner(props) {
  const { banner, avatar, name, job, dob, edit = true, onClickEdit } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  return (
    <Card
      mb={{ base: "0px", lg: "20px" }}
      mt={{ sm: 10, base: "80px", lg: "0" }}
      align="start"
    >
      <Flex
        m="10px 10px"
        w="max-content"
        direction="row"
        alignItems="center"
        gap={5}
        bgColor={"gray.100"}
        py={2}
        pl={2}
        pr={6}
        borderRadius={"full"}
      >
        <Flex direction="column">
          <Avatar
            mx="auto"
            src={avatar}
            h={{ sm: "50px", lg: "100px" }}
            w={{ sm: "50px", lg: "100px" }}
            border="4px solid"
            borderColor={borderColor}
            boxShadow={"lg"}
          />
          {edit && (
            <Button
              variant="action"
              // bgColor="gray.100"
              borderBottomColor="#4B70F5"
              borderBottomWidth={1}
              color="black"
              fontSize={{ sm: "xs", md: "sm" }}
              fontWeight="500"
              // borderRadius='10px'
              onClick={onClickEdit}
              px="4px"
              py="4px"
            >
              Edit Profile
            </Button>
          )}
        </Flex>

        <Flex align="start" direction="column">
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize={{ sm: "xs", md: "sm", lg: "md" }}
          >
            {name}
          </Text>
          <Text color={textColorPrimary} fontSize={{ sm: "xs", lg: "sm" }}>
            {job}
          </Text>
          {edit && (
            <Text color={textColorPrimary} fontSize={{ sm: "xs", lg: "sm" }}>
              {dob}
            </Text>
          )}
        </Flex>
      </Flex>
      {/*       
       <Flex w='max-content' mx='auto' mt='26px'>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {posts}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Posts
          </Text>
        </Flex>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {followers}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Followers
          </Text>
        </Flex>
        <Flex mx='auto' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {following}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Following
          </Text>
        </Flex>
      </Flex>  */}
    </Card>
  );
}
