import React from "react";
import { Box, Button } from "@chakra-ui/react";
import PropTypes from "prop-types";
import Content from "components/sidebar/components/Content";
import SidebarMiniContent from "./SidebarMiniContent";

function SidebarAdmin({ routes, isOpen, setIsOpen, toggleSidebar }) {
  const variantChange = "0.2s ease-in-out";

  return (
    <>
      {/* Full Sidebar */}
      <Box
        display={{
          base: "none",
          sm: "none",
          md: isOpen ? "block" : "none",
          lg: isOpen ? "block" : "none",
        }}
        w={{ base: 0, md: "17%", lg:"15%" }}
        position="fixed"
        minH="100vh"
        transition={`width ${variantChange}, opacity ${variantChange}`}
        opacity={isOpen ? 1 : 0}
        pointerEvents={isOpen ? "auto" : "none"}
        bg="white"
        boxShadow="lg"
      >
        <Content routes={routes} />
      </Box>

      {/* Mini Sidebar */}
      <Box
        display={{
          base: "flex",
          sm: "flex",
          md: isOpen ? "flex" : !isOpen ? "flex" : "none",
          lg: !isOpen ? "flex" : "none",
        }}
        w={{ base: "15%", md: "7%", lg: "5%" }}
        position="fixed"
        minH="100vh"
        transition={`width ${variantChange}, opacity ${variantChange}`}
        opacity={isOpen ? 0 : 1}
        pointerEvents={isOpen ? "none" : "auto"}
        boxShadow="lg"
        bg="white"
        justifyContent="center"
        p={2}
      >
        <SidebarMiniContent routes={routes} />
      </Box>

      {/* Toggle Button */}
      <Box
        fontSize="xl"
        fontWeight="bold"
        position="fixed"
        zIndex={3}
        bottom={4}
        left={{ base: 3, sm: 4, md: isOpen ? "12.7%" : 1.99 }}
      >
        <Button
          onClick={toggleSidebar}
          bgColor="gray.700"
          p={1}
          borderRadius="full"
          boxShadow="lg"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#fff"
            width="36"
            height="36"
            style={{
              transform: isOpen ? "rotate(0deg)" : "rotate(180deg)",
              transition: "transform 0.3s ease-in-out",
            }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </Button>
      </Box>
    </>
  );
}

SidebarAdmin.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default SidebarAdmin;
