import { Grid } from "@chakra-ui/react";
import React from "react";

import ProfilePart1 from "./ProfilePart1";
import ProfilePart2 from "./ProfilePart2";

const ProfileMain = ({ user }) => {
  return (
    <Grid
      templateColumns={{
        base: "1fr", 
        sm: "1fr", 
        md: "1fr 2fr", 
        lg: "1fr 2fr",
      }}
      gap={6}
      mb={6}
    >
      <ProfilePart1 user={user} />
      <ProfilePart2 user={user} />
    </Grid>
  );
};

export default ProfileMain;
