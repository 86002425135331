import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Center, ChakraProvider, Spinner } from "@chakra-ui/react";

// Layouts
import AdminLayout from "layouts/admin";
import PublicLayout from "layouts/public/PublicLayout";

// Theme
import theme from "theme/theme";
import ScrollToTop from "tomslock/component/ScrollToTop";
import NotFound from "tomslock/view/handling/NotFound";
import Forbidden from "tomslock/view/handling/Forbidden";

// Lazy load Views
const Home = lazy(() => import("views/home"));
const Login = lazy(() => import("views/login"));
const AboutUs = lazy(() => import("views/aboutUs"));
const CommunityGuideline = lazy(() => import("views/communityGuideline"));
const Forgot = lazy(() => import("views/forgot"));
const Disclaimer = lazy(() => import("views/disclaimer"));
const TermsService = lazy(() => import("views/termsService"));
const PrivacyPolicy = lazy(() => import("views/privacyPolicy"));
const Signup = lazy(() => import("views/signup"));
const Matching = lazy(() => import("views/matching"));
const Profiling = lazy(() => import("views/profiling"));
const Pricing = lazy(() => import("views/pricing"));
const Service = lazy(() => import("views/service"));
const MatchingService = lazy(() => import("views/matchingService"));
const PersonaService = lazy(() => import("views/persona"));
const Faq = lazy(() => import("views/faq"));

const themeMui = createTheme();

const isAuthenticated = () => {
  return !!localStorage.getItem("user");
};


const PrivateRoute = ({ children, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? children : <Redirect to="/forbidden" />
    }
  />
);



const App = () => (
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ThemeProvider theme={themeMui}>
        <Router>
          <Switch>
            {/* Admin Layout Routes */}
            <Route
              path="/dashboard"
              render={(props) => (
                <PrivateRoute>
                  <AdminLayout {...props} />
                </PrivateRoute>
              )}
            />

            {/* Public Layout Routes */}
            <Route
              path="/"
              render={(props) => (
                <PublicLayout {...props}>
                  <main>
                    <Suspense
                      fallback={
                        <Center h="100vh">
                          <Spinner size="xl" />
                        </Center>
                      }
                    >
                      <Switch>
                        {/* Public Routes */}
                        <Route exact path="/" component={Home} />
                        <Route path="/home" component={Home} />
                        <Route path="/login" component={Login} />
                        <Route path="/signup" component={Signup} />
                        <Route path="/forgot-password" component={Forgot} />
                        <Route path="/about-us" component={AboutUs} />
                        <Route path="/profiling" component={Profiling} />
                        <Route path="/matching" component={Matching} />
                        <Route path="/faq" component={Faq} />
                        <Route
                          path="/matching-service"
                          component={MatchingService}
                        />
                        <Route
                          path="/persona-service"
                          component={PersonaService}
                        />
                        <Route path="/pricing" component={Pricing} />
                        <Route path="/service" component={Service} />
                        <Route path="/disclaimer" component={Disclaimer} />
                        <Route
                          path="/terms-of-service"
                          component={TermsService}
                        />
                        <Route
                          path="/privacy-policy"
                          component={PrivacyPolicy}
                        />
                        <Route
                          path="/community-guideline"
                          component={CommunityGuideline}
                        />

                        {/* Forbidden Route */}
                        <Route path="/forbidden" component={Forbidden} />

                        {/* Catch-all for undefined paths */}
                        <Route component={NotFound} />
                      </Switch>
                      <ScrollToTop />
                    </Suspense>
                  </main>
                </PublicLayout>
              )}
            />
          </Switch>
        </Router>
      </ThemeProvider>
    </React.StrictMode>
  </ChakraProvider>
);


ReactDOM.render(<App />, document.getElementById("root"));
