import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={52}
    height={53}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m30.112 36.5-10-10 10-10 1.775 1.775-8.225 8.225 8.225 8.225-1.775 1.775Z"
      fill="#1A7431"
    />
    <rect
      x={1}
      y={1.5}
      width={50}
      height={50}
      rx={25}
      stroke="#1A7431"
      strokeWidth={2}
    />
  </svg>
)

export default SvgComponent
