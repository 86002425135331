import React from "react";
import { Box, Flex, Input, Button, Text, CloseButton } from "@chakra-ui/react";

const LoggedSearchPickUserModal = ({
  searchData,
  onChangeSearch,
  onSelectUser,
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;



  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      backgroundColor="rgba(0, 0, 0, 0.5)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex="1000"
    >
      <Box
        backgroundColor="white"
        p="6"
        borderRadius="md"
        boxShadow="lg"
        width={{ base: "90%", md: "450px", lg: "500px" }}
        maxWidth="90vw"
      >
        <Flex justify="space-between" alignItems="center" mb={4}>
          <Text fontWeight="bold" fontSize="md">
            Cari User
          </Text>
          <CloseButton onClick={onClose} />
        </Flex>
        <Text fontSize="sm" mb={2} color="gray.600">
          Nama
        </Text>
        <Input
          px={3}
          py={2}
          border={"1px solid #ccc"}
          borderRadius={"md"}
          width="100%"
          fontSize={{ sm: "xs", md: "sm" }}
          placeholder="Search by name (min 3 huruf)"
          bgColor="gray.50"
          onChange={onChangeSearch}
          mb={4}
          aria-label="Search by name"
        />
        {searchData?.length > 0 ? (
          <Flex
            flexDirection="column"
            width="100%"
            gap={2}
            backgroundColor="#fff"
            borderWidth={1}
            px={5}
            py={2}
            borderRadius="md"
            mt={2}
          >
            {searchData.map((data, index) => (
              <Button
                key={index}
                justifyContent="start"
                color={"black"}
                fontSize={{ sm: "xs", md: "sm" }}
                onClick={() => {
                  onSelectUser(data, "user");
                  onClose(); // Close modal after selecting a user
                }}
                py="5px"
              >
                <Text>{data.name}</Text>
              </Button>
            ))}
          </Flex>
        ) : (
          <Text color="gray.500" mt={2}>
            No results found
          </Text>
        )}
        <Button
          color={"white"}
          fontWeight="bold"
          borderRadius="lg"
          borderColor="gray.200"
          bgColor="gray.500"
          boxShadow="lg"
          _hover={{ backgroundColor: "gray.600" }}
          onClick={onClose}
          mt={3}
          px={4}
          py={2}
          width="max"
          fontSize="xs"
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default LoggedSearchPickUserModal;
