/* eslint-disable */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Flex, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";

export function SidebarLinks({ routes, isOpen }) {
  const location = useLocation();

  const isRouteActive = (path) =>
    location.pathname.includes(path.toLowerCase());

  const activeColor = useColorModeValue("gray.700", "white");
  const textColor = useColorModeValue("secondaryGray.500", "white");

  const renderRoutes = (routes) =>
    routes.map((route) => {
      if (route.category && isOpen) {
        return (
          <Box key={route.name} my={16}>
            <Text
              fontSize="sm"
              color={activeColor}
              fontWeight="bold"
              pt="18px"
              pb="12px"
            >
              {route.name}
            </Text>
            {renderRoutes(route.items || [])}
          </Box>
        );
      }

      const linkPath =
        route.path === "/home" ? route.path : `${route.layout}${route.path}`;

      return (
        <NavLink to={linkPath} key={route.name}>
          <Tooltip
            label={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontWeight={"bold"}
              >
                {route.name}
              </Box>
            }
            placement="right"
            bgColor="rgba(255, 255, 255, 0.8)"
            p="10px"
            ml={-1}
            w="160px"
            fontSize="sm"
            borderRadius="full"
            border="1px solid tomato"
            color="red"
            mb="1.5px"
            mt="1px"
            boxShadow="lg"
            zIndex={10}
          >
            <Flex
              p="3"
              mb="3"
              w={"max"}
              mx={"auto"}
              zIndex={20}
              borderRadius="full"
              boxShadow={{ base: "", lg: "xl" }}
              alignItems="center"
              justifyContent="center"
              bg={isRouteActive(route.path) ? "tomato" : "transparent"}
              color={isRouteActive(route.path) ? "white" : textColor}
              transition="background-color 0.3s ease"
              _hover={{
                bg: "tomato",
                color: "white",
                boxShadow: "lg",
              }}
            >
              {route.icon && <Box me={isOpen ? 0 : 0}>{route.icon}</Box>}
            </Flex>
          </Tooltip>
        </NavLink>
      );
    });

  return <>{renderRoutes(routes)}</>;
}

export default SidebarLinks;
