import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Input,
  Text,
} from "@chakra-ui/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Assets
import { LinearProgress } from "@mui/material";
import { Search2Icon } from "@chakra-ui/icons";
import PersonIcon from "@mui/icons-material/Person";
import axiosInstance from "tomslock/utils/axiosInstance";

export default function Matching(props) {
  const [user, setUser] = useState();
  const [localUser, setLocalUser] = useState();
  const [user1, setUser1] = useState();
  const [user2, setUser2] = useState();

  const [visibleSearchUser, setVisibleSearchUser] = useState();
  const [searchData, setSearchData] = useState([]);
  const [activeCard, setActiveCard] = useState(0);
  const [matching, setMatching] = useState();
  const [open, setOpen] = useState("");
  const [isUnlocked, setIsUnlocked] = useState(false);

  const [mode, setMode] = useState(0);
  const [cardMatching, setCardMatching] = useState([]);
  const [stats, setStats] = useState();
  const [level, setLevel] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    let locUser = localStorage.getItem("user");
    setLocalUser(JSON.parse(locUser));
    if (props?.match?.params?.id && props?.match?.params?.type) {
      axiosInstance
        .get(`/user/${props.match.params.type}/${props.match.params.id}`, {
          headers: {
            Authorization: `Bearer ${token ? token : ""}`,
            "Content-Type": "x-www-from-urlencode",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        })
        .then((res) => {
          setUser(res.data.user ?? res.data.colleagues);
        })
        .catch((err) => {
          if (
            err.response?.data?.message === "Unauthorized" ||
            err.response?.data === "Unauthorized"
          ) {
            // navigate('/');
          }
        });
    } else {
      if (locUser) {
        setUser(JSON.parse(locUser));
      } else {
        window.location.assign("/home");
      }
    }
  }, [props]);

  useEffect(() => {
    window.onscroll = function () {
      myFunction();
    };

    var navbar = document.getElementById("sticky");
    var sticky = navbar.offsetTop - 150;

    function myFunction() {
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky");
        // type.classList.add("sticky")
      } else {
        navbar.classList.remove("sticky");
        // type.classList.remove("sticky");
      }
    }
  }, []);

  useEffect(() => {
    if (stats) {
      if (stats < 50) {
        setLevel("Average");
      } else if (stats < 60) {
        setLevel("Moderate");
      } else if (stats < 70) {
        setLevel("Intermediate");
      } else if (stats < 80) {
        setLevel("Leading");
      } else if (stats < 90) {
        setLevel("Advance");
      } else {
        setLevel("The BEST");
      }
    }
  }, [stats]);

  useEffect(() => {
    if (mode === 1) {
      if (user1 && user2) {
        if (user1.gender === user2.gender) {
          setError(
            "Matching Romance hanya berlaku untuk gender yang berbeda saja"
          );
        } else {
          setError("");
        }
      } else {
        setError("");
      }
    } else {
      setError("");
    }
  }, [mode, user1, user2]);


  const onRunMatching = () => {
    const token = localStorage.getItem("token");
    if (token) {
      axiosInstance
        .get(
          `/matching/run?id1=${user1?.id}&id2=${user2?.id}&type1=${
            user1.type ?? 0
          }&type2=${user2.type ?? 0}&matching_type=${mode}`,
          {
            headers: {
              Authorization: `Bearer ${token ? token : ""}`,
              "Content-Type": "x-www-from-urlencode",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": "true",
              "Access-Control-Allow-Headers":
                "Origin, X-Requested-With, Content-Type, Accept",
            },
          }
        )
        .then((res) => {
          setMatching(res?.data);
          setCardMatching(res?.data?.cards_general);
          // setMatchingCard(0);
          setStats(res?.data?.stats?.general);
          setActiveCard(1);
          setIsUnlocked(res?.data?.is_unlocked);
          // setCards(res?.data?.cards)
        })
        .catch((err) => {
          if (
            err.response?.data?.message === "Unauthorized" ||
            err.response?.data === "Unauthorized"
          ) {
            // navigate('/');
          }
        });
    }
  };

  const onClickCard = (props) => {
    setActiveCard(props);
    // setMatchingCard(props);
    if (matching) {
      if (props === 1) {
        setCardMatching(matching.cards_general);
        setStats(matching.stats.general);
      } else if (props === 2) {
        setCardMatching(matching.cards_daily);
        setStats(matching.stats.today);
      }
    }
  };

  const onChangeSearch = (text) => {
    const token = localStorage.getItem("token");

    if (token) {
      if (text.target.value.length > 2) {
        if (visibleSearchUser === "colleague") {
          axiosInstance
            .get(`/user/colleague?search=${text.target.value}`, {
              headers: {
                Authorization: `Bearer ${token ? token : ""}`,
                "Content-Type": "x-www-from-urlencode",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Headers":
                  "Origin, X-Requested-With, Content-Type, Accept",
              },
            })
            .then((res) => {
              setSearchData(res?.data?.colleagues);
              // setCards(res?.data?.cards)
            })
            .catch((err) => {
              if (
                err.response?.data?.message === "Unauthorized" ||
                err.response?.data === "Unauthorized"
              ) {
                // navigate('/');
              }
            });
        } else {
          axiosInstance
            .get(`/user/friendship/find-friend?search=${text.target.value}`, {
              headers: {
                Authorization: `Bearer ${token ? token : ""}`,
                "Content-Type": "x-www-from-urlencode",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Headers":
                  "Origin, X-Requested-With, Content-Type, Accept",
              },
            })
            .then((res) => {
              setSearchData(res?.data?.users);
              // setCards(res?.data?.cards)
            })
            .catch((err) => {
              if (
                err.response?.data?.message === "Unauthorized" ||
                err.response?.data === "Unauthorized"
              ) {
                // navigate('/');
              }
            });
        }
      } else {
        setSearchData([]);
      }
    }
  };

  const onSelectMe = () => {
    if (!user1) {
      setUser1({
        ...user,
        type: props?.match?.params?.type === "colleague" ? "1" : "0",
      });
    } else if (user1 !== user) {
      setUser2({
        ...user,
        type: props?.match?.params?.type === "colleague" ? "1" : "0",
      });
    }
  };

  const onSelectUser = (data, type) => {
    if (!user1) {
      setUser1({ ...data, type: type === "colleague" ? "1" : "0" });
      setVisibleSearchUser(false);
      setSearchData([]);
    } else {
      setUser2({ ...data, type: type === "colleague" ? "1" : "0" });
      setVisibleSearchUser(false);
      setSearchData([]);
    }
  };

  const onRemoveUser = (user) => {
    if (user === 1) {
      setUser1();
    } else if (user === 2) {
      setUser2();
    }
  };

  const onClickOpen = (text) => {
    if (isUnlocked) {
      if (text === open) {
        setOpen("");
      } else {
        setOpen(text);
      }
    } else {
      if (props?.match?.params?.id) {
        window.location.assign("/pricing#matching");
        // window.location.assign(`/dashboard/friend-list/${props.match.params.type}/${props?.match?.params?.id}/checkout/matching`)
      } else {
        window.location.assign("/pricing#matching");
        // window.location.assign(`/dashboard/profile/${user?.username}/checkout/matching`)
      }
    }
  };

  //   const onSetMatchingCard = props => {
  //     setMatchingCard(props);
  //     if (props === 0) {
  //         setCardMatching(matching.cards_general)
  //         setStats(matching.stats.general)
  //     } else if (props === 1) {
  //         setCardMatching(matching.cards_daily)
  //         setStats(matching.stats.today)
  //     }
  //   }

  return (
    <Box
      pt={{ sm: 0, base: "130px", md: "80px", xl: "80px" }}
      mt={{ sm: 20, md: 5 }}
    >
      {/* Main Fields */}
      <Grid gap={{ base: "20px", xl: "20px" }}>
        {/* <Banner
          avatar={user?.photo}
          name={user?.name}
          job={user?.occupation}
          dob={user?.birth_date}
        /> */}
        <Flex
          direction="row"
          id="sticky"
          gap={{ sm: 3, md: 5 }}
          flexWrap="wrap"
        >
          {matching && activeCard !== 0 ? (
            <>
              <Button
                bgColor={activeCard === 0 ? "blue.500" : "white"}
                color={activeCard === 0 ? "white" : "blue.500"}
                borderWidth={1}
                borderColor="blue.500"
                fontSize={{ sm: "xs", md: "sm" }}
                fontWeight="bold"
                borderRadius="5px"
                onClick={() => onClickCard(0)}
                flex={{ sm: 1, md: "unset" }}
                px={{ sm: "10px", md: "30px" }}
                py="5px"
              >
                <ArrowBackIcon fontSize="0.75rem" />
                Matching
              </Button>
              <Button
                bgColor={
                  cardMatching.length === 0
                    ? "gray"
                    : activeCard === 1
                    ? "blue.500"
                    : "white"
                }
                color={
                  activeCard === 1 || cardMatching.length === 0
                    ? "white"
                    : "blue.500"
                }
                borderWidth={1}
                borderColor="blue.500"
                fontSize={{ sm: "xs", md: "sm" }}
                fontWeight="bold"
                borderRadius="5px"
                onClick={() => onClickCard(1)}
                flex={{ sm: 1, md: "unset" }}
                disabled={cardMatching.length === 0}
                px={{ sm: "10px", md: "30px" }}
                py="5px"
              >
                General match
              </Button>
              <Button
                bgColor={
                  cardMatching.length === 0
                    ? "gray"
                    : activeCard === 2
                    ? "blue.500"
                    : "white"
                }
                color={
                  activeCard === 2 || cardMatching.length === 0
                    ? "white"
                    : "blue.500"
                }
                borderWidth={1}
                borderColor="blue.500"
                fontSize={{ sm: "xs", md: "sm" }}
                fontWeight="bold"
                borderRadius="5px"
                onClick={() => onClickCard(2)}
                disabled={cardMatching.length === 0}
                flex={{ sm: 1, md: "unset" }}
                px={{ sm: "10px", md: "30px" }}
                py="5px"
              >
                Daily match
              </Button>
            </>
          ) : (
            <>
              <Button
                bgColor={mode === 0 ? "orange" : "white"}
                color={mode === 0 ? "white" : "orange"}
                borderWidth={1}
                borderColor={"orange"}
                fontSize={{ sm: "xs", md: "sm" }}
                fontWeight="bold"
                borderRadius="5px"
                onClick={() => setMode(0)}
                flex={{ sm: 1, md: "unset" }}
                px={{ sm: "10px", md: "30px" }}
                py="5px"
              >
                Partnership
              </Button>
              <Button
                bgColor={mode === 1 ? "#FF0080" : "white"}
                borderColor={"#FF0080"}
                borderWidth={1}
                color={mode === 1 ? "white" : "#FF0080"}
                fontSize={{ sm: "xs", md: "sm" }}
                fontWeight="bold"
                borderRadius="5px"
                onClick={() => setMode(1)}
                flex={{ sm: 1, md: "unset" }}
                px={{ sm: "10px", md: "30px" }}
                py="5px"
              >
                Romance
              </Button>
              <Button
                bgColor={mode === 2 ? "blue.500" : "white"}
                borderColor={"blue.500"}
                borderWidth={1}
                color={mode === 2 ? "white" : "blue.500"}
                fontSize={{ sm: "xs", md: "sm" }}
                fontWeight="bold"
                borderRadius="5px"
                onClick={() => setMode(2)}
                flex={{ sm: 1, md: "unset" }}
                // disabled={cardMatching.length === 0}
                px={{ sm: "10px", md: "30px" }}
                py="5px"
              >
                Friendship
              </Button>
            </>
          )}
        </Flex>
        {matching && activeCard !== 0 ? (
          <Flex
            flexDirection="column"
            width={{ md: "100%", lg: "75%" }}
            borderWidth={1}
            borderRadius={15}
            px={0}
            py={5}
            gap={5}
          >
            <Flex
              flexDirection={{ md: "row" }}
              px={{ sm: 0, md: 10 }}
              py={5}
              gap={10}
              alignItems="center"
            >
              <Flex flexDirection="column" gap={5}>
                <Flex alignItems="center" gap={1} flexDirection="column">
                  <Avatar
                    src={user1.photo}
                    h={{ sm: "50px", md: "110px" }}
                    w={{ sm: "50px", md: "110px" }}
                    border={{ sm: "1px solid", md: "4px solid" }}
                  />
                  <Flex flexDirection="column" textAlign="center">
                    <Text
                      textOverflow="ellipsis"
                      fontSize={{ sm: "xs", md: "sm" }}
                      overflow="hidden"
                      noOfLines={1}
                    >
                      {user1.name}
                    </Text>
                    <Text fontSize={{ sm: "xs", md: "sm" }}>
                      {user1.gender === 0 ? "Male" : "Female"}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex direction="column" width="100%">
                <Text textAlign="center" fontSize="lg" fontWeight="bold">
                  {stats}%
                </Text>
                <LinearProgress
                  sx={{ height: 20, borderRadius: 3, width: "100%" }}
                  color={mode === 0 ? "warning" : mode === 1 ? "error" : "info"}
                  variant="determinate"
                  value={stats}
                />
                <Text textAlign="center" fontSize="sm" fontWeight="bold">
                  {level}
                </Text>
              </Flex>
              <Flex flexDirection="column" gap={5}>
                <Flex alignItems="center" gap={1} flexDirection="column">
                  <Avatar
                    src={user2.photo}
                    h={{ sm: "50px", md: "110px" }}
                    w={{ sm: "50px", md: "110px" }}
                    border={{ sm: "1px solid", md: "4px solid" }}
                  />
                  <Flex flexDirection="column" textAlign="center">
                    <Text
                      textOverflow="ellipsis"
                      fontSize={{ sm: "xs", md: "sm" }}
                      overflow="hidden"
                      noOfLines={1}
                    >
                      {user2.name}
                    </Text>
                    <Text fontSize={{ sm: "xs", md: "sm" }}>
                      {user2.gender === 0 ? "Male" : "Female"}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            {cardMatching.map((card, index) => (
              <Flex direction="column" key={index} gap={1}>
                <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>
                  {card.title}
                </Text>
                {card.texts.map((text) => (
                  <>
                    {text.map((item, i) => {
                      // if (item.title && item.text) {
                      return (
                        <Flex
                          key={i}
                          direction="column"
                          gap={1}
                          borderWidth={1}
                          borderRadius={15}
                          padding={2}
                          borderColor="#3572EF"
                        >
                          <Text
                            fontWeight="bold"
                            fontSize={{ sm: "xs", md: "md" }}
                          >
                            {item.title !== "null" && item.title}
                          </Text>
                          <Text
                            textOverflow="ellipsis"
                            fontSize={{ sm: "xs", md: "md" }}
                            overflow="hidden"
                            noOfLines={item.text === open ? 0 : 3}
                          >
                            {item.text}
                          </Text>
                          <Text
                            color="#050C9C"
                            fontSize={{ sm: "xs", md: "md" }}
                            fontStyle="italic"
                            textAlign="end"
                            mt={0}
                            cursor="pointer"
                            onClick={() => onClickOpen(item.text)}
                          >
                            {isUnlocked && item.text !== open
                              ? "Read more"
                              : isUnlocked && item.text === open
                              ? "Show less"
                              : "Deep dive"}
                          </Text>
                        </Flex>
                      );
                      // }
                    })}
                  </>
                ))}
              </Flex>
            ))}
          </Flex>
        ) : (
          <Flex
            borderWidth={1}
            borderRadius={15}
            py={5}
            flexDirection="column"
            gap={5}
            width={{ sm: "100%", md: "78%" }}
          >
            {error !== "" && (
              <Flex bgColor="red" borderRadius={5} px={3} py={2}>
                <Text color="white" fontSize={{ sm: "xs", md: "sm" }}>
                  {error}
                </Text>
              </Flex>
            )}
            <Flex
              flexDirection="row"
              px={{ sm: 0, md: 10 }}
              py={0}
              width={{ sm: "100%", md: "55%" }}
            >
              <Flex width={"100%"} flexDirection="column" gap={5} px={5} py={5}>
                {user1 ? (
                  <Flex alignItems="center" gap={1} flexDirection="column">
                    <Avatar
                      src={user1.photo}
                      h="80px"
                      w="80px"
                      border="4px solid"
                      // borderColor={borderColor}
                    >
                      <AvatarBadge
                        cursor="pointer"
                        onClick={() => onRemoveUser(1)}
                        borderColor="white"
                        bg="red"
                        boxSize="1.5em"
                        top={0}
                        borderRadius={100}
                        color="white"
                      >
                        X
                      </AvatarBadge>
                    </Avatar>
                    <Flex flexDirection="column" textAlign="center">
                      <Text fontSize={{ sm: "xs", md: "sm" }}>
                        {user1.name}
                      </Text>
                      <Text fontSize={{ sm: "xs", md: "sm" }}>
                        {user1.gender === 0 ? "Male" : "Female"}
                      </Text>
                    </Flex>
                  </Flex>
                ) : (
                  <Flex alignItems="center" gap={5} flexDirection="column">
                    <Flex
                      flexDirection="column"
                      width="80px"
                      height="80px"
                      border="4px solid"
                      borderRadius={99}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <PersonIcon />
                      <Text fontSize={{ sm: "xs", md: "sm" }}>User 1</Text>
                    </Flex>
                  </Flex>
                )}
              </Flex>
              <Flex flexDirection="column" width={"100%"} gap={5} px={5} py={5}>
                {user2 ? (
                  <Flex alignItems="center" gap={1} flexDirection="column">
                    <Avatar
                      src={user2.photo}
                      h="80px"
                      w="80px"
                      border="4px solid"
                    >
                      <AvatarBadge
                        cursor="pointer"
                        onClick={() => onRemoveUser(2)}
                        borderColor="white"
                        bg="red"
                        boxSize="1.5em"
                        top={0}
                        borderRadius={100}
                        color="white"
                      >
                        X
                      </AvatarBadge>
                    </Avatar>
                    <Flex flexDirection="column" textAlign="center">
                      <Text fontSize={{ sm: "xs", md: "sm" }}>
                        {user2.name}
                      </Text>
                      <Text fontSize={{ sm: "xs", md: "sm" }}>
                        {user2.gender === 0 ? "Male" : "Female"}
                      </Text>
                    </Flex>
                  </Flex>
                ) : (
                  <Flex alignItems="center" gap={0} flexDirection="column">
                    <Flex
                      flexDirection="column"
                      width="80px"
                      height="80px"
                      border="4px solid"
                      borderRadius={99}
                      alignItems="center"
                      justifyContent="center"
                      gap={0}
                    >
                      <PersonIcon />
                      <Text fontSize={{ sm: "xs", md: "sm" }}>User 2</Text>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Flex>
            <Flex flexDirection="column" gap={5}>
              <Flex gap={3} flexWrap="wrap" width={{ sm: "100%", md: "55%" }}>
                {visibleSearchUser ? (
                  <Flex flexDirection="row" gap={2} width="100%">
                    <Flex flex={11} flexDirection="column">
                      <Input
                        px={3}
                        py={1}
                        borderRadius={5}
                        width="100%"
                        fontSize={{ sm: "xs", md: "sm" }}
                        placeholder="Search by name (min 3 huruf)"
                        onChange={onChangeSearch}
                      />
                      {searchData.length > 0 && (
                        <Flex
                          flexDirection="column"
                          flex={1}
                          gap={2}
                          position="relative"
                        >
                          <Flex
                            flexDirection="column"
                            width="100%"
                            flex={1}
                            gap={2}
                            position="absolute"
                            backgroundColor="#fff"
                            borderWidth={1}
                            px={5}
                            py={2}
                            zIndex={1}
                          >
                            {searchData.map((data, index) => (
                              <Button
                                key={index}
                                justifyContent="start"
                                color={"black"}
                                fontSize={{ sm: "xs", md: "sm" }}
                                onClick={() =>
                                  onSelectUser(data, visibleSearchUser)
                                }
                                py="5px"
                              >
                                {data.name}
                              </Button>
                            ))}
                          </Flex>
                        </Flex>
                      )}
                    </Flex>
                    <Flex flex={1}>
                      <Button
                        bgColor={"white"}
                        color={"red"}
                        fontSize={{ sm: "xs", md: "sm" }}
                        fontWeight="bold"
                        borderRadius="5px"
                        borderWidth={1}
                        borderColor="red"
                        onClick={() => setVisibleSearchUser(false)}
                        px={{ sm: "10px", md: "30px" }}
                        py="5px"
                      >
                        Cancel
                      </Button>
                    </Flex>
                  </Flex>
                ) : (
                  <>
                    <Button
                      bgColor={"blue.400"}
                      color={"white"}
                      fontSize={{ sm: "xs", md: "sm" }}
                      fontWeight="bold"
                      borderRadius="5px"
                      onClick={() => onSelectMe()}
                      px="10px"
                      py="5px"
                      flex={1}
                      disabled={user2 === user}
                    >
                      <Text
                        overflow="hidden"
                        fontSize={{ sm: "xs", md: "sm" }}
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        maxWidth="100%"
                      >
                        {user?.name}
                      </Text>
                    </Button>
                    <Button
                      bgColor={"blue.400"}
                      color={"white"}
                      fontSize={{ sm: "xs", md: "sm" }}
                      fontWeight="bold"
                      borderRadius="5px"
                      onClick={() => setVisibleSearchUser("user")}
                      px="15px"
                      flex={1}
                      py="5px"
                    >
                      <Flex gap={2} alignItems="center" justifyContent="center">
                        <Icon as={Search2Icon} />
                        <Text fontSize={{ sm: "xs", md: "sm" }}>User</Text>
                      </Flex>
                    </Button>
                    <Button
                      bgColor={"green.400"}
                      color={"white"}
                      fontSize={{ sm: "xs", md: "sm" }}
                      fontWeight="bold"
                      borderRadius="5px"
                      onClick={() => setVisibleSearchUser("colleague")}
                      px="15px"
                      py="5px"
                      flex={1}
                    >
                      <Flex gap={2} alignItems="center" justifyContent="center">
                        <Icon as={Search2Icon} />
                        <Text fontSize={{ sm: "xs", md: "sm" }}>Colleague</Text>
                      </Flex>
                    </Button>
                  </>
                )}
              </Flex>
              <Flex
                justifyContent={{ sm: "center" }}
                width={{ sm: "100%", md: "55%" }}
              >
                <Button
                  bgColor={"red"}
                  color={"white"}
                  fontSize={{ sm: "xs", md: "sm" }}
                  fontWeight="bold"
                  borderRadius="5px"
                  onClick={() => onRunMatching()}
                  px={{ sm: "10px", md: "30px" }}
                  py="5px"
                  disabled={mode === 1 && user1?.gender === user2?.gender}
                >
                  Run the matching
                </Button>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Grid>
    </Box>
  );
}
