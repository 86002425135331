import React from "react";
import { VStack, Box } from "@chakra-ui/react";

import PublicButtonWithModal from "./PublicButtonWithModal";
const PublicButtonPickUser = ({
  onChangeUser1,
  onChangeUser2,
  user1,
  user2,
  // visibleSearchUser,
}) => {
  return (
    <Box w="full" maxW={{ base: "100%", md: "90%" }} mx="auto">
      <Box
        borderBottom="2px solid"
        borderColor="gray.300"
        pb={4}
        mx="auto"
        mb={6}
      />

      <Box justifyContent="center" align="center" gap="64" mx="auto">
        <VStack>
          {!user1 && (
            <PublicButtonWithModal
              visibleSearchUser={"user1"}
              onChangeUser1={onChangeUser1}
              onChangeUser2={onChangeUser2}
              user={user1}
            />
          )}

          {!user2 && (
            <PublicButtonWithModal
              visibleSearchUser={"user2"}
              onChangeUser1={onChangeUser1}
              onChangeUser2={onChangeUser2}
              user={user2}
            />
          )}
        </VStack>
      </Box>
    </Box>
  );
};
export default PublicButtonPickUser;
