import React, { useEffect, useState } from "react";

// Chakra imports
import { Box, Flex, Grid, Text, Avatar, Button } from "@chakra-ui/react";

import { columnsDataPurchaseHistory } from "./variables/columnsData";
import TablePurchased from "./components/TablePurchased";
import { LinearProgress } from "@mui/material";
import moment from "moment";
import TitleLoggedPage from "tomslock/component/TitleLoggedPage";
import KotakPutih from "tomslock/component/KotakPutih";
import ButtonAction from "tomslock/component/ButtonAction";
import axiosInstance from "tomslock/utils/axiosInstance";

export default function PurchaseHistory() {
  const [user, setUser] = useState();
  const [purchased, setPurchased] = useState([]);
  const [activeType, setActiveType] = useState("profiling");
  const [loadingItems, setLoadingItems] = useState({});
  const [pagination, setPagination] = useState({
    limit: 5,
    page: 1,
    max: 1,
    total: 1,
  });
  const [matchingID, setMatchingID] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let localUser = localStorage.getItem("user");
    if (localUser) {
      setUser(JSON.parse(localUser));
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (activeType === "matching") {
      axiosInstance
        .get(`/friends/matching/list`, {
          headers: {
            Authorization: `Bearer ${token ? token : ""}`,
            "Content-Type": "x-www-from-urlencode",
          },
        })
        .then((res) => {
          if (res?.data?.data) {
            setPurchased(res.data.data);
          }
        })
        .catch((err) => {
          if (
            err.response.data.message === "Unauthorized" ||
            err.response.data === "Unauthorized"
          ) {
            // navigate('/');
          }
        });
    } else {
      axiosInstance
        .get(
          `/user/transaction?type=${activeType}&limit=${pagination.limit}&page=${pagination.page}`,
          {
            headers: {
              Authorization: `Bearer ${token ? token : ""}`,
              "Content-Type": "x-www-from-urlencode",
            },
          }
        )
        .then((res) => {
          // if (res?.data?.transactions?.length > 0) {
          setPurchased(res?.data?.transactions);
          setPagination({
            ...pagination,
            max: res.data.totalPages,
            total: res.data.totalCount,
          });
          // }
        })
        .catch((err) => {
          if (
            err.response.data.message === "Unauthorized" ||
            err.response.data === "Unauthorized"
          ) {
            // navigate('/');
          }
        });
    }
  }, [pagination.page, pagination.limit, activeType]);

  useEffect(() => {
    if (matchingID) {
      const token = localStorage.getItem("token");
      axiosInstance
        .get(`/matching/detail/${matchingID}`, {
          headers: {
            Authorization: `Bearer ${token ? token : ""}`,
            "Content-Type": "x-www-from-urlencode",
          },
        })
        .then((res) => {
          console.log(res);
          if (res?.data?.data) {
            // setPurchased(res.data.data)
          }
        })
        .catch((err) => {
          if (
            err.response.data.message === "Unauthorized" ||
            err.response.data === "Unauthorized"
          ) {
            // navigate('/');
          }
        });
    }
  }, [matchingID]);

  useEffect(() => {
    let localUser = localStorage.getItem("user");
    if (localUser) {
      setUser(JSON.parse(localUser));
      // console.log(user)
    } else {
      window.location.assign("/home");
    }
  }, []);

  const nextPage = () => {
    if (pagination.page < pagination.max) {
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  };

  const prevPage = () => {
    if (pagination.page > 1) {
      setPagination({ ...pagination, page: pagination.page - 1 });
    }
  };

  const firstPage = () => {
    setPagination({ ...pagination, page: 1 });
  };

  const lastPage = (type) => {
    setPagination({ ...pagination, page: pagination.max });
  };

  const goToPage = (type, page) => {
    setPagination({ ...pagination, page: page });
  };

  const limitPage = (type, limit) => {
    setPagination({ ...pagination, limit: limit });
  };

  const onClickAction = (props) => {
    if (props.type === "Download") {
      setLoadingItems((prev) => ({ ...prev, [props.index]: true })); // Set individual item loading
      const token = localStorage.getItem("token");
      let baseUrl;
      if (props.product.product.features === "Standard Profiling Book") {
        baseUrl = `/profiling/pdf/link?id=${props.product.user_id}&type=${
          props.product.is_has_user_id ? 0 : 1
        }&version=standard`;
      } else if (props.product.product.features === "Complete Profiling Book") {
        baseUrl = `/profiling/pdf/link?id=${props.product.user_id}&type=${
          props.product.is_has_user_id ? 0 : 1
        }&version=complete`;
      } else if (props.product.product.features === "Career Profiling Book") {
        baseUrl = `/profiling/pdf/career`;
      }

      axiosInstance
        .get(baseUrl, {
          headers: {
            Authorization: `Bearer ${token ? token : ""}`,
            "Content-Type": "x-www-from-urlencode",
          },
          responseType: "blob",
        })
        .then((res) => {
          // setLoading(false)
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(res.data);
          a.download = `${
            props.product.product.features === "Career Profiling Book"
              ? "Karir Terbaik Untuk"
              : props.product.product.features
          } ${user.name}.pdf`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
        .catch((err) => {
          if (
            err.response?.data?.message === "Unauthorized" ||
            err.response?.data === "Unauthorized"
          ) {
            // navigate('/');
          }
        })
        .finally(() => {
          setLoadingItems((prev) => ({ ...prev, [props.index]: false })); // Reset loading after completion
        });
    } else if (props.type === "Web") {
      if (user.id === props.product.user_id) {
        if (props.product.product.features === "Standard Profiling Book") {
          window.location.assign(
            `/dashboard/profile/${user?.username}/profiling-standard-web`
          );
        } else if (
          props.product.product.features === "Complete Profiling Book"
        ) {
          window.location.assign(
            `/dashboard/profile/${user?.username}/profiling-complete-web`
          );
        } else if (props.product.product.name === "Profiling Web") {
          window.location.assign(
            `/dashboard/profile/${user?.username}/profiling-web`
          );
        } else if (
          props.product.product.name === "Daily Vibe Persona" ||
          props.product.product.name === "General Persona"
        ) {
          window.location.assign(
            `/dashboard/profile/${user?.username}/persona-web`
          );
        } else if (props.product.product.name === "Matching") {
          window.location.assign(`/matching`);
        }
      } else {
        if (props.product.product.features === "Standard Profiling Book") {
          window.location.assign(
            `/dashboard/friend-list/${
              props.product.is_has_user_id ? "friend" : "colleague"
            }/${
              props.product.is_has_user_id
                ? props.product.user.id
                : props.product.colleague.id
            }/profiling-standard-web`
          );
        } else if (
          props.product.product.features === "Complete Profiling Book"
        ) {
          window.location.assign(
            `/dashboard/friend-list/${
              props.product.is_has_user_id ? "friend" : "colleague"
            }/${
              props.product.is_has_user_id
                ? props.product.user.id
                : props.product.colleague.id
            }/profiling-complete-web`
          );
        } else if (props.product.product.name === "Profiling Web") {
          window.location.assign(
            `/dashboard/friend-list/${
              props.product.is_has_user_id ? "friend" : "colleague"
            }/${
              props.product.is_has_user_id
                ? props.product.user.id
                : props.product.colleague.id
            }/profiling-web`
          );
        } else if (
          props.product.product.name === "Daily Vibe Persona" ||
          props.product.product.name === "General Persona"
        ) {
          window.location.assign(
            `/dashboard/friend-list/${
              props.product.is_has_user_id ? "friend" : "colleague"
            }/${
              props.product.is_has_user_id
                ? props.product.user.id
                : props.product.colleague.id
            }/persona-web`
          );
        } else if (props.product.product.name === "Matching") {
          window.location.assign(
            `/dashboard/friend-list/${
              props.product.is_has_user_id ? "friend" : "colleague"
            }/${
              props.product.is_has_user_id
                ? props.product.user.id
                : props.product.colleague.id
            }/matching-web`
          );
        }
      }
    } else if (props.type === "Buy") {
      if (user.id === props.product.user_id && props.product.is_has_user_id) {
        if (
          props.product.product.features === "Standard Profiling Book" ||
          props.product.product.features === "Complete Profiling Book"
        ) {
          window.location.assign(
            `/dashboard/profile/${user?.username}/product/profiling-book`
          );
        } else if (props.product.product.name === "Daily Vibe Persona") {
          window.location.assign(
            `/dashboard/profile/${user?.username}/product/daily-vibe`
          );
        } else if (props.product.product.name === "General Persona") {
          window.location.assign(
            `/dashboard/profile/${user?.username}/product/persona`
          );
        } else if (props.product.product.name === "Profiling Web") {
          window.location.assign(
            `/dashboard/profile/${user?.username}/product/profiling-web`
          );
        } else if (props.product.product.name === "Matching") {
          window.location.assign(
            `/dashboard/profile/${user?.username}/product/matching`
          );
        }
      } else {
        if (
          props.product.product.features === "Standard Profiling Book" ||
          props.product.product.features === "Complete Profiling Book"
        ) {
          window.location.assign(
            `/dashboard/friend-list/${
              props.product.is_has_user_id ? "friend" : "colleague"
            }/${
              props.product.is_has_user_id
                ? props.product.user.id
                : props.product.colleague.id
            }/product/profiling-book`
          );
        } else if (props.product.product.name === "Daily Vibe Persona") {
          window.location.assign(
            `/dashboard/friend-list/${
              props.product.is_has_user_id ? "friend" : "colleague"
            }/${
              props.product.is_has_user_id
                ? props.product.user.id
                : props.product.colleague.id
            }/product/daily-vibe`
          );
        } else if (props.product.product.name === "General Persona") {
          window.location.assign(
            `/dashboard/friend-list/${
              props.product.is_has_user_id ? "friend" : "colleague"
            }/${
              props.product.is_has_user_id
                ? props.product.user.id
                : props.product.colleague.id
            }/product/persona`
          );
        } else if (props.product.product.name === "Profiling Web") {
          window.location.assign(
            `/dashboard/friend-list/${
              props.product.is_has_user_id ? "friend" : "colleague"
            }/${
              props.product.is_has_user_id
                ? props.product.user.id
                : props.product.colleague.id
            }/product/profiling-web`
          );
        } else if (props.product.product.name === "Matching") {
          window.location.assign(
            `/dashboard/friend-list/${
              props.product.is_has_user_id ? "friend" : "colleague"
            }/${
              props.product.is_has_user_id
                ? props.product.user.id
                : props.product.colleague.id
            }/product/matching`
          );
        }
      }
    }
  };

  const onChangeMenu = (menu) => {
    setPurchased([]);
    setActiveType(menu);
    // if (menu === 'profiling') {
    //   setActiveType(0);
    // } else if (menu === 1) {
    //   setActiveType(1);
    // } else if (menu === 2) {
    //   setActiveType(2);
    // }
  };

  const setDetailMatching = (item) => {
    setMatchingID(item.id);
    if (item.expired_at && moment(item.expired_at).isAfter(new Date())) {
      setOpen(true);
    }
  };

  return (
    <>
      <Box>
        <TitleLoggedPage title={activeType.toUpperCase() + " SERVICE"} />

        <KotakPutih p={{ base: 2, md: 10 }}>
          <Grid>
            <Flex
              gap={{ base: 2, md: 6 }}
              flex={1}
              bgColor={{ md: "gray.100" }}
              w={{ base: "100%", md: "max" }}
              p={2}
              borderRadius="full"
              align="center"
              justify="center"
              mx="auto"
              flexDirection={{ base: "column", md: "row" }}
            >
              <ButtonAction
                cursor="pointer"
                backgroundColor={
                  activeType === "profiling" ? "green.400" : "gray.400"
                }
                color={activeType === "profiling" ? "#FFF" : "#000"}
                w={{ base: "max", md: "auto" }}
                onClick={() => onChangeMenu("profiling")}
              >
                PROFILING SERVICE
              </ButtonAction>

              <ButtonAction
                cursor="pointer"
                backgroundColor={
                  activeType === "matching" ? "orange.400" : "gray.400"
                }
                color={activeType === "matching" ? "#FFF" : "#000"}
                w={{ base: "max", md: "auto" }}
                onClick={() => onChangeMenu("matching")}
              >
                MATCHING SERVICE
              </ButtonAction>

              <ButtonAction
                cursor="pointer"
                backgroundColor={
                  activeType === "persona" ? "pink.400" : "gray.400"
                }
                color={activeType === "persona" ? "#FFF" : "#000"}
                w={{ base: "max", md: "auto" }}
                onClick={() => onChangeMenu("persona")}
              >
                PERSONA SERVICE
              </ButtonAction>
            </Flex>

            <Box borderBottom={"1px solid #ddd"} mt={6}></Box>

            {activeType === "matching" ? (
              <Flex
                flexDir="row"
                gap={5}
                flexWrap="wrap"
                width={"100%"}
                mt={{ sm: 10, md: 0 }}
              >
                {/* Header  */}
                <Grid
                  display={"flex"}
                  gap={4}
                  alignItems="center"
                  bgColor={"yellow"}
                  w={"full"}
                >
                  <Flex
                    justifyContent="center"
                    borderRadius="lg"
                    py={2}
                    px={4}
                    backgroundColor="green.200"
                    w={"20%"}
                  >
                    <Text color="green.700" fontWeight="bold" fontSize="sm">
                      Matched Name
                    </Text>
                  </Flex>

                  <Flex
                    borderColor="#CADFB8"
                    py={2}
                    px={4}
                    backgroundColor="green.200"
                    borderRadius="lg"
                    gap={1}
                    justifyContent="center"
                    w={"15%"}
                  >
                    <Text color="green.700" fontWeight="bold" fontSize="sm">
                      Service
                    </Text>
                  </Flex>

                  <Flex
                    py={2}
                    px={4}
                    backgroundColor="green.200"
                    borderRadius="lg"
                    gap={2}
                    justifyContent="center"
                    w={"20%"}
                  >
                    <Text color="green.700" fontWeight="bold" fontSize="sm">
                      Matched Result
                    </Text>
                  </Flex>

                  <Flex
                    py={2}
                    px={4}
                    backgroundColor="green.200"
                    borderRadius="lg"
                    gap={2}
                    justifyContent="center"
                    w={"15%"}
                  >
                    <Text color="green.700" fontWeight="bold" fontSize="sm">
                      Action
                    </Text>
                  </Flex>

                  <Flex
                    py={2}
                    px={4}
                    backgroundColor="green.200"
                    borderRadius="lg"
                    gap={2}
                    justifyContent="center"
                    w={"15%"}
                  >
                    <Text color="green.700" fontWeight="bold" fontSize="sm">
                      Tanggal Beli
                    </Text>
                  </Flex>

                  <Flex
                    py={2}
                    px={4}
                    backgroundColor="green.200"
                    borderRadius="lg"
                    gap={2}
                    justifyContent="center"
                    w={"15%"}
                  >
                    <Text color="green.700" fontWeight="bold" fontSize="sm">
                      Tanggal Expired
                    </Text>
                  </Flex>
                </Grid>

                {purchased.length > 0 &&
                  purchased.map((item, index) => (
                    <>
                      <Grid
                        key={index}
                        display={"flex"}
                        gap={4}
                        alignItems="center"
                        borderRadius={"lg"}
                        _hover={{ backgroundColor: "gray.100" }}
                        w={"full"}
                        mb={2}
                      >
                        <Flex
                          justifyContent="center"
                          py={2}
                          px={4}
                          w={"20%"}
                          gap={4}
                        >
                          <Flex
                            direction="column"
                            justifyContent="center"
                            align={"center"}
                          >
                            <Avatar
                              src={item.users?.user1?.photo}
                              h="70px"
                              w="70px"
                              border="2px solid #ddd"
                              alt={item?.users.user1?.name || "user1"}
                              loading="lazy"
                            />
                            <Text
                              textOverflow="ellipsis"
                              whiteSpace="nowrap"
                              overflow="hidden"
                              maxWidth={70}
                              fontSize={"xs"}
                              color={"gray.700"}
                            >
                              {item?.users?.user1?.name.split(" ")[0]}
                            </Text>
                          </Flex>
                          <Flex
                            direction="column"
                            justifyContent="center"
                            minWidth={70}
                            align={"center"}
                          >
                            <Avatar
                              src={item.users?.user2?.photo}
                              h="70px"
                              w="70px"
                              border="2px solid #ddd"
                              alt={item?.users.user1?.name || "user1"}
                              loading="lazy"
                            />
                            <Text
                              textOverflow="ellipsis"
                              whiteSpace="nowrap"
                              overflow="hidden"
                              maxWidth={70}
                              fontSize={"xs"}
                              color={"gray.700"}
                            >
                              {item?.users?.user2?.name.split(" ")[0]}
                            </Text>
                          </Flex>
                        </Flex>

                        <Flex
                          borderColor="#CADFB8"
                          py={2}
                          px={4}
                          gap={1}
                          justifyContent="center"
                          w={"15%"}
                        >
                          <Flex
                            direction="column"
                            gap={4}
                            justifyContent="center"
                            align={"center"}
                          >
                            <Text fontSize={"sm"}>General</Text>
                            <Text fontSize={"sm"}>Daily</Text>
                          </Flex>
                        </Flex>

                        <Box
                          py={2}
                          px={4}
                          justifyContent="center"
                          w={"20%"}
                          gap={4}
                        >
                          <Box width="100%" position="relative" mb={4}>
                            <LinearProgress
                              sx={{
                                height: 24,
                                borderRadius: 5,
                                width: "100%",
                                position: "absolute",
                              }}
                              color={
                                item.matching_type === 0
                                  ? "warning"
                                  : item.matching_type === 1
                                  ? "error"
                                  : "info"
                              }
                              variant="determinate"
                              value={item.stats.general}
                            />
                            <Text
                              color="white"
                              fontWeight="bold"
                              zIndex={1}
                              position="relative"
                              textAlign="center"
                            >
                              {item.stats.general}%
                            </Text>
                          </Box>

                          <Box width="100%" position="relative">
                            <LinearProgress
                              sx={{
                                height: 24,
                                borderRadius: 5,
                                width: "100%",
                                position: "absolute",
                              }}
                              color={
                                item.matching_type === 0
                                  ? "warning"
                                  : item.matching_type === 1
                                  ? "error"
                                  : "info"
                              }
                              variant="determinate"
                              value={item.stats.today}
                            />
                            <Text
                              color="white"
                              fontWeight="bold"
                              zIndex={1}
                              position="relative"
                              textAlign="center"
                            >
                              {item.stats.today}%
                            </Text>
                          </Box>
                        </Box>

                        <Flex py={2} px={4} justifyContent="center" w={"15%"}>
                          <Button
                            onClick={
                              item.expired_at &&
                              moment(item.expired_at).isAfter(new Date())
                                ? () => setDetailMatching(item)
                                : () => window.location.assign("/pricing")
                            }
                            bgColor={
                              item.expired_at &&
                              moment(item.expired_at).isAfter(new Date())
                                ? "teal.400"
                                : "tomato"
                            }
                            borderRadius={"full"}
                            textAlign="center"
                            width="100%"
                            py={1.5}
                            px={4}
                            w={16}
                            color="#fff"
                            fontSize={"xs"}
                            boxShadow={"lg"}
                            _hover={{ backgroundColor: "orange.500" }}
                          >
                            {item.expired_at &&
                            moment(item.expired_at).isAfter(new Date())
                              ? "View"
                              : "Beli lagi"}
                          </Button>
                        </Flex>

                        <Flex py={2} px={4} justifyContent="center" w={"15%"}>
                          <Text fontSize={"sm"} color={"gray.700"}>
                            {moment(item.created_at).format("DD MMM YYYY")}
                          </Text>
                        </Flex>

                        <Flex py={2} px={4} justifyContent="center" w={"15%"}>
                          <Text fontSize={"sm"} color={"gray.700"}>
                            {item.expired_at
                              ? moment(item.expired_at).format("DD MMM YYYY")
                              : "-"}
                          </Text>
                        </Flex>
                      </Grid>
                    </>
                  ))}
              </Flex>
            ) : (
              <TablePurchased
                columnsData={columnsDataPurchaseHistory}
                tableData={purchased}
                pagination={pagination}
                nextPage={nextPage}
                prevPage={prevPage}
                firstPage={firstPage}
                lastPage={lastPage}
                limitPage={limitPage}
                goToPage={goToPage}
                onClickAction={onClickAction}
                loading={loadingItems}
              />
            )}
          </Grid>
        </KotakPutih>

        {/* <KotakPutih>
          <HistoriTransaksi />
        </KotakPutih> */}
      </Box>
    </>
  );
}
