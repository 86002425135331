/* eslint-disable */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";

export function SidebarLinks({ routes }) {
  const location = useLocation();
  const activeColor = useColorModeValue("gray.700", "white");
  const activeIconColor = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.500", "white");

  const isActiveRoute = (path) =>
    location.pathname.includes(path.toLowerCase());

  const renderLink = (route) => {
    const linkPath =
      route.path === "/home" ? route.path : `${route.layout}${route.path}`;

    return (
      <NavLink to={linkPath} key={route.name}>
        <Flex
          py="2"
          px="2"
          mb={2}
          borderRadius="10"
          border={isActiveRoute(route.path) ? "2px solid orange.200" : "none"}
          _hover={{
            bg: "tomato",
            boxShadow: "xl",
            transition: "background-color 0.3s ease, box-shadow 0.3s ease",
            color: "white",
          }}
          _focus={{ outline: "none" }}
          bg={isActiveRoute(route.path) ? "tomato" : "transparent"}
          color={isActiveRoute(route.path) ? "white" : textColor}
          align={"center"}
        >
          {route.icon && (
            <Box
              color={isActiveRoute(route.path) ? activeIconColor : textColor}
              me={2}
            >
              {route.icon}
            </Box>
          )}
          <Text
            fontWeight={isActiveRoute(route.path) ? "bold" : "normal"}
            fontSize={{ base: "xs", sm: "xs", md:"x", lg: "sm" }}
          >
            {route.name}
          </Text>
        </Flex>
      </NavLink>
    );
  };

  const createLinks = (routes) => {
    return routes.map((route) => {
      if (route.category) {
        return (
          <Box key={route.name}>
            <Text
              fontSize="md"
              color={activeColor}
              fontWeight="bold"
              pt="18px"
              pb="12px"
              ps={{ sm: "10px", xl: "16px" }}
            >
              {route.name}
            </Text>
            {createLinks(route.items || [])}
          </Box>
        );
      } else if (route.layout === "/dashboard" || route.layout === "/auth") {
        return renderLink(route);
      }
      return null;
    });
  };

  return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
