import React, { useEffect, useState } from "react";
import axios from "axios";

import { Box, Button, Flex, Text } from "@chakra-ui/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import KontainerPublik from "tomslock/component/KontainerPublik";
import ErrorModal from "tomslock/component/ErrorModal";
import TitleLoggedPage from "tomslock/component/TitleLoggedPage";
import KotakPutih from "tomslock/component/KotakPutih";

import ResultUserComparison from "views/matching/ResultUserComparison";
import UserPickSelect from "views/matching/UserPickSelect";
import ResultButtonMatching from "views/matching/ResultButtonMatching";
import HorizontalTimeline from "views/matching/HorizontalTimeline";
import SubTitleMatching from "views/matching/SubTitleMatching";
import TypePickContainer from "views/matching/TypePickContainer";
import LoggedButtonPickUser from "views/matching/LoggedButtonPickUser";
import PublicButtonPickUser from "views/matching/PublicButtonPickUser";
import ButtonNextStep from "views/matching/ButtonNextStep";
import ShowPickAvatar from "views/matching/ShowPickVatar";
import axiosInstance from "tomslock/utils/axiosInstance";

const IndexMatchingLogged = (props) => {
  const [user, setUser] = useState();
  const [localUser, setLocalUser] = useState();
  const [user1, setUser1] = useState();
  const [user2, setUser2] = useState();
  const [visibleSearchUser, setVisibleSearchUser] = useState();
  const [searchData, setSearchData] = useState([]);
  const [activeCard, setActiveCard] = useState(0);
  const [matching, setMatching] = useState();
  const [open, setOpen] = useState("");
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [mode, setMode] = useState(-1);
  const [cardMatching, setCardMatching] = useState([]);
  const [stats, setStats] = useState();
  const [level, setLevel] = useState("");
  const [error, setError] = useState("");

  const [step, setStep] = useState(1);
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");

    let locUser = localStorage.getItem("user");
    setLocalUser(JSON.parse(locUser));
    if (props?.match?.params?.id && props?.match?.params?.type) {
      axiosInstance
        .get(`/user/${props.match.params.type}/${props.match.params.id}`, {
          headers: {
            Authorization: `Bearer ${token ? token : ""}`,
            "Content-Type": "x-www-from-urlencode",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        })
        .then((res) => {
          setUser(res.data.user ?? res.data.colleagues);
        })
        .catch((err) => {
          if (
            err.response?.data?.message === "Unauthorized" ||
            err.response?.data === "Unauthorized"
          ) {
            // navigate('/');
          }
        });
    } else {
      if (locUser) {
        setUser(JSON.parse(locUser));
      } else {
        setUser("guess");
        // window.location.assign('/home')
      }
    }
  }, [props]);

  useEffect(() => {
    window.onscroll = function () {
      myFunction();
    };

    var navbar = document.getElementById("sticky-guess");
    var sticky = navbar.offsetTop - 100;

    function myFunction() {
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky-guess");
        // type.classList.add("sticky")
      } else {
        navbar.classList.remove("sticky-guess");
        // type.classList.remove("sticky");
      }
    }
  }, []);

  useEffect(() => {
    if (stats) {
      if (stats < 50) {
        setLevel("Average");
      } else if (stats < 60) {
        setLevel("Moderate");
      } else if (stats < 70) {
        setLevel("Intermediate");
      } else if (stats < 80) {
        setLevel("Leading");
      } else if (stats < 90) {
        setLevel("Advance");
      } else {
        setLevel("The BEST");
      }
    }
  }, [stats]);

  useEffect(() => {
    setSelectedType(mode);
    if (mode === 1) {
      if (user1 && user2) {
        if (user1.gender === user2.gender) {
          setError(
            "Matching Romance hanya berlaku untuk gender yang berbeda saja"
          );
        } else {
          setError("");
        }
      } else {
        setError("");
      }
    } else {
      setError("");
    }
  }, [mode, user1, user2]);

  const onRunMatching = async () => {
    const token = localStorage.getItem("token");

    if (!user1) {
      setError("Please select User 1");

      return;
    }

    if (!user2) {
      setError("Please select User 2");
      return;
    }

    //  if (token) {
    try {
      const res = await axiosInstance.get(
        `/matching/run?id1=${user1?.id}&id2=${user2?.id}&type1=${
          user1.type ?? 0
        }&type2=${user2.type ?? 0}&matching_type=${mode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded", // Corrected Content-Type
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        }
      );

      setStep(3);
      setMatching(res.data);
      setCardMatching(res.data.cards_general);
      setStats(res.data.stats?.general);
      setActiveCard(1);
      setIsUnlocked(res.data.is_unlocked);
    } catch (err) {
      setError(err?.response?.data?.message);
      if (
        err.response?.data?.message === "Unauthorized" ||
        err.response?.data === "Unauthorized"
      ) {
        // navigate('/'); // Uncomment if navigation is needed
        window.location.href = "/login";
      }
    }
    //  } else {
    //    window.location.assign("/login?service=matching");
    //  }
  };

  const onClickCard = (props) => {
    setActiveCard(props);

    // setMatchingCard(props);
    if (matching) {
      if (props === 1) {
        setCardMatching(matching.cards_general);
        setStats(matching.stats.general);
      } else if (props === 2) {
        setCardMatching(matching.cards_daily);
        setStats(matching.stats.today);
      }
    }
  };

  const onChangeSearch = (text) => {
    const token = localStorage.getItem("token");

    if (token) {
      if (text.target.value.length > 2) {
        if (visibleSearchUser === "colleague") {
          axiosInstance
            .get(`/user/colleague?search=${text.target.value}`, {
              headers: {
                Authorization: `Bearer ${token ? token : ""}`,
                "Content-Type": "x-www-from-urlencode",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Headers":
                  "Origin, X-Requested-With, Content-Type, Accept",
              },
            })
            .then((res) => {
              setSearchData(res?.data?.colleagues);
              // setCards(res?.data?.cards)
            })
            .catch((err) => {
              if (
                err.response?.data?.message === "Unauthorized" ||
                err.response?.data === "Unauthorized"
              ) {
                // navigate('/');
              }
            });
        } else {
          axiosInstance
            .get(`/user/friendship/find-friend?search=${text.target.value}`, {
              headers: {
                Authorization: `Bearer ${token ? token : ""}`,
                "Content-Type": "x-www-from-urlencode",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Headers":
                  "Origin, X-Requested-With, Content-Type, Accept",
              },
            })
            .then((res) => {
              setSearchData(res?.data?.users);
              // setCards(res?.data?.cards)
            })
            .catch((err) => {
              if (
                err.response?.data?.message === "Unauthorized" ||
                err.response?.data === "Unauthorized"
              ) {
                // navigate('/');
                window.location.href = "/login";
              }
            });
        }
      } else {
        setSearchData([]);
      }
    }
  };

  const onSelectMe = () => {
    if (!user1) {
      setUser1({
        ...user,
        type: props?.match?.params?.type === "colleague" ? "1" : "0",
      });
    } else if (user1 !== user) {
      setUser2({
        ...user,
        type: props?.match?.params?.type === "colleague" ? "1" : "0",
      });
    }
  };

  const onSelectUser = (data, type) => {
    if (!user1) {
      setUser1({ ...data, type: type === "colleague" ? "1" : "0" });
      setVisibleSearchUser(false);
      setSearchData([]);
    } else {
      setUser2({ ...data, type: type === "colleague" ? "1" : "0" });
      setVisibleSearchUser(false);
      setSearchData([]);
    }
  };

  const onRemoveUser = (user) => {
    if (user === 1) {
      setUser1();
    } else if (user === 2) {
      setUser2();
    }
  };

  const onChangeUser1 = (userInfo) => {
    if (userInfo && typeof userInfo === "object") {
      setUser1((prevUser) => ({
        ...prevUser,
        ...userInfo,
      }));
    }
  };

  const onChangeUser2 = (userInfo) => {
    if (userInfo && typeof userInfo === "object") {
      setUser2((prevUser) => ({
        ...prevUser,
        ...userInfo,
      }));
    }
  };

  // Step by Step
  const handleResetSelectType = () => {
    setMode(-1);
    setStep(1);
    setSelectedType("");
  };

  const handleNextStep = () => {
    if (step < 2) {
      setStep(step + 1);
    }
  };

  const handlePreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <>
      <TitleLoggedPage title="Matching Persona" />

      <KotakPutih>
        {error && <ErrorModal error={error} />}

        <Box>
          <HorizontalTimeline currentStep={step - 1} />
          <SubTitleMatching mode={mode} step={step} />

          <Box justifyContent="center" alignItems="center" height="auto">
            {step === 1 && (
              <Flex direction="column">
                <TypePickContainer mode={mode} setMode={setMode} />
              </Flex>
            )}

            {step === 2 && (
              <Flex
                mx="auto"
                pt={4}
                pb={24}
                flexDirection="column"
                gap={5}
                width={{ sm: "100%" }}
                mt={16}
              >
                <ShowPickAvatar
                  user1={user1}
                  user2={user2}
                  onChangeUser1={onChangeUser1}
                  onChangeUser2={onChangeUser2}
                  onRemoveUser={onRemoveUser}
                />
                <Flex flexDirection="column" gap={5}>
                  <Flex gap={3} flexWrap="wrap" width={{ sm: "100%" }}>
                    {user !== "guest" && (
                      <LoggedButtonPickUser
                        user={user}
                        user1={user1}
                        user2={user2}
                        onSelectMe={onSelectMe}
                        setVisibleSearchUser={setVisibleSearchUser}
                        onChangeSearch={onChangeSearch}
                        searchData={searchData}
                        onSelectUser={onSelectUser}
                      />
                    )}
                  </Flex>
                </Flex>
              </Flex>
            )}

            {step === 3 && matching && activeCard !== 0 && (
              <Flex direction="column">
                <ResultButtonMatching
                  activeCard={activeCard}
                  cardMatching={cardMatching}
                  onClickCard={onClickCard}
                />
                <ResultUserComparison
                  props={props}
                  user1={user1}
                  user2={user2}
                  stats={stats}
                  level="Intermediate"
                  mode={mode}
                  cardMatching={cardMatching}
                  isUnlocked={isUnlocked}
                  open={open}
                />
              </Flex>
            )}
          </Box>

          <KontainerPublik>
            <ButtonNextStep
              step={step}
              mode={mode}
              user1={user1}
              user2={user2}
              onRunMatching={onRunMatching}
              handlePreviousStep={handlePreviousStep}
              handleNextStep={handleNextStep}
              handleResetSelectType={handleResetSelectType}
            />
          </KontainerPublik>

          <Flex direction={{ sm: "column-reverse", md: "row" }} gap={10}>
            <Flex flex={1} direction="column">
              <Flex
                direction="row"
                id="sticky-guess"
                gap={{ sm: 3, md: 5 }}
                flexWrap="wrap"
              >
                {matching && activeCard !== 0 && (
                  <>
                    {["Matching", "General match", "Daily match"].map(
                      (label, index) => (
                        <Button
                          key={index}
                          bgColor={
                            cardMatching.length === 0
                              ? "gray"
                              : activeCard === index
                              ? "#3572EF"
                              : "white"
                          }
                          color={
                            activeCard === index || cardMatching.length === 0
                              ? "white"
                              : "#3572EF"
                          }
                          borderWidth={1}
                          borderColor="#3572EF"
                          fontSize={{ sm: "xs", md: "sm" }}
                          fontWeight="bold"
                          borderRadius="5px"
                          onClick={() => onClickCard(index)}
                          flex={{ sm: 1 }}
                          disabled={cardMatching.length === 0}
                          px={{ sm: "10px", md: "30px" }}
                          py="5px"
                        >
                          {index === 0 && <ArrowBackIcon fontSize="0.75rem" />}
                          {label}
                        </Button>
                      )
                    )}
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </KotakPutih>
    </>
  );
};

export default IndexMatchingLogged;
