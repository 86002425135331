import React, { useEffect, useState } from "react";
import { Box, Flex, Grid, Text } from "@chakra-ui/react";

// Custom components

import ButtonAction from "tomslock/component/ButtonAction";
import CardUser from "./components/CardUser";
import axiosInstance from "tomslock/utils/axiosInstance";

export default function FriendList() {
  // const [user, setUser] = useState();
  const [friends, setFriends] = useState([]);
  const [colleague, setColleague] = useState([]);
  const [activeMenu, setActiveMenu] = useState(0);
  const [paginationFriend, setPaginationFriend] = useState({
    limit: 5,
    page: 1,
    max: 1,
    total: 1,
  });
  const [paginationColleague, setPaginationColleague] = useState({
    limit: 5,
    page: 1,
    max: 1,
    total: 1,
  });

  // useEffect(() => {
  //   const localUser = localStorage.getItem("user");
  //   if (localUser) {
  //     setUser(JSON.parse(localUser));
  //   } else {
  //     window.location.assign("/home");
  //   }
  // }, []);

  const fetchFriends = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axiosInstance.get(
        `/user/friendship/?limit=${paginationFriend.limit}&page=${paginationFriend.page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setFriends(res.data?.friendship);
      setPaginationFriend({
        ...paginationFriend,
        max: res.data.totalPages,
        total: res.data.totalCount,
      });
    } catch (err) {
      if (err.response?.data?.message === "Unauthorized") {
        window.location.assign("/dashboard");
      }
    }
  };

  const fetchColleagues = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axiosInstance.get(
        `/user/colleague?limit=${paginationColleague.limit}&page=${paginationColleague.page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setColleague(res?.data?.colleagues);
      setPaginationColleague({
        ...paginationColleague,
        max: res.data.totalPages,
        total: res.data.totalCount,
      });
    } catch (err) {
      if (err.response?.data?.message === "Unauthorized") {
        window.location.assign("/dashboard");
      }
    }
  };

  useEffect(() => {
    fetchFriends();
  }, [paginationFriend.page, paginationFriend.limit]);

  useEffect(() => {
    fetchColleagues();
  }, [paginationColleague.page, paginationColleague.limit]);

  // const handlePageChange = (type, direction) => {
  //   const pagination =
  //     type === "friend" ? paginationFriend : paginationColleague;
  //   const setPagination =
  //     type === "friend" ? setPaginationFriend : setPaginationColleague;

  //   if (direction === "next" && pagination.page < pagination.max) {
  //     setPagination({ ...pagination, page: pagination.page + 1 });
  //   } else if (direction === "prev" && pagination.page > 1) {
  //     setPagination({ ...pagination, page: pagination.page - 1 });
  //   } else if (direction === "first") {
  //     setPagination({ ...pagination, page: 1 });
  //   } else if (direction === "last") {
  //     setPagination({ ...pagination, page: pagination.max });
  //   }
  // };

  const changeMenu = (menu) => {
    setActiveMenu(menu);
  };

  return (
    <Box>
      <Grid mb={6}>
        <Text
          color="gray.700"
          align={"center"}
          fontWeight={"bold"}
          fontSize={"3xl"}
          mb={6}
        >
          {activeMenu === 0 ? " Friend Colleague" : "Friends from Apps"}
        </Text>
        <Flex
          direction={{ base: "column", md: "row" }}
          gap={2}
          bgColor={{ base: "white", md: "gray.200" }}
          p={4}
          borderRadius={{ base: "2xl", md: "full" }}
          boxShadow={{ base: "lg" }}
          w={{ base: "100%", md: "max" }}
          align={"center"}
          justifyContent={"center"}
          mx={"auto"}
          mb={{ md: 6 }}
        >
          {activeMenu === 0 ? (
            <>
              <ButtonAction
                color="red"
                fontWeight="bold"
                w={{ base: "full", md: "auto" }}
              >
                Friend Colleague
              </ButtonAction>
              <ButtonAction
                fontWeight="bold"
                onClick={() =>
                  window.location.assign("/dashboard/friend-list/add-colleague")
                }
                color={"yellow"}
                w={{ base: "full", md: "auto" }}
              >
                Add Colleague
              </ButtonAction>

              <ButtonAction
                color={"cyan"}
                fontWeight="bold"
                w={{ base: "full", md: "auto" }}
                onClick={() => changeMenu(1)}
              >
                Friends from Apps
              </ButtonAction>
            </>
          ) : (
            <>
              <ButtonAction
                color="red"
                fontWeight="bold"
                w={{ base: "full", md: "auto" }}
              >
                Friend From Apps
              </ButtonAction>
              <ButtonAction
                fontWeight="bold"
                onClick={() =>
                  window.location.assign("/dashboard/friend-list/add-friend")
                }
                color={"blue"}
                w={{ base: "full", md: "auto" }}
              >
                Add Friend
              </ButtonAction>

              <ButtonAction
                fontWeight="bold"
                color={"purple"}
                w={{ base: "full", md: "auto" }}
                onClick={() => changeMenu(0)}
              >
                Friends Colleague
              </ButtonAction>
            </>
          )}
        </Flex>
      </Grid>

      {activeMenu === 0 ? (
        <CardUser
          type="colleague"
          colleague={colleague}
          activeMenu={activeMenu}
        />
      ) : (
        <CardUser type="friend" friends={friends} activeMenu={activeMenu} />
      )}
    </Box>
  );
}
