/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import qs from "qs";
import { Avatar, Box, Button, Flex, Grid, Input, Text } from "@chakra-ui/react";
import KotakPutih from "tomslock/component/KotakPutih";
import TitleLoggedPage from "tomslock/component/TitleLoggedPage";
import ButtonAction from "tomslock/component/ButtonAction";
import ButtonMiddle from "tomslock/component/ButtonMiddle";
import axiosInstance from "tomslock/utils/axiosInstance";

const AddFriend = (props) => {
  const [user, setUser] = useState({});
  const [params, setParams] = useState("");
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axiosInstance
      .get("/user/current", {
        headers: {
          Authorization: `Bearer ${token ? token : ""}`,
          "Content-Type": "x-www-from-urlencode",
        },
      })
      .then((res) => {
        setUser(res.data.user);
      })
      .catch((err) => {
        if (
          err.response.data.message === "Unauthorized" ||
          err.response.data === "Unauthorized"
        ) {
                window.location.href = "/login"; 

          
        }
      });
  }, []);

  const onChangeInput = (e) => {
    setParams(e.target.value);
    handleSubmit();
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("token");
    axiosInstance
      .get(
        `/user/friendship/find-friend?search=${params}`,
        {
          headers: {
            Authorization: `Bearer ${token ? token : ""}`,
            "Content-Type": "x-www-from-urlencode",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        }
      )
      .then((res) => {
        setSearchData(res?.data?.users);
        // setCards(res?.data?.cards)
      })
      .catch((err) => {
        if (
          err.response?.data?.message === "Unauthorized" ||
          err.response?.data === "Unauthorized"
        ) {
          // navigate('/');
        }
      });
  };

  const handleAddFriend = (item) => {
    const token = localStorage.getItem("token");
    axiosInstance
      .post(
        "/user/friendship/add-friend",
        qs.stringify({ friend_id: item.id }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        window.location.assign("/dashboard/friend-list");
      })
      .catch((err) => {
        console.log("err: ", err);
        // setError(err.response.data.message || err.response.data)
      });
  };

  return (
    <>
      <Box>
        <KotakPutih p={6}>
          <TitleLoggedPage title="Add Friend" />

          <Box borderBottom={"1px solid #ddd"} mb={4}></Box>

          <Flex
            direction={{ base: "column", sm: "column", md: "row" }}
            gap={2}
            id="sticky"
            alignItems="center"
            mb={10}
            justifyContent={"center"}
            mx={"auto"}
          >
            <Flex gap={3} alignItems="center">
              <Input
                value={params}
                variant="search"
                placeholder="Search user on apps"
                id="search"
                fontSize="sm"
                bg={"white"}
                color={"black"}
                fontWeight="500"
                px="4"
                py="3"
                w={{ base: "300px", md: "400px" }}
                onChange={onChangeInput}
                borderRadius={"lg"}
                border={"1px solid #ddd"}
              />
            </Flex>
            <ButtonAction color="red" onClick={() => handleSubmit()}>
              Search
            </ButtonAction>

            <ButtonAction
              onClick={() => {
                window.location.assign("/dashboard/friend-list/add-friend");
              }}
              w={"auto"}
            >
              Reset
            </ButtonAction>

            <ButtonAction
              color="teal"
              onClick={() => {
                window.location.assign("/dashboard/friend-list");
              }}
              w={"auto"}
            >
              Back to Friends List
            </ButtonAction>
          </Flex>

          <Grid
            templateColumns={{
              base: "1fr",
              sm: "1fr",
              md: "repeat(2, 1fr)",
              lg: "repeat(4, 1fr)",
            }}
            gap={6}
            mb={6}
          >
            {searchData.map((item) => (
              <Box
                key={item.id}
                bg="white"
                borderRadius="2xl"
                padding={4}
                display="flex"
                flexDirection="column"
                height="100%"
                bgColor={"gray.50"}
                border={"1px solid #eee"}
              >
                <Flex alignItems="center" gap={3} flexDirection="row">
                  <Avatar
                    src={item.photo}
                    h="40px"
                    w="40px"
                    border="1px solid #059212"
                    // borderColor={borderColor}
                  />
                  <Flex flexDirection="column" flex={1} maxWidth={120}>
                    <Text
                      whiteSpace="nowrap"
                      // whiteSpace={{ base: "break-spaces", md: "nowrap" }}
                      textOverflow="ellipsis"
                      overflow="hidden"
                      maxWidth={100}
                    >
                      {item.name}
                    </Text>
                    {/* {item.privacy === 0 && ( */}
                    <Text
                      fontSize="xs"
                      whiteSpace="nowrap"
                      mt={-2}
                      textOverflow="ellipsis"
                      overflow="hidden"
                      maxWidth={100}
                    >
                      {item.gender === 0 ? "Male" : "Female"}
                    </Text>
                    {/* )} */}
                  </Flex>
                  {item.status === "NOT_FRIEND_PUBLIC" ||
                  item.status === "NOT_FRIEND_PRIVATE" ? (
                    <ButtonMiddle
                      color="green"
                      onClick={() => handleAddFriend(item)}
                    >
                      Add Friend
                    </ButtonMiddle>
                  ) : item.status === "WAITING FOR RESPOND" ? (
                    <Button
                      borderWidth={1}
                      borderColor="blue.300"
                      bg={"white"}
                      color="black"
                      fontSize="xs"
                      // fontWeight='bold'
                      borderRadius="5px"
                      px="10px"
                      py="5px"
                      disabled
                      // onClick={() => handleAddFriend(item)}
                      // disabled={pagination.page === pagination.max}
                    >
                      Requested
                    </Button>
                  ) : (
                    <Flex width="80px">
                      {/* <Text fontSize={"xs"}>Sudah Berteman</Text> */}
                    </Flex>
                  )}
                </Flex>
              </Box>
            ))}
          </Grid>
        </KotakPutih>
      </Box>
    </>
  );
};

export default AddFriend;
