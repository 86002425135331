import { Text } from "@chakra-ui/react";
import React from "react";

const TitleLoggedPage = ({ title }) => {
  return (
    <Text fontSize={"3xl"} fontWeight={"bold"} align={"center"} mb={6}>
      {title ? title : "Title"}
    </Text>
  );
};

export default TitleLoggedPage;
