import React from "react";
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const HeaderAdminRight = ({ user, handleLogout, secondary }) => {
  // Chakra Color Mode
  let menuBg = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  return (
    <div>
      <Flex
        w={{ sm: "100%", md: "auto" }}
        alignItems="center"
        flexDirection="row"
        bg={menuBg}
        flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
        p="2"
        gap={2}
        borderRadius="full"
        boxShadow={shadow}
      >
        <Button onClick={() => window.location.reload()}>
          <Box
            borderRadius="full"
            bg="#555"
            p={2}
            border={"4px solid #999"}
            _active={{
              border: "4px solid ",
              borderColor: "#ffaaaa",
              transform: "rotate(180deg)",
              transition: "transform 0.3s ease-in-out",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#fff"
              className="size-6"
              height="24"
              width="24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </Box>
        </Button>
        <Menu>
          <MenuButton as={Button} borderRadius="5">
            <Flex align="center">
              <Image
                mr={2}
                borderRadius="full"
                border="2px solid gray"
                boxSize="12"
                src={user?.photo}
                alt={user?.name}
                objectFit="cover"
                transition="transform 0.2s"
                _hover={{ transform: "scale(1.05)" }}
                loading="lazy"
              />
              <Box mr="4">
                <Box fontSize="sm" fontWeight="bold" align="left">
                  {user?.name}
                </Box>
                <Box fontSize="xs" color="gray.500" align="left" mt={-2}>
                  {user?.occupation || "user"}
                </Box>
              </Box>
            </Flex>
          </MenuButton>

          <MenuList
            bgColor="white"
            p={3}
            w="48"
            borderRadius="2xl"
            border="1px solid #eee"
            boxShadow="lg"
          >
            <MenuItem
              as={Link}
              to="/dashboard/profile"
              py={2}
              px={4}
              borderRadius="lg"
              _hover={{
                bg: "tomato",
                transition: "background-color 0.3s ease",
                color: "white",
              }}
            >
              <Text fontSize="sm" ml={2}>
                Profile
              </Text>
            </MenuItem>
            <MenuItem
              as={Link}
              to="/settings"
              py={2}
              px={4}
              borderRadius="lg"
              _hover={{
                bg: "tomato",
                transition: "background-color 0.3s ease",
                color: "white",
              }}
            >
              <Text fontSize="sm" ml={2}>
                Settings
              </Text>
            </MenuItem>
            <MenuItem
              onClick={handleLogout}
              py={2}
              px={4}
              color="red"
              borderRadius="lg"
              _hover={{
                bg: "tomato",
                transition: "background-color 0.3s ease",
                color: "white",
              }}
            >
              <Text fontSize="sm" ml={2}>
                Logout
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </div>
  );
};

export default HeaderAdminRight;
