import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";

import ServiceTable from "./components/ServiceTable";
import { columnsDataService } from "./variables/columnsData";

import ProfileMain from "./ProfileMain";
import axiosInstance from "tomslock/utils/axiosInstance";

export default function Overview() {
  const [user, setUser] = useState();
  const [service, setService] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingItems, setLoadingItems] = useState({});

  useEffect(() => {
    const localUser = localStorage.getItem("user");

    if (localUser) {
      try {
        setUser(JSON.parse(localUser));
      } catch (error) {
        console.error("Failed to parse user data from localStorage:", error);
        localStorage.removeItem("user");
        window.location.assign("/home");
      }
    } else {
      window.location.assign("/home");
    }
  }, []);

  const fetchServiceData = async () => {
    try {
      const token = localStorage.getItem("token") || "";
      if (!token) {
        window.location.assign("/login");
        return;
      }

      const response = await axiosInstance.get("/user/service/table-list", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.data?.products) {
        setService(response.data.products);
      }
    } catch (error) {
      if (
        error.response?.data?.message === "Unauthorized" ||
        error.response?.status === 401
      ) {
        console.error("Unauthorized access - redirecting to login.");
        window.location.assign("/login"); // Redirect to login
      } else {
        console.error("Error fetching service data:", error);
      }
    }
  };

  useEffect(() => {
    fetchServiceData();
  }, []);

  const onClickCategory = (props) => {
    console.log(props);
    if (props.category === "Web View" && props.service === "webview") {
      window.location.assign(
        `/dashboard/profile/${user?.username}/profiling-web`
      );
    } else if (props.category === "Web View" && props.service === "persona") {
      window.location.assign(
        `/dashboard/profile/${user?.username}/persona-web`
      );
    } else if (props.category === "Web View" && props.service === "matching") {
      window.location.assign(`/matching`);
    }
  };

  const onClickDetail = (props) => {
    console.log(props);
    if (
      props.category === "File PDF" &&
      props.name === "Standard Profiling Book"
    ) {
      window.location.assign(
        `/dashboard/profile/${user?.username}/profiling-standard-web`
      );
    } else if (
      props.category === "File PDF" &&
      props.name === "Complete Profiling Book"
    ) {
      window.location.assign(
        `/dashboard/profile/${user?.username}/profiling-complete-web`
      );
    }
  };

  const onClickDownload = async (props) => {
    setLoadingItems((prev) => ({ ...prev, [props.index]: true }));
    const token = localStorage.getItem("token");
    setLoading(true);
    let baseUrl;

    // Determine the base URL based on the category and name
    if (props.category === "File PDF") {
      switch (props.name) {
        case "Standard Profiling Book":
          baseUrl = `/profiling/pdf/link?id=${user.id}&type=0&version=standard`;
          break;
        case "Complete Profiling Book":
          baseUrl = `/profiling/pdf/link?id=${user.id}&type=0&version=complete`;
          break;
        case "Career Profiling Book":
          baseUrl = `/profiling/pdf/career`;
          break;
        default:
          console.warn(`Unknown PDF name: ${props.name}`);
          setLoading(false);
          setLoadingItems((prev) => ({ ...prev, [props.index]: false }));
          return;
      }
    } else {
      console.warn(`Unknown category: ${props.category}`);
      setLoading(false);
      setLoadingItems((prev) => ({ ...prev, [props.index]: false }));
      return;
    }

    try {
      // Make the API call to download the file
      const response = await axiosInstance.get(baseUrl, {
        headers: {
          Authorization: `Bearer ${token || ""}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        responseType: "blob",
      });

      // Create a download link and simulate a click
      const blobUrl = window.URL.createObjectURL(response.data);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = `${
        props.name === "Career Profiling Book"
          ? "Karir Terbaik Untuk"
          : props.name
      } ${user.name}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(blobUrl); 
    } catch (err) {
      console.error("Download error:", err);
      if (
        err.response?.data?.message === "Unauthorized" ||
        err.response?.data === "Unauthorized"
      ) {
        console.error("Unauthorized access - handling redirection if needed.");
        window.location.assign(`/login`);
      }
    } finally {
      // Reset loading states
      setLoading(false);
      setLoadingItems((prev) => ({ ...prev, [props.index]: false }));
    }
  };

  // const onClickDownload = (props) => {
  //   setLoadingItems((prev) => ({ ...prev, [props.index]: true })); // Set individual item loading
  //   const token = localStorage.getItem("token");
  //   setLoading(true);
  //   let baseUrl;

  //   // Determine the base URL based on the category and name
  //   switch (props.category) {
  //     case "File PDF":
  //       switch (props.name) {
  //         case "Standard Profiling Book":
  //           baseUrl = `/profiling/pdf/link?id=${user.id}&type=0&version=standard`;
  //           break;
  //         case "Complete Profiling Book":
  //           baseUrl = `/profiling/pdf/link?id=${user.id}&type=0&version=complete`;
  //           break;
  //         case "Career Profiling Book":
  //           baseUrl = `/profiling/pdf/career`;
  //           break;
  //         default:
  //           console.warn(`Unknown PDF name: ${props.name}`);
  //           setLoading(false);
  //           setLoadingItems((prev) => ({ ...prev, [props.index]: false }));
  //           return;
  //       }
  //       break;
  //     default:
  //       console.warn(`Unknown category: ${props.category}`);
  //       setLoading(false);
  //       setLoadingItems((prev) => ({ ...prev, [props.index]: false }));
  //       return;
  //   }

  //   // Make the API call to download the file
  //   axiosInstance
  //     .get(baseUrl, {
  //       headers: {
  //         Authorization: `Bearer ${token || ""}`,
  //         "Content-Type": "application/x-www-form-urlencoded", // Corrected content type
  //       },
  //       responseType: "blob",
  //     })
  //     .then((res) => {
  //       const a = document.createElement("a");
  //       a.href = window.URL.createObjectURL(res.data);
  //       a.download = `${
  //         props.name === "Career Profiling Book"
  //           ? "Karir Terbaik Untuk"
  //           : props.name
  //       } ${user.name}.pdf`;
  //       document.body.appendChild(a);
  //       a.click();
  //       a.remove();
  //     })
  //     .catch((err) => {
  //       console.error("Download error:", err);
  //       if (
  //         err.response?.data?.message === "Unauthorized" ||
  //         err.response?.data === "Unauthorized"
  //       ) {
  //         // Optionally handle unauthorized access, e.g., redirect
  //         // navigate('/');
  //       }
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //       setLoadingItems((prev) => ({ ...prev, [props.index]: false }));
  //     });
  // };

  // const onClickBuy = (props) => {
  //   if (props.service === "profiling") {
  //     window.location.assign(
  //       `/dashboard/profile/${user?.username}/product/profiling-book`
  //     );
  //   } else if (props.service === "webview") {
  //     window.location.assign(
  //       `/dashboard/profile/${user?.username}/product/profiling-web`
  //     );
  //   } else if (
  //     props.service === "Persona" &&
  //     props.name === "General Persona"
  //   ) {
  //     window.location.assign(
  //       `/dashboard/profile/${user?.username}/product/persona`
  //     );
  //   } else if (
  //     props.service === "Persona" &&
  //     props.name === "Daily Vibe Persona"
  //   ) {
  //     window.location.assign(
  //       `/dashboard/profile/${user?.username}/product/daily-vibe`
  //     );
  //   } else if (props.service === "matching") {
  //     window.location.assign(
  //       `/dashboard/profile/${user?.username}/product/matching`
  //     );
  //   }
  // };

  const onClickPrice = (props) => {
    if (props.service === "profiling" || props.service === "webview") {
      window.location.assign(`/pricing#profiling`);
    } else if (
      props.service === "Persona" ||
      props.name === "General Persona" ||
      props.name === "Daily Vibe"
    ) {
      window.location.assign(`/pricing#persona`);
      //   window.location.assign(`/dashboard/profile/${user?.username}/product/persona`)
    } else if (props.service === "matching") {
      window.location.assign(`/pricing#matching`);
      //   window.location.assign(`/dashboard/profile/${user?.username}/product/matching`)
    }
  };

  return (
    <Box pb={36}>
      {/* By Tomslock  */}
      <ProfileMain user={user} />

      <Box mb={6}>
        <ServiceTable
          columnsData={columnsDataService}
          tableData={service}
          onClickCategory={onClickCategory}
          onClickBuy={onClickPrice}
          onClickPrice={onClickPrice}
          onClickDetail={onClickDetail}
          onClickDownload={onClickDownload}
          loading={loadingItems}
          // user={user}
        />
      </Box>
    </Box>
  );
}
