/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import qs from "qs";

import useStyles from "./style";
import { Box, Flex, Text, Input } from "@chakra-ui/react";
import TitleLoggedPage from "tomslock/component/TitleLoggedPage";
import KotakPutih from "tomslock/component/KotakPutih";
import ErrorModal from "tomslock/component/ErrorModal";
import ButtonSubmit from "tomslock/component/ButtonSubmit";
import ButtonCloseModal from "tomslock/component/ButtonCloseModal";
import axiosInstance from "tomslock/utils/axiosInstance";

const AddColleague = () => {
  //   const [addBy, setAddBy] = useState('username');
  const [user, setUser] = useState({});
  const [error, setError] = useState();
  const [params, setParams] = useState({});
  const styles = useStyles();

  useEffect(() => {
    const token = localStorage.getItem("token");
    axiosInstance
      .get("/user/current", {
        headers: {
          Authorization: `Bearer ${token ? token : ""}`,
          "Content-Type": "x-www-from-urlencode",
        },
      })
      .then((res) => {
        setUser(res.data.user);
      })
      .catch((err) => {
        if (
          err.response.data.message === "Unauthorized" ||
          err.response.data === "Unauthorized"
        ) {
          window.location.href = "/login";
        }
      });
  }, []);

  const onChangeInput = (e) => {
    const tmpValue = {};
    tmpValue[e.target.id] = e.target.value;
    setParams({ ...params, ...tmpValue });
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("token");
    axiosInstance
      .post(
        "/colleague/add",
        qs.stringify({ ...params }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        window.location.assign("/dashboard/friend-list");
      })
      .catch((err) => {
        console.log("err: ", err);
        setError(err.response.data);
        // setError(err.response.data.message || err.response.data)
      });
  };

  return (
    <>
      <Box w={{ md: "45%" }}>
        {error && <ErrorModal error={error} />}

        <KotakPutih p={6}>
          <TitleLoggedPage title="Add Colleague" />

          <Box borderTop={"1px solid #ddd"} pt={6}>
            <Box mb={4}>
              <Text fontSize={"sm"} color={"gray.500"} mb={1}>
                Display Name
              </Text>
              <FormControl variant="standard" className={styles.formControl}>
                <Input
                  fullWidth
                  id="name"
                  type="text"
                  placeholder="Display Name"
                  onChange={onChangeInput}
                  border={"1px solid #ddd"}
                  borderRadius={"md"}
                  p={2}
                  fontSize={"sm"}
                />
              </FormControl>
            </Box>

            <Box mb={4}>
              <div className="row add-friend">
                <Text fontSize={"sm"} color={"gray.500"} mb={1}>
                  Tanggal Lahir
                </Text>
                <FormControl variant="standard" className={styles.formControl}>
                  <Input
                    fullWidth
                    id="birth_date"
                    type="date"
                    placeholder="Tanggal Lahir"
                    onChange={onChangeInput}
                    border={"1px solid #ddd"}
                    borderRadius={"md"}
                    p={2}
                    fontSize={"sm"}
                  />
                </FormControl>
              </div>
            </Box>
            <Box mb={4}>
              <div className="row add-friend">
                <Text fontSize={"sm"} color={"gray.500"} mb={1}>
                  Jam Lahir <span className="option">(optional)</span>
                </Text>
                <FormControl variant="standard" className={styles.formControl}>
                  <Input
                    fullWidth
                    id="birth_time"
                    type="time"
                    placeholder="Jam Lahir"
                    onChange={onChangeInput}
                    border={"1px solid #ddd"}
                    borderRadius={"md"}
                    p={2}
                    fontSize={"sm"}
                  />
                </FormControl>
              </div>
            </Box>
            <Box mb={16} pb={2} borderBottom={"1px solid #ddd"}>
              <div className="row add-friend">
                <Text fontSize={"sm"} color={"gray.500"} mb={1}>
                  Gender
                </Text>
                <FormControl
                  variant="standard"
                  // className={styles.formControlSmall}
                >
                  <RadioGroup row id="gender" onChange={onChangeInput}>
                    <FormControlLabel
                      value={0}
                      control={<Radio id="gender" />}
                      label="Pria"
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio id="gender" />}
                      label="Wanita"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Box>
            <Box mb={4} mt={4} position="relative" width="100%">
              <Flex position="absolute" right={0} bottom={0} mb={2} gap={1}>
                <ButtonCloseModal
                  title="Cancel"
                  onClick={() => {
                    window.location.assign("/dashboard/friend-list");
                  }}
                />
                <ButtonSubmit onClick={() => handleSubmit()}>Save</ButtonSubmit>
              </Flex>
            </Box>
          </Box>
        </KotakPutih>
      </Box>
    </>
  );
};

export default AddColleague;
