import React from "react";
import { Box, Flex, Button } from "@chakra-ui/react";

const ButtonNextStep = ({
  step,
  mode,
  selectedType,
  handlePreviousStep,
  handleNextStep,
  handleResetSelectType,
  user1,
  user2,
  onRunMatching,
}) => {
  return (
    <Box
      mt={
        step === 1
          ? { base: -32, sm: -32, md: -52 }
          : step === 2
          ? { base: -32, sm: -64, md: -52 }
          : 0
      }
      width="100%"
      px={{ base: 2, md: 4 }}
    >
      <Flex justify="center" gap={{ base: 2, md: 4 }} flexWrap="wrap">
        {step > 1 && (
          <Button
            onClick={handlePreviousStep}
            isDisabled={step === 1}
            bg="gray.500"
            px={4}
            py={2}
            fontSize={{ base: "xs", md: "sm" }}
            borderRadius="full"
            color="white"
            boxShadow="lg"
            _hover={{
              backgroundColor: "gray.600",
            }}
            width={{ base: "full", sm: "32", md: "32" }} // Responsif untuk lebar tombol
            mb={{ base: 2, md: 0 }} // Tambahkan margin bawah di layar kecil
          >
            Back
          </Button>
        )}

        {step === 2 && user1 && user2 && (
            <Button
              bgColor={"red"}
              color={"white"}
              fontSize={{ sm: "xs", md: "sm" }}
              fontWeight="bold"
              _hover={{
                backgroundColor: "red.500",
                transform: "scale(1.05)",
              }}
              _active={{ border: "2px solid red" }}
              boxShadow="lg"
              transition="background-color 0.3s ease, transform 0.3s ease"
              borderRadius="25"
              onClick={onRunMatching}
              px={4}
              py={2}
              disabled={mode === 1 && user1?.gender === user2?.gender}
              width="32"
              fontStyle={{ wrap: "break" }}
            >
              Process
            </Button>
        )}

        {mode !== -1 && step === 1 && (
          <Button
            onClick={handleResetSelectType}
            bg="gray.700"
            px={4}
            py={2}
            fontSize={{ base: "xs", md: "sm" }}
            borderRadius="full"
            color="white"
            boxShadow="lg"
            width={{ base: "full", sm: "32", md: "32" }}
            mb={{ base: 2, md: 0 }}
          >
            Cancel
          </Button>
        )}

        {mode !== -1 && step < 2 && (
          <Button
            onClick={handleNextStep}
            bg={
              step === -1 && !selectedType
                ? "gray.700"
                : mode === 0
                ? "orange"
                : mode === 1
                ? "#FF0080"
                : mode === 2
                ? "#0988e5"
                : ""
            }
            px={4}
            py={2}
            fontSize={{ base: "xs", md: "sm" }}
            borderRadius="full"
            boxShadow="lg"
            color="white"
            width={{ base: "full", sm: "32", md: "32" }}
            mb={{ base: 2, md: 0 }}
            isDisabled={step === -1 && selectedType === -1}
          >
            Next
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default ButtonNextStep;
