// ResultButtonMatching.js
import React from "react";
import { Box, Button } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons"; // Ensure you import ArrowBackIcon

const ResultButtonMatching = ({ activeCard, cardMatching, onClickCard }) => {
  return (
    <Box
      my={24}
      gap={4}
      mx="auto"
      px={16}
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="auto"
    >
      <Button
        bgColor={activeCard === 0 ? "orange" : "white"}
        color={activeCard === 0 ? "white" : "orange"}
        borderWidth={1}
        borderColor="orange"
        fontSize={{ sm: "xs", md: "sm" }}
        fontWeight="bold"
        borderRadius="25"
        onClick={() => onClickCard(0)}
        flex={{ sm: 1 }}
        px={{ sm: "10px", md: "30px" }}
        py="2"
      >
        <ArrowBackIcon fontSize="0.75rem" />
        Matching
      </Button>
      <Button
        bgColor={
          cardMatching.length === 0
            ? "gray"
            : activeCard === 1
            ? "red"
            : "white"
        }
        color={activeCard === 1 || cardMatching.length === 0 ? "white" : "red"}
        borderWidth={1}
        borderColor="red"
        fontSize={{ sm: "xs", md: "sm" }}
        fontWeight="bold"
        borderRadius="25"
        onClick={() => onClickCard(1)}
        flex={{ sm: 1 }}
        disabled={cardMatching.length === 0}
        px={{ sm: "10px", md: "30px" }}
        py="3"
        boxShadow={activeCard === 1 && "0 4px 6px rgba(255, 0, 0, 0.5)"}
      >
        General match
      </Button>
      <Button
        bgColor={
          cardMatching.length === 0
            ? "gray"
            : activeCard === 2
            ? "orange"
            : "white"
        }
        color={
          activeCard === 2 || cardMatching.length === 0 ? "white" : "orange"
        }
        borderWidth={1}
        borderColor="orange"
        fontSize={{ sm: "xs", md: "sm" }}
        fontWeight="bold"
        borderRadius="25"
        onClick={() => onClickCard(2)}
        disabled={cardMatching.length === 0}
        flex={{ sm: 1 }}
        px={{ sm: "10px", md: "30px" }}
        py="2"
      >
        Daily match
      </Button>
    </Box>
  );
};

export default ResultButtonMatching;
