export function formatNumber(num) {

  if (num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(3) + " M"; 
    } else if (num >= 1000) {
      return (num / 1000).toFixed(3) + " K"; 
    } else if (num < 1000) {
      return num.toString(); 
    } else {
      return ""; 
    }
  } else {
    return "";
  }
 
}

export function formatDate(dateString) {
  if (dateString) {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  } else {
    return "";
  }
}


export function truncateText  (text, maxLength = 165) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};
