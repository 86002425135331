// SubTitleMatching.js
import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";

const SubTitleMatching = ({ mode, step }) => {
  const getColor = (mode) => {
    return mode === 0
      ? "orange"
      : mode === 1
      ? "#FF0080"
      : mode === 2
      ? "#0988e5"
      : "gray";
  };

  const getModeText = (mode) => {
    return mode === 0
      ? "Partnership"
      : mode === 1
      ? "Romance"
      : mode === 2
      ? "Friendship"
      : "gray";
  };

  return (
    <>
      {mode === 0 || mode === 1 || mode === 2 ? (
        <Box mx="auto" alignContent={"center"}>
          <Flex gap={1} align="center" justify="center" mx="auto">
            <Text
              fontSize="lg"
              fontWeight="bold"
              color="gray.700"
              align="center"
              as={"h2"}
            >
              Anda Memilih Tipe Matching{" "}
              <Box as="span" color={getColor(mode)}>
                {getModeText(mode)}
              </Box>
            </Text>
          </Flex>
          <Flex gap={1} align="center" justify="center" mx="auto">
            {step === 1 ? (
              <Text fontSize="sm" align="center" color="gray.600" as={"article"}>
                Untuk melanjutkan matching, silahkan pilih klik button Next.
              </Text>
            ) : step === 2 ? (
              <Text fontSize="sm" align="center" color="gray.600" as={"article"}>
                Untuk melanjutkan matching, silahkan pilih User 1 dan User 2
              </Text>
            ) : (
              <Flex gap={1}>
                <Text fontSize="sm" align="center" color="gray.600">
                  Berikut adalah hasil dari Matching
                </Text>
                <Text
                  fontSize="sm"
                  align="center"
                  fontWeight="bold"
                  color={"gray.500"}
                >
                  {getModeText(mode)}
                </Text>
              </Flex>
            )}
          </Flex>
        </Box>
      ) : (
        <>
          <Text fontSize="lg" align="center" fontWeight="bold" color="gray.700">
            Anda Belum Memilih Tipe Matching
          </Text>

          <Text fontSize="sm" align="center" color="gray.600">
            Untuk melanjutkan matching, silahkan pilih Tipe Matching berikut.
          </Text>
        </>
      )}
    </>
  );
};

SubTitleMatching.propTypes = {
  mode: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
};

export default SubTitleMatching;
