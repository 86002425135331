import { Button } from "@chakra-ui/react";
import React from "react";

const ButtonMiddle = ({
  onClick,
  children = "None Button",
  px = 4,
  py = 1,
  color,
  w = "auto",
  fontSize="xs",
  ...rest
}) => {
  return (
    <Button
      bgColor={color ? `${color}.400` : "gray.400"}
      border="2px solid"
      borderColor={color ? `${color}.400` : "gray.400"}
      _hover={{ backgroundColor: color ? `${color}.500` : "gray.500" }}
      _active={{
        border: "2px solid",
        borderColor: color ? `${color}.200` : "gray.200",
      }}
      px={px}
      py={py}
      borderRadius="full"
      fontSize={fontSize}
      color="white"
      boxShadow="lg"
      w={w}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ButtonMiddle;
