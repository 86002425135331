import React from "react";

import { Box, Text } from "@chakra-ui/react";
const KotakPutih = ({ children, title, ...rest }) => {
  return (
    <Box
      bg="white"
      borderRadius={"2xl"}
      boxShadow={"lg"}
      p={4}
      mb={{ base: 0, sm: 0, md: 0, lg: 6 }}
      {...rest}
    >
      {title && (
        <>
          <Box mb={2}>
            <Text fontWeight={"bold"}>{title}</Text>
          </Box>
        </>
      )}
      <Box>{children}</Box>
    </Box>
  );
};

export default KotakPutih;
