import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  CircularProgress,
  Progress,
  Text,
  Flex,
} from "@chakra-ui/react";

const DownloadButton = ({
  item,
  activeMenu,
  index,
  handleDownload,
  loading,
}) => {
  const [progress, setProgress] = useState(0);

  const handleClick = () => {
    setProgress(0); // Reset progress when a new download starts

    // Start the download process
    handleDownload({
      category: item.category,
      service: activeMenu,
      name: item.features,
      index: index,
    });
  };

  // Simulate progress during loading
  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev)); // Increment progress, max at 90%
      }, 500); // Adjust interval for speed of progress

      return () => clearInterval(interval); // Cleanup interval on unmount
    } else {
      setProgress(100); // Set to 100% when loading completes
    }
  }, [loading]);

  return (
    <>
      <Button
        width="24"
        bgColor="green"
        _hover={{ backgroundColor: "green.500" }}
        _active={{
          border: "4px solid",
          borderColor: "green.200",
        }}
        color="white"
        fontSize={{ sm: "xs", md: "sm" }}
        fontWeight="500"
        borderRadius="full"
        onClick={handleClick}
        px="4"
        py="2"
        title="Download PDF"
      >
        {loading ? (
          <CircularProgress
            thickness={4}
            size={15}
            sx={{
              color: (theme) =>
                theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
            }}
          />
        ) : (
          <Text fontSize="xs">Download</Text>
        )}
      </Button>

      {loading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          backgroundColor="rgba(0, 0, 0, 0.5)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="1000"
        >
          <Box
            bgColor="white"
            p={6}
            borderRadius="xl"
            boxShadow="lg"
            width={{ base: "90%", md: "400px" }}
            mx={"auto"}
          >
            <Text fontWeight="bold" mb={4}>
              Downloading...
            </Text>

            <CircularProgress isIndeterminate color="green.300" />

            <Text fontSize={"sm"} align={"start"} mt={4}>
              Unduhan Anda sedang berlangsung. Mohon tunggu sejenak, dan jangan tutup halaman ini !
            </Text>


            <Flex justifyContent="flex-end" mt={4}>
              <Button
                bgColor="gray.500"
                _hover={{
                  backgroundColor: "gray.600",
                }}
                px={4}
                py={2}
                borderRadius={"full"}
                fontSize="xs"
                color="white"
                onClick={() => {}} 
                boxShadow={"xl"}
                disabled
              >
                OK
              </Button>
            </Flex>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DownloadButton;
