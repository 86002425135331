import axios from "axios";

const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_API_URL,
  baseURL: process.env.REACT_APP_API_URL || "https://api.personatalenta.id",
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 10000,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    if (response) {
      switch (response.status) {
        case 401:
          console.error("Unauthorized access - Redirecting to login.");
          window.location.href = "/login";
          break;
        case 500:
          console.error("Internal server Error.");
          window.location.assign = "/login";
          break;
        case 404:
          console.error("Resource not found.");
          window.location.assign = "/login";

          break;
        default:
          console.error("API error:", response);
          break;
      }
    } else {
      console.error("Network error:", error);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
