import { Button } from "@chakra-ui/react";
import React from "react";

const ButtonAction = ({
  onClick,
  children = "None Button",
  px = 6,
  py = 2,
  color,
  w = "32",
  ...rest
}) => {
  return (
    <Button
      bgColor={color ? `${color}.400` : "gray.400"}
      border="4px solid"
      borderColor={color ? `${color}.400` : "gray.400"}
      _hover={{ backgroundColor: color ? `${color}.500` : "gray.500" }}
      _active={{
        border: "4px solid",
        borderColor: color ? `${color}.200` : "gray.200",
      }}
      px={px}
      py={py}
      borderRadius="full"
      fontSize="sm"
      color="white"
      boxShadow="lg"
      w={w}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ButtonAction;
