import { Button } from "@chakra-ui/react";
import React from "react";

const ButtonSubmit = ({ onClick, name, ...rest }) => {
  return (
    <div>
      <Button
        {...rest}
        px={5}
        py={2}
        w={16}
        borderRadius={"full"}
        boxShadow={"lg"}
        onClick={onClick}
        color={"white"}
        fontSize={"xs"}
        bgColor={"red.500"}
        _hover={{
          bgColor: "red.600",
          transition: "background-color 0.3s ease-in-out",
        }}
        _active={{
          border: "4px solid #fd8585",
        }}
      >
        {name ? name : "Submit"}
      </Button>
    </div>
  );
};

export default ButtonSubmit;
